"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var array_helpers_1 = require("../../../utils/array_helpers");
require("../ShimentRoute/shipmentRoute.scss");
var slotblocking_apicalls_1 = require("../../field_exicutive/slot_blocking/slotblocking.apicalls");
require("../ultra_static/ultra_static.scss");
var ultra_static_api_1 = require("./ultra_static_api");
var react_hot_toast_1 = require("react-hot-toast");
var api_service_1 = require("../../../api/api_service");
var fe_services_1 = require("../../field_exicutive/fe_services");
var details_grid_1 = require("../../../common/DetailsGrid/details_grid");
var search_component_1 = require("../../../common/search/search_component");
var ultra_static_visit_1 = require("./ultra_static_visit");
var CreateUltraStatic = function () {
    var containerRef = React.useRef(null);
    var ultraStaticInitialValues = {
        labCode: '',
        labName: '',
        labState: '',
        labCity: '',
        feMobile: '',
        feCode: '',
        feName: '',
        feWorkingState: '',
        feWorkingCity: '',
        scheduleType: { label: 'MON - SAT', value: 1, id: 1 },
        slots: [],
    };
    var nav = (0, react_router_1.useNavigate)();
    var rowId = (0, react_router_1.useParams)().id;
    var routing_types_list = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.routing_assignment_type; });
    var ultrastatic_route_status_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_route_status_type; });
    var ultrastatic_route_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_route_type; });
    var updated_route_types = ultrastatic_route_types.map(function (route) { return (__assign(__assign({}, route), { label: route.label.replace(/_/g, ' ') })); });
    var ultrastatic_schedule_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_schedule_types; });
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _a = React.useState(''), selectedLabCode = _a[0], setSelectedLabcode = _a[1];
    var _b = React.useState(''), selectedFE = _b[0], setSelectedFE = _b[1];
    var _c = React.useState(ultraStaticInitialValues), initialValues = _c[0], setInitialValues = _c[1];
    var _d = React.useState([]), filteredFEs = _d[0], setFilteredFEs = _d[1];
    var _e = React.useState(''), searchTerm = _e[0], setSearchTerm = _e[1];
    var _f = React.useState(), visitsList = _f[0], setVisitsList = _f[1];
    var routeType = routing_types_list.filter(function (routeType) { return routeType.label === "ULTRA_STATIC"; });
    var convertedLabList = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var navigateTOListPage = function () {
        nav("/".concat(route_path_1.default.PATHS.ultra_static_list));
    };
    var EditData = function (isScheduleType, values) {
        if (rowId || selectedFE) {
            var data_1 = {};
            if (!isScheduleType) {
                (0, fe_services_1.fetchFEProfileList)(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, { user_id: rowId ? rowId : selectedFE }, process.env.VENDOR_BASE_URL)
                    .then(function (res) {
                    data_1['labCode'] = {
                        id: 1,
                        label: res.lab_code,
                        value: res.lab_code,
                    };
                    data_1['labName'] = res.centre_name;
                    data_1['labState'] = res.centre_state;
                    data_1['labCity'] = res.centre_city;
                    data_1['feMobile'] = {
                        id: res.id,
                        label: res.mobile,
                        value: res.mobile,
                    };
                    data_1['feCode'] = res.fe_id;
                    data_1['feName'] = "".concat(res.first_name, " ").concat(res.last_name);
                    data_1['feWorkingState'] = res.working_state;
                    data_1['feWorkingCity'] = res.working_city;
                    data_1['scheduleType'] = { label: 'MON - SAT', value: 1, id: 1 };
                    var payloadParam = "fe_id=".concat(rowId, "&schedule_type=").concat(1, "&status=1,2,4,5");
                    fetchUltraStaticSlots(payloadParam, data_1);
                });
            }
            else {
                data_1['labCode'] = values.labCode;
                data_1['labName'] = values.labName;
                data_1['labState'] = values.labState;
                data_1['labCity'] = values.labCity;
                data_1['feMobile'] = values.feMobile;
                data_1['feCode'] = values.feCode;
                data_1['feName'] = values.feName;
                data_1['feWorkingState'] = values.feWorkingState;
                data_1['feWorkingCity'] = values.feWorkingCity;
                data_1['scheduleType'] = isScheduleType;
                var payloadParam = "fe_id=".concat(rowId ? rowId : selectedFE, "&schedule_type=").concat(isScheduleType === null || isScheduleType === void 0 ? void 0 : isScheduleType.value, "&status=1,2,4,5");
                fetchUltraStaticSlots(payloadParam, data_1);
            }
        }
    };
    var visitFormData = function (visitDetails) {
        var _a, _b, _c, _d;
        var visitType = updated_route_types.filter(function (routeType) { return routeType.value === parseInt(visitDetails.visit_type); });
        var minutes = appConstants_1.MinutesUltrStatic.filter(function (minute) { return minute.value === visitDetails.eta.substring(3); });
        var timeSlot = appConstants_1.TimeSlots.filter(function (slot) { return slot.value.substring(0, 2) === visitDetails.eta.substring(0, 2); });
        var status = ultrastatic_route_status_types.filter(function (status) { return status.id === visitDetails.status; });
        var eventType = ((_a = visitType[0]) === null || _a === void 0 ? void 0 : _a.label) === 'PICKUP' ? 'CC' : ((_b = visitType[0]) === null || _b === void 0 ? void 0 : _b.label) === 'HANDOVER LAB' || ((_c = visitType[0]) === null || _c === void 0 ? void 0 : _c.label) === 'HANDOVER CASH' ? 'LAB' : ((_d = visitType[0]) === null || _d === void 0 ? void 0 : _d.label) === 'HANDOVER HUB' ? 'HUB' : '';
        var visit = {
            ccCode: { label: visitDetails === null || visitDetails === void 0 ? void 0 : visitDetails.warehouse_code, value: visitDetails === null || visitDetails === void 0 ? void 0 : visitDetails.warehouse_code },
            ccName: visitDetails === null || visitDetails === void 0 ? void 0 : visitDetails.center_name,
            event: visitType[0],
            hours: visitDetails.eta.substring(0, 2),
            eta: visitDetails.eta,
            minutes: minutes[0],
            timeSlots: timeSlot[0],
            isEdit: false,
            status: status[0].label,
            isError: '',
            routeId: visitDetails.id,
            isNewRoute: false,
            codePlaceHolder: "".concat(eventType, " Code"),
            namePlaceHolder: "".concat(eventType, " Name")
        };
        return visit;
    };
    var fetchUltraStaticSlots = function (payload, data) {
        (0, ultra_static_api_1.getUltraStaticRoutes)(payload)
            .then(function (res) {
            var _a, _b, _c;
            if (res.statusCode === 200) {
                if (((_a = res.data) === null || _a === void 0 ? void 0 : _a.results.length) > 0) {
                    var slots_1 = [];
                    var scheduleType = ultrastatic_schedule_types === null || ultrastatic_schedule_types === void 0 ? void 0 : ultrastatic_schedule_types.filter(function (type) { var _a; return type.id === parseInt((_a = res.data) === null || _a === void 0 ? void 0 : _a.results[0].schedule_type); });
                    data['scheduleType'] = scheduleType[0];
                    (_c = (_b = res.data) === null || _b === void 0 ? void 0 : _b.results) === null || _c === void 0 ? void 0 : _c.map(function (each) {
                        var visits = visitFormData(each);
                        visits['isDelete'] = false;
                        slots_1.push(visits);
                    });
                    data['slots'] = slots_1;
                }
                else {
                    data['slots'] = [];
                }
            }
            setVisitsList(data.slots);
            setInitialValues(data);
        })
            .catch(function (e) {
            console.error(e);
        });
    };
    var fetchSelectedFERoute = function (feId, setFieldValue) {
        var payloadParam = "fe_id=".concat(feId, "&schedule_type=").concat(1, "&status=1,2,4,5");
        (0, ultra_static_api_1.getUltraStaticRoutes)(payloadParam)
            .then(function (res) {
            var _a, _b, _c;
            if (res.statusCode === 200) {
                if (((_a = res.data) === null || _a === void 0 ? void 0 : _a.results.length) > 0) {
                    var slots_2 = [];
                    (_c = (_b = res.data) === null || _b === void 0 ? void 0 : _b.results) === null || _c === void 0 ? void 0 : _c.map(function (each) {
                        var routeSlots = visitFormData(each);
                        slots_2.push(routeSlots);
                    });
                    setVisitsList(slots_2);
                    setFieldValue('slots', slots_2);
                }
                else {
                    setVisitsList([]);
                    setFieldValue('slots', []);
                }
            }
        });
    };
    var checkIsEditFalse = function (array) {
        return array.some(function (item) { return item.isEdit === true; });
    };
    var scrollToBottom = function () {
        if (containerRef.current) {
            var container_1 = containerRef.current;
            var offset_1 = 200;
            setTimeout(function () {
                container_1.scrollTop = container_1.scrollHeight + offset_1;
            }, 0);
        }
    };
    var searchingHandler = function (val, setFieldValue) {
        if (val.target.value) {
            setSearchTerm(val.target.value);
            var lowercasedSearchText_1 = val.target.value.toLowerCase();
            var filteredData = visitsList.filter(function (item) {
                return item.ccCode.value.toLowerCase().includes(lowercasedSearchText_1) ||
                    item.ccName.toLowerCase().includes(lowercasedSearchText_1) ||
                    item.timeSlots.label.toLowerCase().includes(lowercasedSearchText_1);
            });
            setFieldValue('slots', filteredData);
        }
        else {
            setFieldValue('slots', visitsList);
        }
    };
    React.useEffect(function () {
        if (rowId) {
            EditData();
        }
    }, [rowId]);
    React.useEffect(function () {
        if (selectedLabCode != null) {
            var payload = {
                onboard_status: [appConstants_1.FE_USER_TYPES.active],
                route_type: routeType[0].value,
                lab_code: selectedLabCode.value
            };
            (0, slotblocking_apicalls_1.fetchFeOnSelectedFeType)(payload)
                .then(function (res) {
                setFilteredFEs((0, array_helpers_1.convertFEListResponse)(res));
            })
                .catch(function (e) { return console.log(e); });
        }
    }, [selectedLabCode]);
    return (React.createElement("div", { className: "ultra-static-wrapper", ref: containerRef },
        React.createElement("div", null,
            React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, enableReinitialize: true, onSubmit: function () { } }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                        React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                            React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTOListPage }),
                            React.createElement("h2", null, "".concat(rowId ? 'Edit' : 'Create', " Ultra Static Route"))),
                        React.createElement(button_component_1.default, { color: "yellow", buttonName: "Close", onClickHandler: navigateTOListPage })),
                    React.createElement("div", { className: "add-ultra-static-form form-filed-wrapper" },
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, spacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 12 },
                                React.createElement("h2", { className: "label-heading heading" }, "Lab Details")),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                React.createElement("div", { className: "clear-dropdown" },
                                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "labCode", value: values === null || values === void 0 ? void 0 : values.labCode, dropDownList: convertedLabList.length > 0 ? convertedLabList : [], onChange: function (val) {
                                            if (val == null) {
                                                setFieldValue('labState', '');
                                                setFieldValue('labCity', '');
                                                setFieldValue('labCode', '');
                                                setFieldValue('labName', '');
                                                setFieldValue('feCode', '');
                                                setFieldValue('feWorkingState', '');
                                                setFieldValue('feWorkingCity', '');
                                                setFieldValue('feMobile', '');
                                                setFieldValue('feName', '');
                                                setFieldValue('slots', []);
                                                setSelectedLabcode('');
                                            }
                                            else {
                                                setSelectedLabcode(val);
                                                setFieldValue('labState', val.center_state);
                                                setFieldValue('labCity', val.center_city);
                                                setFieldValue('labName', val.center_name);
                                                setFieldValue('labCode', val);
                                                setFieldTouched('labCode', true);
                                            }
                                        }, placeholder: "Lab Code", required: true, isDisabled: rowId ? true : false }),
                                    errors['labCode'] && touched['labCode'] ? (React.createElement("div", { className: "error" },
                                        errors['labCode'],
                                        " ")) : null)),
                            (values === null || values === void 0 ? void 0 : values.labName) &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'Lab Name', value: values === null || values === void 0 ? void 0 : values.labName, className: '' })),
                            (values === null || values === void 0 ? void 0 : values.labState) &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'Lab State', value: values === null || values === void 0 ? void 0 : values.labState, className: '' })),
                            (values === null || values === void 0 ? void 0 : values.labCity) &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'Lab City', value: values === null || values === void 0 ? void 0 : values.labCity, className: '' }))),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, spacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 12 },
                                React.createElement("h2", { className: "label-heading heading" }, "FE Details")),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                React.createElement("div", { className: "clear-dropdown" },
                                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "feMobile", value: values === null || values === void 0 ? void 0 : values.feMobile, dropDownList: filteredFEs ? filteredFEs : [], onChange: function (val) {
                                            if (val === null) {
                                                setFieldValue('feCode', '');
                                                setFieldValue('feWorkingState', '');
                                                setFieldValue('feWorkingCity', '');
                                                setFieldValue('feMobile', '');
                                                setFieldValue('feName', '');
                                                setFieldValue('slots', []);
                                            }
                                            else {
                                                fetchSelectedFERoute(val.id, setFieldValue);
                                                setFieldValue('feCode', val.FE_id);
                                                setSelectedFE(val.id);
                                                setFieldValue('feWorkingState', val.centre_state);
                                                setFieldValue('feWorkingCity', val.centre_city);
                                                setFieldValue('feName', val.name);
                                                setFieldValue('feMobile', {
                                                    id: val.id,
                                                    label: val.mobile,
                                                    value: val.mobile
                                                });
                                                setFieldTouched('feMobile', true);
                                            }
                                        }, placeholder: "FE Mobile Number/FE Name/FE Code", required: true, isDisabled: rowId ? true : (values === null || values === void 0 ? void 0 : values.labCode) == '' }),
                                    errors['feCode'] && touched['feCode'] ? (React.createElement("div", { className: "error" },
                                        errors['feCode'],
                                        " ")) : null)),
                            (values === null || values === void 0 ? void 0 : values.feCode) &&
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'FE Code', value: values === null || values === void 0 ? void 0 : values.feCode })),
                            (values === null || values === void 0 ? void 0 : values.feName) &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'FE Name', value: values === null || values === void 0 ? void 0 : values.feName })),
                            (values === null || values === void 0 ? void 0 : values.feWorkingState) &&
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'FE Working State', value: values === null || values === void 0 ? void 0 : values.feWorkingState })),
                            (values === null || values === void 0 ? void 0 : values.feWorkingState) &&
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: 'grid-wrapper' },
                                    React.createElement(details_grid_1.default, { lableName: 'FE Working City', value: values === null || values === void 0 ? void 0 : values.feWorkingState })))),
                    React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true },
                        React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true },
                            React.createElement("h2", { className: "ultra-static-subheading" }, "Manage Center Visits"))),
                    React.createElement(formik_1.FieldArray, { name: "slots" }, function (_a) {
                        var _b, _c;
                        var push = _a.push, remove = _a.remove;
                        return (React.createElement("div", { className: "add-ultra-static-form form-filed-wrapper" },
                            React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, style: { marginTop: '-9px', marginBottom: '-9px' } },
                                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "sceduleType", value: values === null || values === void 0 ? void 0 : values.scheduleType, dropDownList: (ultrastatic_schedule_types === null || ultrastatic_schedule_types === void 0 ? void 0 : ultrastatic_schedule_types.length) > 0 ? ultrastatic_schedule_types : [], onChange: function (val) {
                                            setFieldValue('scheduleType', val);
                                            EditData(val, values);
                                        }, placeholder: "Schedule Type" })),
                                React.createElement(Grid_1.default, { item: true, xs: 10, lg: 10, style: { display: 'flex', justifyContent: 'flex-end', alignSelf: 'center' } },
                                    React.createElement("div", { className: "filter-menu-wrapper long-filter" },
                                        React.createElement("div", { className: "search-filter search-lg" },
                                            React.createElement(search_component_1.default, { placeholder: "CC Code/CC Name/Timeslot", searchValue: searchTerm, searchHandler: function (val) { return searchingHandler(val, setFieldValue); } }))),
                                    React.createElement(button_component_1.default, { buttonName: "Add Visit", isError: rowId ? false : selectedFE === '' ? true : false, onClickHandler: function () {
                                            var _a, _b;
                                            var pushNewVisit = function () {
                                                push({
                                                    id: Math.random(),
                                                    hours: '',
                                                    minutes: '',
                                                    isError: '',
                                                    event: '',
                                                    ccCode: '',
                                                    ccName: '',
                                                    timeSlots: '',
                                                    isSubmitted: false,
                                                    isEdit: true,
                                                    routeId: '',
                                                    isNewRoute: true,
                                                    status: 'ACTIVE',
                                                    isDelete: false,
                                                    codePlaceHolder: 'CC Code',
                                                    namePlaceHolder: 'CC Name'
                                                });
                                            };
                                            if (((_a = values.slots) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                                if (values.slots[((_b = values.slots) === null || _b === void 0 ? void 0 : _b.length) - 1].isNewRoute || checkIsEditFalse(values.slots)) {
                                                    react_hot_toast_1.default.error('Please complete the current action', { duration: 3000 });
                                                }
                                                else {
                                                    pushNewVisit();
                                                    scrollToBottom();
                                                }
                                            }
                                            else {
                                                pushNewVisit();
                                                scrollToBottom();
                                            }
                                        }, color: "yellow" }))),
                            ((_b = values === null || values === void 0 ? void 0 : values.slots) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
                                React.createElement("div", { className: "field-array-wrapper" }, (_c = values === null || values === void 0 ? void 0 : values.slots) === null || _c === void 0 ? void 0 : _c.map(function (p, index) {
                                    return (React.createElement("div", { key: p.id },
                                        React.createElement(ultra_static_visit_1.default, { values: values, setFieldValue: setFieldValue, setFieldTouched: setFieldTouched, remove: remove, index: index, rowId: rowId, checkIsEditFalse: checkIsEditFalse, routeType: routeType, setVisitsList: setVisitsList })));
                                }))));
                    })));
            }))));
};
exports.default = CreateUltraStatic;
