"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var Stack_1 = require("@mui/system/Stack");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../actions/config_action");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var map_1 = require("../../../common/map");
var ModalComponentWithoutButton_1 = require("../../../common/modal/ModalComponentWithoutButton");
var modal_component_1 = require("../../../common/modal/modal_component");
var profile_grid_1 = require("../../../common/profile_grid/profile_grid");
var RoastersForMultipleDays_1 = require("../../../common/Roaster/RoastersForMultipleDays");
var text_filed_with_icon_1 = require("../../../common/text_filed/text-filed-with-icon");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var cc_slots_1 = require("../../../menuList/cc_slots");
var menu_constants_1 = require("../../../menuList/menu_constants");
var selected_CC_1 = require("../../../menuList/selected_CC");
var array_helpers_1 = require("../../../utils/array_helpers");
var lodash_1 = require("../../../utils/lodash");
var slotblocking_apicalls_1 = require("../../field_exicutive/slot_blocking/slotblocking.apicalls");
var slotblocking_helper_1 = require("../../field_exicutive/slot_blocking/slotblocking.helper");
require("../control_center/control_center.scss");
var CC_api_calls_1 = require("./CC_api_calls");
var CC_helper_1 = require("./CC_helper");
var CC_Roster_Timeslots_1 = require("./CC_Roster_Timeslots");
var reverseGeoCode_1 = require("./reverseGeoCode");
var ModifyTimeSlot = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var isMounted = false;
    var _a = React.useState([]), filteredCCs = _a[0], setFilteredCCs = _a[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var pathName = location.pathname;
    var convertedLabList = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var _b = React.useState([]), weekDays = _b[0], setWeekDays = _b[1];
    var _c = React.useState(1), activeTab = _c[0], setActiveTab = _c[1];
    var _d = React.useState(slotblocking_helper_1.CC_timeSlots), timeSlotsList = _d[0], setTimeSlotList = _d[1];
    var _e = React.useState(), apidata = _e[0], setApidata = _e[1];
    var _f = React.useState(), editData = _f[0], setEditdata = _f[1];
    var _g = React.useState(), apislotdata = _g[0], setApislotdata = _g[1];
    var _h = React.useState(), selectedDay = _h[0], setSelectedDay = _h[1];
    var _j = React.useState(false), isDayselected = _j[0], setDayselected = _j[1];
    var _k = React.useState(), daySlots = _k[0], setDaySlots = _k[1];
    var _l = React.useState(), labcode = _l[0], setlabcode = _l[1];
    var _m = React.useState(), addedData = _m[0], setAddedData = _m[1];
    var _o = React.useState([]), arr = _o[0], setArr = _o[1];
    var payloadobject = React.useState({})[0];
    var _p = React.useState(false), showMaps = _p[0], toggleMapView = _p[1];
    var _q = React.useState(false), weekDaysModal = _q[0], setWeekDaysModal = _q[1];
    var _r = React.useState(), slotid = _r[0], setSlotid = _r[1];
    var _s = React.useState([]), selecteFeIds = _s[0], setSelecteFeIds = _s[1];
    var _t = React.useState([]), selectedSlots = _t[0], setSelectedSlots = _t[1];
    var hiddenInnerSubmitFormRef = React.useRef(null);
    var _u = React.useState(1), weekDay = _u[0], setWeekDay = _u[1];
    var _v = React.useState(), editSlots = _v[0], setEditSlots = _v[1];
    var _w = React.useState(), isRouteChanged = _w[0], setIsRouteChanged = _w[1];
    var _x = React.useState('NON_ROUTE'), routeType = _x[0], setRouteType = _x[1];
    var _y = React.useState(false), isRoutechanged = _y[0], setIsRoutechanged = _y[1];
    var _z = React.useState(false), isSlotsEmpty = _z[0], setIsSlotsEmpty = _z[1];
    var _0 = React.useState(false), labHandover = _0[0], setLabHandover = _0[1];
    var _1 = React.useState(), invoiceCode = _1[0], setInvoiceCode = _1[1];
    var _2 = React.useState(), editLatitude = _2[0], setEditLatitude = _2[1];
    var _3 = React.useState(), editLongitude = _3[0], setEditLongitude = _3[1];
    var _4 = React.useState(null), addressInfo = _4[0], setAddressInfo = _4[1];
    var _5 = React.useState(), editLocation = _5[0], setEditLocation = _5[1];
    var _6 = React.useState(false), getLoactionFromLatLong = _6[0], setGetLoactionFromLatLong = _6[1];
    var errorMsg = 'Please Select Lab code';
    var _7 = React.useState(cc_slots_1.CC_INTITAL_VALUES), initialValues = _7[0], setInitialValues = _7[1];
    var _8 = React.useState(false), raiseOnDemandRequests = _8[0], setRaiseOnDemandRequest = _8[1];
    var _9 = React.useState(false), extraCreditModal = _9[0], setExtraCreditModal = _9[1];
    var creditErrorMsg = 'Please enter On-Demand requests between 1 to 30';
    var _10 = React.useState(false), addCreditBtn = _10[0], setAddCreditBtn = _10[1];
    console.log(addCreditBtn);
    var _11 = React.useState(0), defaultOndemand = _11[0], setDefaultOndemand = _11[1];
    var _12 = React.useState(0), onchangeOndemand = _12[0], setOnchangeOndemand = _12[1];
    var _13 = React.useState(null), errorCredit = _13[0], setErrorCredit = _13[1];
    var _14 = React.useState({}), infoTag = _14[0], setinfoTag = _14[1];
    var _15 = React.useState(true), searchBy = _15[0], setSearchBy = _15[1];
    var _16 = React.useState(''), warehousecodeError = _16[0], setWarehousecodeError = _16[1];
    var _17 = React.useState({
        id: '',
        invoiceCode: '',
    }), invoiceId = _17[0], setInvoiceId = _17[1];
    var addCredits = function () {
        if (onchangeOndemand > 0) {
            setDefaultOndemand(parseInt(defaultOndemand) + parseInt(onchangeOndemand));
            setExtraCreditModal(false);
            setOnchangeOndemand(0);
        }
        else {
            setExtraCreditModal(true);
            setErrorCredit(creditErrorMsg);
        }
    };
    var ontextChange = function (val) {
        val > 0 ? setErrorCredit(null) : setErrorCredit(creditErrorMsg);
        val <= 30 ? setErrorCredit(null) : setErrorCredit(creditErrorMsg);
        setOnchangeOndemand(parseInt(val));
    };
    var roootType = 'NON_ROUTE';
    var handOverToLab = true;
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var navigateTOListPage = function () {
        if (pathName.includes("".concat(route_path_1.default.PATHS.ccworkinghourslist))) {
            nav("/".concat(route_path_1.default.PATHS.ccworkinghourslist));
        }
        else {
            dispatch((0, config_action_1.featchCurrentActiveMenu)({
                mainModule: menu_constants_1.FEATURE_CONSTANTS.controlTower,
                subModule: menu_constants_1.FEATURE_CONSTANTS.controlTower,
                subChaildModule: '',
            }));
            nav(-1);
        }
    };
    var callbackOnselectedLab = function (val) {
        var payload = {
            lab_code: val.value,
        };
        (0, CC_api_calls_1.fetchCCTimeSlotList)(payload)
            .then(function (res) {
            var convertedData = (0, array_helpers_1.convertCCListResponse)(res.result);
            setFilteredCCs(convertedData);
        })
            .catch(function (e) { return console.log(e); });
    };
    var callbackOnselectedCC = function (val, setFieldValue) {
        var obj = {};
        weekDays.map(function (each) {
            obj["day".concat(each.id)] = lodash_1.default.cloneDeep(slotblocking_helper_1.CC_timeSlots);
        });
        setSelecteFeIds(val);
        var payload = {
            warehouse_code: val.value,
        };
        if (!searchBy) {
            payload.warehouse_code = val;
        }
        (0, CC_api_calls_1.fetchCCEditList)(payload)
            .then(function (res) {
            var _a, _b, _c, _d, _e, _f, _g;
            if (res.message !== 'Success') {
                setWarehousecodeError(res.message);
            }
            else {
                setWarehousecodeError('');
            }
            setEditSlots(res.data);
            setAddressInfo(res.data);
            setinfoTag(res === null || res === void 0 ? void 0 : res.data);
            if (((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.on_demand_allowed) > 0) {
                setRaiseOnDemandRequest(true);
            }
            setDefaultOndemand((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.unused_credits);
            setFieldValue('baseLocation', res.data.latitude && res.data.longitude
                ? "".concat(res.data.latitude, ",").concat(res.data.longitude)
                : '');
            setFieldValue('base_location_address', res.data.base_location_address ? res.data.base_location_address : '');
            if (!searchBy) {
                var lab = labList.filter(function (labs) { var _a; return (labs === null || labs === void 0 ? void 0 : labs.value) === ((_a = res.data) === null || _a === void 0 ? void 0 : _a.lab_code); });
                setInvoiceId({
                    id: res.data.center_id,
                    invoice_code: res.data.invoice_code,
                });
                setFieldValue('labcode', ((_c = res.data) === null || _c === void 0 ? void 0 : _c.lab_code) ? lab[0] : '');
                setFieldValue('state', ((_d = res.data) === null || _d === void 0 ? void 0 : _d.state) ? res.data.state : '');
                setFieldValue('city', ((_e = res.data) === null || _e === void 0 ? void 0 : _e.city) ? res.data.city : '');
            }
            if ((weekDays === null || weekDays === void 0 ? void 0 : weekDays.length) > 0) {
                var day_1;
                (_g = (_f = res.data) === null || _f === void 0 ? void 0 : _f.schedule) === null || _g === void 0 ? void 0 : _g.map(function (schedule) {
                    day_1 = (0, CC_helper_1.getDayFromId)(schedule.day, weekDays);
                    var editFromTime = schedule.schedule_time_slot.split(':')[0];
                    if (day_1) {
                        obj["day".concat(day_1.id)].map(function (slotObj) {
                            if (editFromTime === slotObj.buttonName.split(':')[0]) {
                                slotObj['color'] = 'slot-btn status-selected';
                            }
                        });
                        setDaySlots(lodash_1.default.cloneDeep(obj));
                    }
                });
            }
        })
            .catch(function (e) { return console.log(e); });
    };
    var clearSlots = function () {
        var obj = {};
        weekDays.map(function (each) {
            obj["day".concat(each.id)] = JSON.parse(JSON.stringify(slotblocking_helper_1.CC_timeSlots));
        });
        setWeekDay(1);
        setDaySlots(__assign({}, obj));
        editTimeSlots(obj);
    };
    var getWeekDays = function () {
        (0, CC_api_calls_1.getAllWeekDays)()
            .then(function (response) {
            setWeekDays(response.data);
            var obj = {};
            var daySlotsObj = {};
            response.data.map(function (each) {
                obj[each.day] = JSON.parse(JSON.stringify(slotblocking_helper_1.CC_timeSlots));
                daySlotsObj["day".concat(each.id)] = JSON.parse(JSON.stringify(slotblocking_helper_1.CC_timeSlots));
            });
            setDaySlots(__assign({}, daySlotsObj));
            if (id) {
                getEditDetails();
            }
        })
            .catch(function (e) { return console.log(e); });
    };
    var getEditDetails = function () {
        var payload = {
            warehouse_code: id,
        };
        (0, CC_api_calls_1.fetchCCEditList)(payload)
            .then(function (res) {
            setEditLatitude(res.data.latitude);
            setEditLongitude(res.data.longitude);
            setInvoiceCode(res.data.invoice_code);
            setEditSlots(res.data);
            setEditdata(res.data);
            roootType =
                res.data.routing_assignment &&
                    (0, CC_helper_1.getRouteTypeFromEdit)(res.data.routing_assignment);
            setRouteType(roootType);
            handOverToLab = (0, CC_helper_1.getHandOverFromEdit)(res.data.can_handover_to_lab);
            setLabHandover(handOverToLab);
            setApidata(res);
            editInitialValues(res.data);
        })
            .catch(function (e) { return console.log(e); });
    };
    var editTimeSlots = function (obj) {
        var _a;
        if (weekDays.length > 0) {
            var day_2;
            (_a = editSlots === null || editSlots === void 0 ? void 0 : editSlots.schedule) === null || _a === void 0 ? void 0 : _a.map(function (schedule) {
                var _a;
                day_2 = (0, CC_helper_1.getDayFromId)(schedule.day, weekDays);
                var editFromTime = schedule.schedule_time_slot.split(':')[0];
                if (day_2) {
                    (_a = obj["day".concat(day_2.id)]) === null || _a === void 0 ? void 0 : _a.forEach(function (slot) {
                        var slotFromTime = slot.buttonName.split(':')[0];
                        if (editFromTime === slotFromTime) {
                            slot['color'] = 'slot-btn status-selected';
                        }
                    });
                }
            });
            setDaySlots(__assign({}, obj));
        }
    };
    var editInitialValues = function (editData) {
        if (editData) {
            setInitialValues({
                labcode: editData.lab_code
                    ? (0, CC_helper_1.getSelectedLabDetils)(labList, editData.lab_code)
                    : '',
                state: editData.state ? editData.state : '',
                city: editData.city ? editData.city : '',
                warehouse_code: editData ? (0, CC_helper_1.getSelectedWarehouseDetils)(editData) : '',
                schedule_time_slot: editData.lab_code ? editData.lab_code : '',
                activate: editData.routing_assignment ? routeType : '',
                handoverdone: editData.can_handover_to_lab ? handOverToLab : '',
                baseLocation: editData.latitude && editData.longitude
                    ? "".concat(editData.latitude, ",").concat(editData.longitude)
                    : '',
                base_location_address: editData.base_location_address
                    ? editData.base_location_address
                    : editLocation
                        ? editLocation
                        : '',
                id: editData.center_id,
            });
            if ((editData === null || editData === void 0 ? void 0 : editData.on_demand_allowed) > 0) {
                setRaiseOnDemandRequest(true);
            }
            setDefaultOndemand(editData === null || editData === void 0 ? void 0 : editData.available_credits);
            setinfoTag(editData);
        }
    };
    var setColor;
    var weekDayColorHandler = function (onChangeWeekValues) {
        setActiveTab(onChangeWeekValues.id);
        if (onChangeWeekValues.color) {
            setColor = weekDays.map(function (weekDaysObject) {
                if (weekDaysObject.id === onChangeWeekValues.id) {
                    weekDaysObject['className'] = null;
                    return weekDaysObject;
                }
                return weekDaysObject;
            });
        }
        else {
            setDayselected(!isDayselected);
            setColor = weekDays.map(function (weekDaysObject) {
                if (weekDaysObject['className'] === 'tab-item active-tab') {
                    weekDaysObject['className'] = null;
                }
                if (weekDaysObject.id === onChangeWeekValues.id) {
                    weekDaysObject['className'] = 'tab-item active-tab';
                    return weekDaysObject;
                }
                if (weekDaysObject.id === onChangeWeekValues.id) {
                    apidata === null || apidata === void 0 ? void 0 : apidata.map(function (e) {
                        if (e.day === onChangeWeekValues.day) {
                            weekDaysObject['className'] = 'tab-item active-tab';
                            setApislotdata(e.slots);
                        }
                    });
                    return weekDaysObject;
                }
                return weekDaysObject;
            });
        }
        setColor.map(function (e) {
            if ((e === null || e === void 0 ? void 0 : e.className) === 'tab-item active-tab') {
                setSelectedDay(e);
            }
        });
        setWeekDays(setColor);
    };
    var formsubmit = function (payLoad) {
        (0, slotblocking_apicalls_1.createCCTimeSlot)(payLoad)
            .then(function (res) {
            if (res.statusCode == 200) {
                react_hot_toast_1.default.success(res.message);
                setDefaultOndemand(0);
            }
            else {
                react_hot_toast_1.default.error(res.message);
            }
            navigateTOListPage();
        })
            .catch(function (err) {
            navigateTOListPage();
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var formSubmitHandler = function (values) {
        var _a, _b, _c, _d;
        if (!searchBy && warehousecodeError == 'Sorry! No results found') {
            return;
        }
        var getSelectedSlots = function (perDaySlots, weekId) {
            var selectedArr = [];
            perDaySlots.map(function (each) {
                var _a;
                if (each.color === 'slot-btn status-selected') {
                    selectedArr.push((_a = {},
                        _a['day'] = weekId,
                        _a['schedule_time_slot'] = each.buttonName,
                        _a));
                    return each;
                }
            });
            return selectedArr;
        };
        var arr = [];
        var payloadSlots = [];
        weekDays.map(function (weekdayString) {
            var selectedSlotsPerDay = getSelectedSlots(daySlots["day".concat(weekdayString.id)], weekdayString.id);
            payloadSlots.push.apply(payloadSlots, selectedSlotsPerDay);
        });
        var payload = {
            schedule: payloadSlots,
            can_handover_to_lab: values.handoverdone === 'true' ? true : false,
            routing_assignment: routeType,
            warehouse_code: (_a = values === null || values === void 0 ? void 0 : values.warehouse_code) === null || _a === void 0 ? void 0 : _a.value,
            on_demand_allowed: values.raiseOnDemandRequest === 'true' ? true : raiseOnDemandRequests,
        };
        if (!searchBy) {
            payload.warehouse_code = values === null || values === void 0 ? void 0 : values.warehouse_codes;
            payload.id = invoiceId.id;
            payload.invoice_code = invoiceId.invoice_code;
        }
        else {
            payload.invoice_code = invoiceCode
                ? invoiceCode
                : ((_b = values === null || values === void 0 ? void 0 : values.warehouse_code) === null || _b === void 0 ? void 0 : _b.invoice_code)
                    ? (_c = values === null || values === void 0 ? void 0 : values.warehouse_code) === null || _c === void 0 ? void 0 : _c.invoice_code
                    : '';
            payload.id = values.id ? values.id : (_d = values === null || values === void 0 ? void 0 : values.warehouse_code) === null || _d === void 0 ? void 0 : _d.id;
        }
        if (values.baseLocation.split(',')[0]) {
            payload['latitude'] = values.baseLocation.split(',')[0];
        }
        if (values.baseLocation.split(',')[1]) {
            payload['longitude'] = values.baseLocation.split(',')[1];
        }
        if (values.base_location_address) {
            payload['base_location_address'] = values.base_location_address;
        }
        payload['available_credits'] =
            parseInt(defaultOndemand) - parseInt(infoTag === null || infoTag === void 0 ? void 0 : infoTag.available_credits);
        setArr(function (prev) { return __spreadArray(__spreadArray([], prev, true), [payload], false); });
        payloadobject['schedule'] = arr;
        if (payloadSlots.length === 0 &&
            (payload.routing_assignment === 'STATIC' ||
                payload.routing_assignment === 'DYNAMIC') &&
            !isSlotsEmpty) {
            setIsSlotsEmpty(true);
        }
        else {
            formsubmit(payload);
        }
    };
    var weekDaysSubmitHandler = function (selectedDays) {
        var selectedDaySlots = daySlots["day".concat(weekDay)];
        var obj = {};
        var k = JSON.parse(JSON.stringify(daySlots));
        selectedDays === null || selectedDays === void 0 ? void 0 : selectedDays.forEach(function (week) {
            var arr = [];
            selectedDaySlots.map(function (each) {
                arr.push(each);
                k[week] = JSON.parse(JSON.stringify(daySlots["day".concat(weekDay)]));
            });
            obj[week] = arr;
        });
        setDaySlots(k);
        react_hot_toast_1.default.success('Slots have been added succesfully for Selected Days', {
            duration: 2000,
        });
        daysModal();
    };
    var daysModal = function () {
        var selectedDaySlots = daySlots["day".concat(weekDay)];
        var slotsSelected = [];
        selectedDaySlots.filter(function (each) {
            if (each.color === 'slot-btn status-selected') {
                slotsSelected.push(each);
            }
            return arr;
        });
        if (slotsSelected.length > 0) {
            setWeekDaysModal(!weekDaysModal);
        }
        else {
            react_hot_toast_1.default.error('Please Select atleast one slot', {
                duration: 5000,
            });
        }
    };
    var extraCreditModalCall = function () {
        setExtraCreditModal(!extraCreditModal);
    };
    var editDataSlots = function () {
        var _a;
        if (weekDays.length > 0) {
            var day_3;
            (_a = editData === null || editData === void 0 ? void 0 : editData.schedule) === null || _a === void 0 ? void 0 : _a.map(function (schedule) {
                var _a;
                day_3 = (0, CC_helper_1.getDayFromId)(schedule.day, weekDays);
                var editFromTime = schedule.schedule_time_slot.split(':')[0];
                if (day_3) {
                    (_a = daySlots["day".concat(day_3.id)]) === null || _a === void 0 ? void 0 : _a.forEach(function (slot) {
                        var slotFromTime = slot.buttonName.split(':')[0];
                        if (editFromTime === slotFromTime) {
                            slot['color'] = 'slot-btn status-selected';
                        }
                    });
                    setDaySlots(daySlots);
                }
            });
        }
    };
    var clearSlotsOnchange = function () {
        var obj = {};
        weekDays.map(function (each) {
            obj["day".concat(each.id)] = JSON.parse(JSON.stringify(slotblocking_helper_1.CC_timeSlots));
        });
        setWeekDay(1);
        setDaySlots(__assign({}, obj));
    };
    React.useEffect(function () {
        if (editData &&
            !editData.base_location_address &&
            editData.latitude &&
            editData.longitude) {
            setGetLoactionFromLatLong(true);
        }
        else if (addressInfo &&
            !addressInfo.base_location_address &&
            addressInfo.latitude &&
            addressInfo.longitude) {
            setGetLoactionFromLatLong(true);
        }
    }, [editData, addressInfo]);
    React.useEffect(function () {
        if (!isMounted) {
            getWeekDays();
            isMounted = true;
        }
    }, [editLocation]);
    React.useEffect(function () {
        editDataSlots();
    }, [editData]);
    React.useEffect(function () {
        clearSlots();
    }, [isRouteChanged]);
    var returnweekdays = function (setFieldValue, touched, errors) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true },
                React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true },
                    React.createElement("h2", { className: "selected-felabel custom-form-label p-24" }, "Select Center Time Slot Hours"),
                    errors['day'] && touched['day'] ? (React.createElement("div", { className: "error" },
                        errors['day'],
                        " ")) : null)),
            React.createElement(Grid_1.default, { item: true, xs: 12 },
                React.createElement("div", { className: "days-list-wrapper tabs-list-wrapper" },
                    React.createElement(Stack_1.default, { className: "tabs-wrapper", direction: "row", alignItems: "center", justifyContent: "space-between", width: "100%" },
                        React.createElement(material_1.Tabs, { onChange: function (event, newValue) { return setSlotid(newValue); }, className: "tab-links" }, weekDays === null || weekDays === void 0 ? void 0 : weekDays.map(function (weekDaysDetails) {
                            return (React.createElement(material_1.Tab, { className: activeTab == weekDaysDetails.id ? 'tab-item active-tab' : 'tab-item', label: weekDaysDetails.day, disabled: routeType === 'NON_ROUTE' ? true : false, onClick: function () {
                                    setWeekDay(weekDaysDetails.id);
                                    weekDayColorHandler(weekDaysDetails);
                                    console.log('weekDaysDetails', weekDaysDetails);
                                    var postOnchangeSlotDetails = weekDays === null || weekDays === void 0 ? void 0 : weekDays.filter(function (weekDaysObjects) {
                                        if (weekDaysObjects['className'] === 'tab-item active-tab') {
                                            return weekDaysObjects.id;
                                        }
                                    });
                                    setFieldValue('day', postOnchangeSlotDetails);
                                } }));
                        })),
                        React.createElement(modal_component_1.default, { buttonName: "Apply For Multiple Days", buttonColor: "yellow-outline", onClickHandler: daysModal, open: weekDaysModal, modalSize: "long-modal", isBtnDisabled: routeType === 'NON_ROUTE' ? true : false },
                            React.createElement(RoastersForMultipleDays_1.default, { weekDaysSubmitHandler: function (selectedDays) {
                                    return weekDaysSubmitHandler(selectedDays);
                                }, weekDays: weekDays, cancelhandler: modalCloseHandler })))))));
    };
    var modalCloseHandler = function () {
        setWeekDaysModal(false);
    };
    var returnslots = function (errors, touched, setFieldValue) {
        console.log(selectedDay, 'selectedDay');
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { item: true, xs: 14, lg: 12 },
                React.createElement("div", { className: "p-24" },
                    console.log(daySlots, 'daysllts'),
                    console.log(daySlots, '867gyjvnb'),
                    daySlots && (React.createElement(CC_Roster_Timeslots_1.default, { name: "selectedSlots", onChange: function (val) {
                            setFieldValue('selectedSlots', val);
                            console.log('selectedSlotssss', val);
                        }, selectedSlots: selectedSlots, setDaySlots: setDaySlots, setSelectedSlots: setSelectedSlots, apislotdetails: apislotdata, selectedDay: selectedDay, isDayselected: isDayselected, slotid: slotid, timeSlotsList: timeSlotsList, setTimeSlotList: setTimeSlotList, daySlots: daySlots, dayset: '', addedData: addedData, setAddedData: setAddedData, editData: editData === null || editData === void 0 ? void 0 : editData.schedule, weekDay: weekDay && weekDay, disabled: routeType === 'NON_ROUTE' ? true : false })),
                    React.createElement("div", { className: "cc-slot-legend" },
                        React.createElement("div", { className: "legend-item bg-existing" }, "Available"),
                        React.createElement("div", { className: "legend-item bg-selected" }, "Selected"))))));
    };
    return (React.createElement("div", { className: "block-slot-wrapper" },
        React.createElement("div", { className: "scrollable-content" },
            React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, enableReinitialize: true, validationSchema: searchBy
                    ? schema_1.schemas.CC_RoasterManagement_Schema
                    : schema_1.schemas.CC_RoasterManagementWarehuse_Schema, onSubmit: formSubmitHandler }, function (_a) {
                var _b, _c;
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                        React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                            React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTOListPage }),
                            React.createElement("h2", null, id ? 'Edit Time Window' : 'Add Time Window')),
                        React.createElement("div", { className: "page-control-wrapper" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateTOListPage }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))),
                    React.createElement("div", { className: "add-slotform form-filed-wrapper" },
                        React.createElement("button", { type: "submit", ref: hiddenInnerSubmitFormRef, className: "display_hide" }, "Submit"),
                        !id && (React.createElement(Grid_1.default, { item: true, xs: 12 },
                            React.createElement("div", { className: "radio-btn-wrapper green-tick column-type-radios" },
                                React.createElement(material_1.FormLabel, { className: "radio-btn-label" },
                                    "Search By ",
                                    React.createElement("span", { className: "asterisk" }, "*")),
                                React.createElement(material_1.RadioGroup, { row: true, name: "searchBy", onChange: function (event) {
                                        clearSlotsOnchange();
                                        setlabcode('');
                                        setDefaultOndemand(0);
                                        setFieldValue('state', '');
                                        setFieldValue('city', '');
                                        setFieldValue('warehouse_code', '');
                                        setFieldValue('base_location_address', '');
                                        setFieldValue('baseLocation', '');
                                        setFieldValue('labcode', '');
                                        setinfoTag({});
                                        if (event.target.value == 'false') {
                                            setSearchBy(false);
                                        }
                                        else {
                                            setSearchBy(true);
                                        }
                                    }, key: Math.random().toString(), defaultValue: searchBy },
                                    React.createElement(material_1.FormControlLabel, { value: true, control: React.createElement(material_1.Radio, null), label: "Lab Code" }),
                                    React.createElement(material_1.FormControlLabel, { value: false, control: React.createElement(material_1.Radio, null), label: "Warehouse Code" }))))),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                            searchBy ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement("div", { className: "clear-dropdown" },
                                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "labcode", value: editData && (editData === null || editData === void 0 ? void 0 : editData.lab_code)
                                            ? (0, CC_helper_1.getSelectedLabDetils)(labList, editData.lab_code)
                                            : labcode, dropDownList: convertedLabList, onChange: function (val) {
                                            setlabcode(val);
                                            setFieldValue('state', '');
                                            setFieldValue('city', '');
                                            setFieldValue('warehouse_code', '');
                                            setFieldValue('base_location_address', '');
                                            setFieldValue('baseLocation', '');
                                            setSelecteFeIds([]);
                                            callbackOnselectedLab(val);
                                            clearSlots();
                                            setFieldValue('labcode', val);
                                            setFieldTouched('labcode', true);
                                        }, placeholder: "Lab Code ", required: true, isDisabled: id ? true : false }),
                                    labcode === null ? (React.createElement("div", { className: "error" },
                                        errorMsg,
                                        " ")) : errors['labcode'] && touched['labcode'] ? (React.createElement("div", { className: "error" },
                                        errors['labcode'],
                                        " ")) : null))) : (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "WarehouseCode", placeholder: 'Warehouse Code', label: "Warehouse Code", onChange: function (val) {
                                        setlabcode('');
                                        setDefaultOndemand(0);
                                        setFieldValue('state', '');
                                        setFieldValue('city', '');
                                        setFieldValue('warehouse_code', '');
                                        setFieldValue('base_location_address', '');
                                        setFieldValue('baseLocation', '');
                                        setFieldValue('labcode', '');
                                        setinfoTag({});
                                        clearSlotsOnchange();
                                        setFieldValue('warehouse_codes', val);
                                        setFieldTouched('warehouse_codes', true);
                                        if (val.length > 2) {
                                            callbackOnselectedCC(val, setFieldValue);
                                        }
                                        else {
                                            setWarehousecodeError('');
                                        }
                                    }, value: values === null || values === void 0 ? void 0 : values.warehouse_codes }),
                                React.createElement("div", { className: "error" },
                                    warehousecodeError,
                                    " "),
                                errors['warehouse_codes'] && touched['warehouse_codes'] ? (React.createElement("div", { className: "error" },
                                    errors['warehouse_codes'],
                                    " ")) : null)),
                            searchBy ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "warehouse_code", placeholder: "Select CC/PUP/WH/Invoice Codes", label: "Select CC/PUC", value: values.warehouse_code, dropDownList: labcode ? filteredCCs : [], onChange: function (val) {
                                        clearSlots();
                                        setActiveTab(1);
                                        setFieldValue('state', val.centre_state);
                                        setFieldValue('city', val.center_city);
                                        setFieldValue('warehouse_code', val);
                                        setFieldTouched('warehouse_code', true);
                                        setFieldValue('base_location_address', '');
                                        setFieldValue('baseLocation', '');
                                        setSelecteFeIds([]);
                                        callbackOnselectedCC(val, setFieldValue);
                                        clearSlots();
                                    }, key: values.labcode, required: true, isDisabled: id ? true : false }),
                                errors['warehouse_code'] && touched['warehouse_code'] ? (React.createElement("div", { className: "error" },
                                    errors['warehouse_code'],
                                    " ")) : null)) : (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "labCode", placeholder: 'Lab Code', label: "Lab Code", isDisabled: true, value: (_b = values === null || values === void 0 ? void 0 : values.labcode) === null || _b === void 0 ? void 0 : _b.value, key: (_c = values === null || values === void 0 ? void 0 : values.labcode) === null || _c === void 0 ? void 0 : _c.value }))),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "state", placeholder: 'State', label: "State", isDisabled: true, value: values.state, key: values.state })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "city", placeholder: 'City', label: "City", isDisabled: true, value: values.city, key: values.city })),
                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                React.createElement("div", { className: "radio-btn-wrapper green-tick column-type-radios" },
                                    React.createElement(material_1.FormLabel, { className: "radio-btn-label" },
                                        "Routing Assignment ",
                                        React.createElement("span", { className: "asterisk" }, "*")),
                                    React.createElement(material_1.RadioGroup, { row: true, name: "activate", onChange: function (event) {
                                            console.log(event.target.value, '678yugj');
                                            setIsRoutechanged(true);
                                            setRouteType(event.target.value);
                                        }, key: routeType, defaultValue: routeType },
                                        React.createElement(material_1.FormControlLabel, { value: "NON_ROUTE", control: React.createElement(material_1.Radio, null), label: "Non Route" }),
                                        React.createElement(material_1.FormControlLabel, { value: "STATIC", control: React.createElement(material_1.Radio, null), label: "Static" }),
                                        React.createElement(material_1.FormControlLabel, { value: "ULTRA_STATIC", control: React.createElement(material_1.Radio, null), label: "Ultra Static" }),
                                        React.createElement(material_1.FormControlLabel, { value: "DYNAMIC", control: React.createElement(material_1.Radio, null), label: "Dynamic" }))),
                                errors['activate'] && touched['activate'] ? (React.createElement("div", { className: "error" }, errors['activate'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                React.createElement("div", { className: "radio-btn-wrapper green-tick column-type-radios" },
                                    React.createElement(material_1.FormLabel, { className: "radio-btn-label" },
                                        "Can handover Samples to Lab ",
                                        React.createElement("span", { className: "asterisk" }, "*")),
                                    React.createElement(material_1.RadioGroup, { row: true, name: "handoverdone", onChange: function (event) {
                                            console.log(event.target.value, '987yugnb');
                                            setLabHandover(event.target.value);
                                            setFieldValue('handoverdone', event.target.value);
                                            setFieldTouched('handoverdone', true);
                                        }, key: Math.random().toString(), defaultValue: labHandover },
                                        React.createElement(material_1.FormControlLabel, { value: true, control: React.createElement(material_1.Radio, null), label: "Yes" }),
                                        React.createElement(material_1.FormControlLabel, { value: false, control: React.createElement(material_1.Radio, null), label: "No" }))),
                                errors['handoverdone'] && touched['handoverdone'] ? (React.createElement("div", { className: "error" }, errors['handoverdone'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                React.createElement(profile_grid_1.default, { lableName: 'CREATED DATE TIME', value: (infoTag === null || infoTag === void 0 ? void 0 : infoTag.created_date_time)
                                        ? (0, array_helpers_1.dateTimeFormatter)(infoTag.created_date_time)
                                        : '--' })),
                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                React.createElement(profile_grid_1.default, { lableName: 'FIRST ROSTER UPDATED ON', value: (infoTag === null || infoTag === void 0 ? void 0 : infoTag.first_roster_updated_on)
                                        ? (0, array_helpers_1.dateTimeFormatter)(infoTag.first_roster_updated_on)
                                        : '--' })),
                            React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                React.createElement(Grid_1.default, { item: true, xs: 12 },
                                    React.createElement("h2", { className: "label-heading heading" }, "CC Base Location")),
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement(text_filed_with_icon_1.default, { name: "base_location_address", placeholder: "Base Location Address", label: "Base Location Address", onClick: function () { return toggleMapView(!showMaps); }, value: values.base_location_address, key: editLocation }),
                                    errors['base_location_address'] &&
                                        touched['base_location_address'] ? (React.createElement("div", { className: "error" },
                                        errors['base_location_address'],
                                        " ")) : null),
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement(text_filed_component_1.default, { name: "baseLocation", placeholder: "FE Base Location", label: "Latitude & Longitude", onChange: function (val) {
                                            setFieldValue('baseLocation', val);
                                            setFieldTouched('baseLocation', true);
                                        }, value: values.baseLocation, isDisabled: true }))),
                            React.createElement(Grid_1.default, { item: true, xs: 12, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
                                React.createElement("h2", { className: "label-heading heading" }, "On-Demand Request"),
                                React.createElement(modal_component_1.default, { buttonName: "ADD EXTRA CREDITS", buttonColor: "yellow-outline", onClickHandler: extraCreditModalCall, open: extraCreditModal, modalSize: "long-modal", isBtnDisabled: !raiseOnDemandRequests },
                                    React.createElement("div", { className: "modal-header " },
                                        React.createElement("h2", { className: "heading h1tagcolor" }, "Add Extra Credits "),
                                        React.createElement("h2", { className: "heading h1tagcolor" },
                                            "Please enter number of credits to be added",
                                            ' ')),
                                    React.createElement(text_filed_component_1.default, { name: "credits", placeholder: 'Credits', label: "Credits", onChange: function (val) { return ontextChange(val); }, onKeyPress: function (e) {
                                            if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                                e.target.value.length <= 1) {
                                            }
                                            else {
                                                e.preventDefault();
                                            }
                                        } }),
                                    errorCredit && React.createElement("div", { className: "error" },
                                        errorCredit,
                                        " "),
                                    React.createElement("div", { className: "modal-footer" },
                                        React.createElement(button_component_1.default, { buttonName: "CANCEL", color: "yellow-outline", onClickHandler: function () { return setExtraCreditModal(false); } }),
                                        React.createElement(button_component_1.default, { buttonName: "ADD", type: "submit", color: "yellow", onClickHandler: addCredits })))),
                            React.createElement(Grid_1.default, { item: true, xs: 12 },
                                React.createElement("div", { className: "radio-btn-wrapper green-tick column-type-radios" },
                                    React.createElement(material_1.FormLabel, { className: "radio-btn-label" },
                                        "Can raise On-Demand Request ",
                                        React.createElement("span", { className: "asterisk" }, "*")),
                                    React.createElement(material_1.RadioGroup, { row: true, name: "raiseOnDemandRequest", onChange: function (event) {
                                            setAddCreditBtn(event.target.value);
                                            if (event.target.value == 'false') {
                                                setRaiseOnDemandRequest(false);
                                            }
                                            else {
                                                setRaiseOnDemandRequest(true);
                                            }
                                            setFieldValue('raiseOnDemandRequest', event.target.value);
                                            setFieldTouched('raiseOnDemandRequest', true);
                                        }, key: Math.random().toString(), defaultValue: raiseOnDemandRequests },
                                        React.createElement(material_1.FormControlLabel, { value: true, control: React.createElement(material_1.Radio, null), label: "Yes" }),
                                        React.createElement(material_1.FormControlLabel, { value: false, control: React.createElement(material_1.Radio, null), label: "No" })))),
                            React.createElement(Grid_1.default, { item: true, xs: 4 },
                                React.createElement(profile_grid_1.default, { lableName: 'Month & Year', value: (infoTag === null || infoTag === void 0 ? void 0 : infoTag.month) ? "".concat(infoTag === null || infoTag === void 0 ? void 0 : infoTag.month, ",").concat(infoTag === null || infoTag === void 0 ? void 0 : infoTag.year) : '--' })),
                            React.createElement(Grid_1.default, { item: true, xs: 4 },
                                React.createElement(profile_grid_1.default, { lableName: 'Available On-Demand request', value: defaultOndemand }),
                                console.log('ghgjj', defaultOndemand)),
                            React.createElement(Grid_1.default, { item: true, xs: 4 },
                                React.createElement(profile_grid_1.default, { lableName: 'Used On-Demand request', value: (infoTag === null || infoTag === void 0 ? void 0 : infoTag.used_credits) >= 0 ? "".concat(infoTag === null || infoTag === void 0 ? void 0 : infoTag.used_credits) : '--' })))),
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                        selecteFeIds.length !== 0 && (React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true },
                            React.createElement(Grid_1.default, { xs: 6, lg: 6, item: true },
                                React.createElement(selected_CC_1.default, { selecteFeIds: labcode ? selecteFeIds : '', setSelecteFeIds: setSelecteFeIds, name: "CC", setFieldValue: setFieldValue, clearSlots: clearSlots })))),
                        returnweekdays(setFieldValue, touched, errors),
                        returnslots(errors, touched, setFieldValue),
                        showMaps && (React.createElement(React.Fragment, null,
                            React.createElement(map_1.default, { bast_lat_lng: values.baseLocation, baselocation_address: values.base_location_address, submitSelectedPlace: function (latAndLng, fe_current_location) {
                                    if (latAndLng) {
                                        setFieldValue('baseLocation', "".concat(latAndLng));
                                        setFieldTouched('baseLocation', true);
                                        setFieldValue('base_location_address', "".concat(fe_current_location));
                                        setFieldTouched('base_location_address', true);
                                        toggleMapView(false);
                                    }
                                }, close: function () { return toggleMapView(false); } })))),
                    React.createElement(ModalComponentWithoutButton_1.default, { open: isRoutechanged },
                        React.createElement("div", { className: "modal-header " },
                            React.createElement("h2", { className: "heading" }, "Confirmation"),
                            React.createElement("p", { className: "sub-heading" },
                                "Are you sure you want to change the route type?",
                                React.createElement("br", null))),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: "NO", color: "yellow-outline", onClickHandler: function () {
                                    setRouteType(editData ? editData === null || editData === void 0 ? void 0 : editData.routing_assignment : values.activate);
                                    setIsRoutechanged(false);
                                } }),
                            React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", onClickHandler: function () {
                                    setIsRoutechanged(false);
                                    setIsRouteChanged(!isRouteChanged);
                                    setFieldValue('activate', routeType);
                                    setFieldTouched('activate', true);
                                } }))),
                    React.createElement(ModalComponentWithoutButton_1.default, { open: isSlotsEmpty },
                        React.createElement("div", { className: "modal-header " },
                            React.createElement("h2", { className: "heading" }, "Confirmation"),
                            React.createElement("p", { className: "sub-heading" }, "Are you sure you want to submit without slots?")),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: "NO", color: "yellow-outline", onClickHandler: function () {
                                    setIsSlotsEmpty(false);
                                } }),
                            React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", onClickHandler: function () {
                                    formSubmitHandler(values);
                                } }))),
                    getLoactionFromLatLong && (React.createElement(reverseGeoCode_1.default, { setFieldValue: setFieldValue, setEditLocation: setEditLocation, lat: editLatitude ? editLatitude : addressInfo.latitude, lng: editLongitude ? editLongitude : addressInfo.longitude }))));
            }))));
};
exports.default = ModifyTimeSlot;
