"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var CheckCircleOutline_1 = require("@mui/icons-material/CheckCircleOutline");
var React = require("react");
var CheckedIconButton = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn", onClick: onClick },
            React.createElement(CheckCircleOutline_1.default, { sx: { fontSize: '30px', color: 'green' } }))));
};
exports.default = CheckedIconButton;
