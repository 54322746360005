"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var ExpandMore_1 = require("@mui/icons-material/ExpandMore");
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var Stack_1 = require("@mui/system/Stack");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../common/buttons/button_component");
var iconButton_component_1 = require("../../common/buttons/iconButton_component");
var switch_button_1 = require("../../common/buttons/switch_button");
var drop_down_component_1 = require("../../common/drop_down/drop_down_component");
var map_1 = require("../../common/map");
var ModalComponentWithoutButton_1 = require("../../common/modal/ModalComponentWithoutButton");
var modal_component_1 = require("../../common/modal/modal_component");
var RoastersForMultipleDays_1 = require("../../common/Roaster/RoastersForMultipleDays");
var text_filed_with_icon_1 = require("../../common/text_filed/text-filed-with-icon");
var text_filed_component_1 = require("../../common/text_filed/text_filed_component");
var route_path_1 = require("../../constants/route_path");
var schema_1 = require("../../constants/schema");
var selected_CC_1 = require("../../menuList/selected_CC");
var field_exicutive_types_1 = require("../../types/field_exicutive_types");
var array_helpers_1 = require("../../utils/array_helpers");
var lodash_1 = require("../../utils/lodash");
var CC_api_calls_1 = require("../super_admin/CC/CC_api_calls");
var CC_helper_1 = require("../super_admin/CC/CC_helper");
var reverseGeoCode_1 = require("../super_admin/CC/reverseGeoCode");
var lab_master_api_calls_1 = require("./lab_master_api_calls");
var Lab_Master_slots_1 = require("./Lab_Master_slots");
var moment = require("moment");
require("./lab_master.scss");
var text_filed_with_fromto_Daterange_1 = require("../../common/text_filed/text_filed_with_fromto_Daterange");
var CreateLabMaster = function () {
    var isMounted = false;
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var ultrastatic_schedule_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_schedule_types; });
    var filtered_schedules = ultrastatic_schedule_types.filter(function (value) { return value.value != 1; });
    var _a = React.useState([]), selectedList = _a[0], setSelectedList = _a[1];
    var convertedLabList = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var _b = React.useState([]), weekDays = _b[0], setWeekDays = _b[1];
    var _c = React.useState(), defaultSlots = _c[0], setDefaultSlots = _c[1];
    var _d = React.useState(), apidata = _d[0], setApidata = _d[1];
    var _e = React.useState(), editData = _e[0], setEditdata = _e[1];
    var _f = React.useState(), apislotdata = _f[0], setApislotdata = _f[1];
    var _g = React.useState(1), activeTab = _g[0], setActiveTab = _g[1];
    var _h = React.useState(), selectedDay = _h[0], setSelectedDay = _h[1];
    var _j = React.useState(false), isDayselected = _j[0], setDayselected = _j[1];
    var _k = React.useState(), daySlots = _k[0], setDaySlots = _k[1];
    var _l = React.useState(), labcode = _l[0], setlabcode = _l[1];
    var _m = React.useState(), labid = _m[0], setlabid = _m[1];
    var _o = React.useState(), route_info = _o[0], setroute_info = _o[1];
    var _p = React.useState([]), gettimeSlotsList = _p[0], setgetTimeSlotList = _p[1];
    var _q = React.useState(), addedData = _q[0], setAddedData = _q[1];
    var _r = React.useState([]), arr = _r[0], setArr = _r[1];
    var payloadobject = React.useState({})[0];
    var _s = React.useState(false), showMaps = _s[0], toggleMapView = _s[1];
    var _t = React.useState(false), weekDaysModal = _t[0], setWeekDaysModal = _t[1];
    var _u = React.useState(), slotid = _u[0], setSlotid = _u[1];
    var _v = React.useState([]), selecteFeIds = _v[0], setSelecteFeIds = _v[1];
    var _w = React.useState([]), selectedSlots = _w[0], setSelectedSlots = _w[1];
    var hiddenInnerSubmitFormRef = React.useRef(null);
    var _x = React.useState(1), weekDay = _x[0], setWeekDay = _x[1];
    var _y = React.useState(), editSlots = _y[0], setEditSlots = _y[1];
    var _z = React.useState(), isRouteChanged = _z[0], setIsRouteChanged = _z[1];
    var _0 = React.useState('NON_ROUTE'), routeType = _0[0], setRouteType = _0[1];
    var _1 = React.useState(false), isRoutechanged = _1[0], setIsRoutechanged = _1[1];
    var _2 = React.useState(false), isSlotsEmpty = _2[0], setIsSlotsEmpty = _2[1];
    var _3 = React.useState(1), weekdayid = _3[0], setWeekayid = _3[1];
    var _4 = React.useState(), editLatitude = _4[0], setEditLatitude = _4[1];
    var _5 = React.useState(), editLongitude = _5[0], setEditLongitude = _5[1];
    var _6 = React.useState(null), addressInfo = _6[0], setAddressInfo = _6[1];
    var _7 = React.useState(), editLocation = _7[0], setEditLocation = _7[1];
    var _8 = React.useState(false), getLoactionFromLatLong = _8[0], setGetLoactionFromLatLong = _8[1];
    var errorMsg = 'Please Select Lab code';
    var _9 = React.useState(field_exicutive_types_1.LAB_INTITAL_VALUES), initialValues = _9[0], setInitialValues = _9[1];
    var _10 = React.useState(), selectedroute = _10[0], setselectedroute = _10[1];
    var _11 = React.useState({
        fromDate: '',
        toDate: ''
    }), editDateRange = _11[0], setEditDateRange = _11[1];
    var roootType = 'NON_ROUTE';
    var handOverToLab = true;
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var route = {};
    var navigateTOListPage = function () {
        nav("/".concat(route_path_1.default.PATHS.LabMasterlist));
    };
    var modalCloseHandler = function () {
        setWeekDaysModal(false);
    };
    React.useEffect(function () {
        if (route_info && (route_info === null || route_info === void 0 ? void 0 : route_info.length) > 0) {
            route_info &&
                (route_info === null || route_info === void 0 ? void 0 : route_info.map(function (e) {
                    if ((e === null || e === void 0 ? void 0 : e.assignment_type) === 2) {
                        if ((e === null || e === void 0 ? void 0 : e.speed) != null || (e === null || e === void 0 ? void 0 : e.speed) != undefined) {
                            route['staticspeed'] = e.speed;
                        }
                        else if ((e === null || e === void 0 ? void 0 : e.radius) != null) {
                            route['staticradius'] = e.radius;
                        }
                        (0, array_helpers_1.static_route_plan_center_types)(e.center_type, route, e.buffer_time);
                    }
                    else if (e.assignment_type === 1) {
                        if ((e === null || e === void 0 ? void 0 : e.speed) != null) {
                            route['dynamicspeed'] = e.speed;
                        }
                        else if ((e === null || e === void 0 ? void 0 : e.radius) != null) {
                            route['dynamicradius'] = e.radius;
                        }
                        (0, array_helpers_1.dynamic_route_plan_center_types)(e.center_type, route, e.buffer_time);
                    }
                    setselectedroute(route);
                }));
        }
    }, [editData, route_info]);
    var callbackOnselectedLabList = function (val, setFieldValue, values, setValues) {
        var route = {};
        var obj = {};
        weekDays.map(function (each) {
            obj["day".concat(each.id)] = lodash_1.default.cloneDeep(gettimeSlotsList);
        });
        var payload = {
            warehouse_code: val.value,
        };
        (0, lab_master_api_calls_1.fetchLabEditList)(payload)
            .then(function (res) {
            var _a, _b, _c, _d;
            setEditSlots(res.data);
            setAddressInfo(res.data);
            setlabid(res.data.center_id);
            if (((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.routing_information) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                res.data.routing_information.map(function (e) {
                    if ((e === null || e === void 0 ? void 0 : e.assignment_type) === 2) {
                        if ((e === null || e === void 0 ? void 0 : e.speed) != null) {
                            route['staticspeed'] = e.speed;
                        }
                        else if ((e === null || e === void 0 ? void 0 : e.radius) != null) {
                            route['staticradius'] = e.radius;
                        }
                        (0, array_helpers_1.static_route_plan_center_types)(e.center_type, route, e.buffer_time);
                    }
                    else if (e.assignment_type === 1) {
                        if ((e === null || e === void 0 ? void 0 : e.speed) != null) {
                            route['dynamicspeed'] = e.speed;
                        }
                        else if ((e === null || e === void 0 ? void 0 : e.radius) != null) {
                            route['dynamicradius'] = e.radius;
                        }
                        (0, array_helpers_1.dynamic_route_plan_center_types)(e.center_type, route, e.buffer_time);
                    }
                });
            }
            if (weekDays.length > 0) {
                var day_1;
                var listFromRes_1 = [];
                (_d = (_c = res.data) === null || _c === void 0 ? void 0 : _c.schedule) === null || _d === void 0 ? void 0 : _d.map(function (schedule) {
                    var _a;
                    day_1 = (0, CC_helper_1.getDayFromId)(schedule.day, weekDays);
                    var editFromTime = schedule.schedule_time_slot.split('-')[0];
                    listFromRes_1.push({
                        parentslot: schedule.schedule_time_slot.split('-')[0],
                        childslot: schedule.schedule_time_slot.split('-')[1],
                        weekday: schedule.day,
                    });
                    if (day_1) {
                        (_a = obj["day".concat(day_1.id)]) === null || _a === void 0 ? void 0 : _a.forEach(function (slot, index) {
                            var slotFromTime = slot.from_time.split('-')[0];
                            if (editFromTime.toString().trim() == slotFromTime.toString().trim()) {
                                slot['color'] = 'slot-btn status-selected';
                                slot['onehourslot'] = schedule.schedule_time_slot;
                                obj["day".concat(day_1.id)][index + 1]['color'] = 'slot-btn status-selected';
                                obj["day".concat(day_1.id)][index + 1]['onehourslot'] =
                                    schedule.schedule_time_slot;
                            }
                        });
                        setDaySlots(lodash_1.default.cloneDeep(obj));
                        setSelectedList(listFromRes_1);
                    }
                });
                setSelectedList(listFromRes_1);
            }
            var valuesObj = lodash_1.default.extend({}, values, {
                labcode: val ? val : '',
                state: val.center_state ? val.center_state : '',
                city: val.center_city ? val.center_city : '',
                dynamicradius: route.dynamicradius ? route.dynamicradius : '',
                dynamic_speed: route.dynamicspeed ? route.dynamicspeed : '',
                staticspeed: route.staticspeed ? route.staticspeed : '',
                staticradius: route.staticradius ? route.staticradius : '',
                static_cc: route.static_cc ? route.static_cc : '',
                static_psc: route.static_psc ? route.static_psc : '',
                static_fpsc: route.static_fpsc ? route.static_fpsc : '',
                static_pup: route.static_pup ? route.static_pup : '',
                static_lab: route.static_lab ? route.static_lab : '',
                static_hub: route.static_hub ? route.static_hub : '',
                dynamic_cc: route.dynamic_cc ? route.dynamic_cc : '',
                dynamic_psc: route.dynamic_psc ? route.dynamic_psc : '',
                dynamic_fpsc: route.dynamic_fpsc ? route.dynamic_fpsc : '',
                dynamic_pup: route.dynamic_pup ? route.dynamic_pup : '',
                dynamic_lab: route.dynamic_lab ? route.dynamic_lab : '',
                dynamic_hub: route.dynamic_hub ? route.dynamic_hub : '',
                baseLocation: res.data.latitude && res.data.longitude
                    ? "".concat(res.data.latitude, ",").concat(res.data.longitude)
                    : '',
                base_location_address: res.data.base_location_address
                    ? res.data.base_location_address
                    : '',
                geofence_enabled: res.data.geofence_enabled
                    ? res.data.geofence_enabled
                    : 0,
                geofence_radius: res.data.geofence_radius ? res.data.geofence_radius : 0,
            });
            setValues(valuesObj, 'valuesObj');
        })
            .catch(function (e) { return console.log(e); });
    };
    var clearsetfieldvalues = function (setValues) {
        var valuesObj = lodash_1.default.extend({}, {
            labcode: '',
            state: '',
            city: '',
            dynamicradius: '',
            dynamic_speed: '',
            staticspeed: '',
            staticradius: '',
            static_cc: '',
            static_psc: '',
            static_fpsc: '',
            static_pup: '',
            static_lab: '',
            static_hub: '',
            dynamic_cc: '',
            dynamic_psc: '',
            dynamic_fpsc: '',
            dynamic_pup: '',
            dynamic_lab: '',
            dynamic_hub: '',
            baseLocation: '',
            base_location_address: '',
            geofence_enabled: 0,
            geofence_radius: 0,
        });
        setValues(valuesObj, 'valuesObj');
    };
    var clearSlots = function () {
        var obj = {};
        weekDays.map(function (each) {
            obj["day".concat(each.id)] = lodash_1.default.cloneDeep(gettimeSlotsList);
        });
        setWeekDay(1);
        setDaySlots(lodash_1.default.cloneDeep(defaultSlots));
        editTimeSlots(obj);
    };
    var getWeekDays = function () {
        (0, CC_api_calls_1.getAllWeekDays)()
            .then(function (response) {
            setWeekDays(response.data);
            var obj = {};
            response.data.map(function (each) {
                obj["day".concat(each.id)] = JSON.parse(JSON.stringify(gettimeSlotsList));
            });
            setDaySlots(__assign({}, obj));
            if (id) {
                getEditDetails();
            }
        })
            .catch(function (e) { return console.log(e); });
    };
    var getEditDetails = function () {
        var payload = {
            warehouse_code: id,
        };
        (0, lab_master_api_calls_1.fetchLabEditList)(payload)
            .then(function (res) {
            setEditLatitude(res.data.latitude);
            setEditLongitude(res.data.longitude);
            setlabid(res.data.center_id);
            setEditSlots(res.data);
            setEditdata(res.data);
            setroute_info(res.data.routing_information);
            roootType =
                res.data.routing_assignment &&
                    (0, CC_helper_1.getRouteTypeFromEdit)(res.data.routing_assignment);
            setRouteType(roootType);
            handOverToLab = (0, CC_helper_1.getHandOverFromEdit)(res.data.can_handover_to_lab);
            setApidata(res);
            editInitialValues(res.data);
        })
            .catch(function (e) { return console.log(e); });
    };
    var editTimeSlots = function (obj) {
        var _a;
        if (weekDays.length > 0) {
            var day_2;
            (_a = editSlots === null || editSlots === void 0 ? void 0 : editSlots.schedule) === null || _a === void 0 ? void 0 : _a.map(function (schedule) {
                var _a;
                day_2 = (0, CC_helper_1.getDayFromId)(schedule.day, weekDays);
                var editFromTime = schedule.schedule_time_slot;
                setSelectedList(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                    {
                        parentslot: schedule.schedule_time_slot.split('-')[0],
                        childslot: schedule.schedule_time_slot.split('-')[1],
                        weekday: schedule.day,
                    },
                ], false); });
                if (day_2) {
                    (_a = obj["day".concat(day_2.id)]) === null || _a === void 0 ? void 0 : _a.forEach(function (slot) {
                        var slotFromTime = slot.from_time;
                        if (editFromTime === slotFromTime) {
                            slot['color'] = 'slot-btn status-selected';
                            slot['onehourslot'] = schedule.schedule_time_slot;
                        }
                    });
                }
            });
            setDaySlots(__assign({}, obj));
        }
    };
    var editInitialValues = function (editData) {
        var _a, _b, _c, _d, _e, _f;
        if (editData) {
            var newFromDate = '';
            var newToDate = '';
            var scheduleRouteType = filtered_schedules.filter(function (type) { return type.value === editData.ultra_static_holiday_schedule.route; });
            if (((_a = editData === null || editData === void 0 ? void 0 : editData.ultra_static_holiday_schedule) === null || _a === void 0 ? void 0 : _a.from_date) && ((_b = editData === null || editData === void 0 ? void 0 : editData.ultra_static_holiday_schedule) === null || _b === void 0 ? void 0 : _b.to_date)) {
                newFromDate = new Date((_c = editData === null || editData === void 0 ? void 0 : editData.ultra_static_holiday_schedule) === null || _c === void 0 ? void 0 : _c.from_date);
                newToDate = new Date((_d = editData === null || editData === void 0 ? void 0 : editData.ultra_static_holiday_schedule) === null || _d === void 0 ? void 0 : _d.to_date);
                setEditDateRange({
                    fromDate: moment(new Date((_e = editData === null || editData === void 0 ? void 0 : editData.ultra_static_holiday_schedule) === null || _e === void 0 ? void 0 : _e.from_date)).format('DD/MM/YYYY'),
                    toDate: moment(new Date((_f = editData === null || editData === void 0 ? void 0 : editData.ultra_static_holiday_schedule) === null || _f === void 0 ? void 0 : _f.to_date)).format('DD/MM/YYYY')
                });
            }
            else {
                setEditDateRange(null);
            }
            setInitialValues({
                labcode: editData.lab_code
                    ? (0, CC_helper_1.getSelectedLabDetils)(labList, editData.lab_code)
                    : '',
                state: editData.state ? editData.state : '',
                city: editData.city ? editData.city : '',
                warehouse_code: editData ? (0, CC_helper_1.getSelectedWarehouseDetils)(editData) : '',
                schedule_time_slot: editData.lab_code ? editData.lab_code : '',
                activate: editData.routing_assignment ? routeType : '',
                handoverdone: editData.can_handover_to_lab ? handOverToLab : '',
                baseLocation: editData.latitude && editData.longitude
                    ? "".concat(editData.latitude, ",").concat(editData.longitude)
                    : '',
                base_location_address: editData.base_location_address
                    ? editData.base_location_address
                    : editLocation
                        ? editLocation
                        : '',
                id: editData.center_id,
                geofence_enabled: editData.geofence_enabled ? editData.geofence_enabled : 0,
                geofence_radius: editData.geofence_radius ? editData.geofence_radius : 0,
                etrf_scan_enabled: editData.etrf_scan_enabled ? editData.etrf_scan_enabled : 0,
                ultrastatic_routing_enabled: editData.ultrastatic_routing_enabled ? editData.ultrastatic_routing_enabled == 1 ? true : false : false,
                us_from_date: newFromDate != '' ? moment(newFromDate).format('DD/MM/YYYY') : '',
                us_to_date: newToDate != '' ? moment(newToDate).format('DD/MM/YYYY') : '',
                type: scheduleRouteType[0]
            });
        }
    };
    var setColor;
    var weekDayColorHandler = function (onChangeWeekValues) {
        setActiveTab(onChangeWeekValues.id);
        if (onChangeWeekValues.className) {
            setColor = weekDays.map(function (weekDaysObject) {
                if (weekDaysObject.id === onChangeWeekValues.id) {
                    weekDaysObject['className'] = null;
                    return weekDaysObject;
                }
                return weekDaysObject;
            });
        }
        else
            setDayselected(!isDayselected);
        setColor = weekDays.map(function (weekDaysObject) {
            if (weekDaysObject['className'] === 'tab-item active-tab') {
                weekDaysObject['className'] = null;
            }
            if (weekDaysObject.id === onChangeWeekValues.id) {
                weekDaysObject['className'] = 'tab-item active-tab';
                return weekDaysObject;
            }
            if (weekDaysObject.id === onChangeWeekValues.id) {
                apidata === null || apidata === void 0 ? void 0 : apidata.map(function (e) {
                    if (e.day === onChangeWeekValues.day) {
                        weekDaysObject['className'] = 'tab-item active-tab';
                        setApislotdata(e.slots);
                    }
                });
                return weekDaysObject;
            }
            return weekDaysObject;
        });
        setColor.map(function (e) {
            if ((e === null || e === void 0 ? void 0 : e.className) === 'tab-item active-tab') {
                setSelectedDay(e);
            }
        });
        setWeekDays(setColor);
    };
    var formsubmit = function (payLoad) {
        (0, lab_master_api_calls_1.createLabTimeSlot)(payLoad)
            .then(function (res) {
            if (res.statusCode == 200) {
                react_hot_toast_1.default.success(res.message);
            }
            else {
                react_hot_toast_1.default.error(res.message);
            }
            navigateTOListPage();
        })
            .catch(function (err) {
            navigateTOListPage();
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var formSubmitHandler = function (values) {
        var _a, _b, _c;
        var getSelectedSlots = function (perDaySlots, weekId) {
            var selectedArr = [];
            perDaySlots.map(function (each) {
                var _a;
                if (each.onehourslot) {
                    selectedArr.push((_a = {},
                        _a['day'] = weekId,
                        _a['schedule_time_slot'] = each.onehourslot,
                        _a));
                    return each;
                }
            });
            return selectedArr;
        };
        var arr = [];
        var payloadSlots = [];
        var newArray = [];
        var uniqueObject = {};
        weekDays.map(function (weekday) {
            var selectedSlotsPerDay = getSelectedSlots(daySlots["day".concat(weekday.id)], weekday.id);
            payloadSlots.push.apply(payloadSlots, selectedSlotsPerDay);
        });
        var filteredArr = payloadSlots.filter(function (currentUser, index) {
            return (payloadSlots.findIndex(function (user) {
                return user.schedule_time_slot === currentUser.schedule_time_slot &&
                    user.day === currentUser.day;
            }) === index);
        });
        for (var i in payloadSlots) {
            var objTitle = payloadSlots[i]['schedule_time_slot'] && payloadSlots[i]['day'];
            uniqueObject[objTitle] = payloadSlots[i];
        }
        for (var i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }
        var payload = {
            schedule: filteredArr,
            latitude: values.baseLocation.split(',')[0],
            longitude: values.baseLocation.split(',')[1],
            warehouse_code: (_a = values.labcode) === null || _a === void 0 ? void 0 : _a.value,
            base_location_address: values.base_location_address,
            id: labid,
            geofence_radius: values.geofence_radius,
            geofence_enabled: values.geofence_enabled ? true : false,
            etrf_scan_enabled: values.etrf_scan_enabled === 0 ? false : true,
            ultrastatic_routing_enabled: values.ultrastatic_routing_enabled,
        };
        if (values.us_from_date || values.us_to_date || ((_b = values === null || values === void 0 ? void 0 : values.type) === null || _b === void 0 ? void 0 : _b.value)) {
            payload['us_holiday_schedule'] = {};
        }
        if (values === null || values === void 0 ? void 0 : values.us_from_date) {
            payload['us_holiday_schedule']['from_date'] = values.us_from_date;
        }
        if (values === null || values === void 0 ? void 0 : values.us_to_date) {
            payload['us_holiday_schedule']['to_date'] = values.us_to_date;
        }
        if ((_c = values === null || values === void 0 ? void 0 : values.type) === null || _c === void 0 ? void 0 : _c.value) {
            payload['us_holiday_schedule']['route'] = values.type.value;
        }
        setArr(function (prev) { return __spreadArray(__spreadArray([], prev, true), [payload], false); });
        payloadobject['schedule'] = arr;
        if (payloadSlots.length === 0 && !isSlotsEmpty) {
            setIsSlotsEmpty(true);
        }
        else {
            formsubmit(payload);
        }
    };
    var weekDaysSubmitHandler = function (selectedDays) {
        selectedDays.push("day".concat(weekDay));
        var selectedDaySlots = daySlots["day".concat(weekDay)];
        var colorIncludedSlots = [];
        var filterUniqueDyas = (0, lab_master_api_calls_1.remove_duplicates_Slots)(selectedDays);
        var obj = {};
        var k = JSON.parse(JSON.stringify(daySlots));
        filterUniqueDyas === null || filterUniqueDyas === void 0 ? void 0 : filterUniqueDyas.forEach(function (week) {
            var arr = [];
            selectedDaySlots.map(function (each, index) {
                arr.push(each);
                k[week] = JSON.parse(JSON.stringify(daySlots["day".concat(weekDay)]));
                selectedList.map(function (val) {
                    if (each.from_time.toString().trim() == val.parentslot.toString().trim() &&
                        val.weekday == activeTab) {
                        colorIncludedSlots.push({
                            parentslot: each.from_time,
                            childslot: selectedDaySlots[index + 1].to_time,
                            weekday: (0, lab_master_api_calls_1.getWeekDay)(week),
                        });
                    }
                });
            });
            obj[week] = arr;
        });
        setDaySlots(k);
        var comapreSlots = function (obj) {
            return colorIncludedSlots.find(function (singleObh) { return singleObh.weekday === obj.weekday; });
        };
        var removedDuplicates = selectedList.filter(function (singleEle) { return !comapreSlots(singleEle); });
        var concatSlots = __spreadArray(__spreadArray([], removedDuplicates, true), colorIncludedSlots, true);
        var uniqueSlots = lodash_1.default.uniqWith(concatSlots, function (slotA, slotB) {
            return slotA.parentslot == slotB.parentslot && slotA.weekday == slotB.weekday;
        });
        setSelectedList(uniqueSlots);
        react_hot_toast_1.default.success('Slots have been added succesfully for Selected Days', {
            duration: 2000,
        });
        daysModal();
    };
    var daysModal = function () {
        var selectedDaySlots = daySlots["day".concat(weekDay)];
        var slotsSelected = [];
        selectedDaySlots.filter(function (each) {
            if (each.color === 'slot-btn status-selected') {
                slotsSelected.push(each);
            }
            return arr;
        });
        if (slotsSelected.length > 0) {
            setWeekDaysModal(!weekDaysModal);
        }
        else {
            react_hot_toast_1.default.error('Please Select atleast one slot', {
                duration: 5000,
            });
        }
    };
    var editDataSlots = function () {
        var _a;
        if (weekDays.length > 0) {
            var day_3;
            (_a = editData === null || editData === void 0 ? void 0 : editData.schedule) === null || _a === void 0 ? void 0 : _a.map(function (schedule) {
                var _a;
                day_3 = (0, CC_helper_1.getDayFromId)(schedule.day, weekDays);
                var editFromTime = schedule.schedule_time_slot.split('-')[0];
                setSelectedList(function (preSlot) {
                    if (preSlot.length > 0) {
                        preSlot.map(function (val) {
                            if (!val.parentslot.includes(schedule.schedule_time_slot.split('-')[0])) {
                                return __spreadArray(__spreadArray([], preSlot, true), [
                                    {
                                        parentslot: schedule.schedule_time_slot.split('-')[0],
                                        childslot: schedule.schedule_time_slot.split('-')[1],
                                        weekday: schedule.day,
                                    },
                                ], false);
                            }
                        });
                    }
                    else {
                        setSelectedList(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                            {
                                parentslot: schedule.schedule_time_slot.split('-')[0],
                                childslot: schedule.schedule_time_slot.split('-')[1],
                                weekday: schedule.day,
                            },
                        ], false); });
                    }
                    return preSlot;
                });
                if (day_3) {
                    (_a = daySlots["day".concat(day_3.id)]) === null || _a === void 0 ? void 0 : _a.forEach(function (slot, index) {
                        var slotFromTime = slot.from_time.split('-')[0];
                        if (editFromTime.toString().trim() == slotFromTime.toString().trim()) {
                            slot['color'] = 'slot-btn status-selected';
                            slot['onehourslot'] = schedule.schedule_time_slot;
                            daySlots["day".concat(day_3.id)][index + 1]['color'] =
                                'slot-btn status-selected';
                            daySlots["day".concat(day_3.id)][index + 1]['onehourslot'] =
                                schedule.schedule_time_slot;
                        }
                    });
                    setDaySlots(daySlots);
                }
            });
        }
    };
    var areDatesWithinFiveDays = function (date1, date2) {
        var differenceInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
        var differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        return differenceInDays <= 4;
    };
    var callBackOnDateRangeSelect = function (dateRangeArray, setFieldValue) {
        if (dateRangeArray) {
            setEditDateRange(null);
            var startDate = new Date(dateRangeArray.start);
            var endDate = new Date(dateRangeArray.end);
            var validDate = areDatesWithinFiveDays(startDate, endDate);
            if (validDate) {
                setFieldValue('us_from_date', moment(dateRangeArray.start).format('YYYY-MM-DD'));
                setFieldValue('us_to_date', moment(dateRangeArray.end).format('YYYY-MM-DD'));
            }
            else {
                react_hot_toast_1.default.error('Please select a date range of up to 5 days', { duration: 4000 });
            }
        }
        else {
            setFieldValue('us_from_date', '');
            setFieldValue('us_to_date', '');
        }
    };
    React.useEffect(function () {
        (0, lab_master_api_calls_1.getAllWeekDaysMasterSlots)()
            .then(function (res) {
            setDefaultSlots(lodash_1.default.cloneDeep(res.data));
            setgetTimeSlotList(lodash_1.default.cloneDeep(res.data));
        })
            .catch(function (e) { return console.log(e); });
    }, []);
    React.useEffect(function () {
        if (editData &&
            !editData.base_location_address &&
            editData.latitude &&
            editData.longitude) {
            setGetLoactionFromLatLong(true);
        }
        else if (addressInfo &&
            !addressInfo.base_location_address &&
            addressInfo.latitude &&
            addressInfo.longitude) {
            setGetLoactionFromLatLong(true);
        }
    }, [editData, addressInfo]);
    React.useEffect(function () {
        if (!isMounted) {
            getWeekDays();
            isMounted = true;
        }
    }, [editLocation, gettimeSlotsList]);
    React.useEffect(function () {
        editDataSlots();
    }, [editData]);
    var returnweekdays = function (setFieldValue, touched, errors) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true },
                React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true },
                    React.createElement("h2", { className: "selected-felabel custom-form-label p-24" }, "Select Lab Time Window Slot Hours"),
                    errors['day'] && touched['day'] ? (React.createElement("div", { className: "error" },
                        errors['day'],
                        " ")) : null)),
            React.createElement(Grid_1.default, { item: true, xs: 12 },
                React.createElement("div", { className: "days-list-wrapper tabs-list-wrapper" },
                    React.createElement(Stack_1.default, { className: "tabs-wrapper", direction: "row", alignItems: "center", justifyContent: "space-between", width: "100%" },
                        React.createElement(material_1.Tabs, { value: activeTab, onChange: function (event, newValue) { return setSlotid(newValue); }, className: "tab-links" }, weekDays === null || weekDays === void 0 ? void 0 : weekDays.map(function (weekDaysDetails) {
                            return (React.createElement(material_1.Tab, { key: weekDaysDetails.id, className: activeTab == weekDaysDetails.id ? 'tab-item active-tab' : 'tab-item', label: weekDaysDetails.day, onClick: function () {
                                    setWeekDay(weekDaysDetails.id);
                                    setWeekayid(weekDaysDetails.id);
                                    weekDayColorHandler(weekDaysDetails);
                                    var postOnchangeSlotDetails = weekDays === null || weekDays === void 0 ? void 0 : weekDays.filter(function (weekDaysObjects) {
                                        if (weekDaysObjects['className'] === 'tab-item active-tab') {
                                            return weekDaysObjects.id;
                                        }
                                    });
                                    setFieldValue('day', postOnchangeSlotDetails);
                                } }));
                        })),
                        React.createElement(modal_component_1.default, { buttonName: "Apply For Multiple Days", buttonColor: "yellow-outline", onClickHandler: daysModal, open: weekDaysModal, modalSize: "long-modal" },
                            React.createElement(RoastersForMultipleDays_1.default, { weekDaysSubmitHandler: function (selectedDays) {
                                    return weekDaysSubmitHandler(selectedDays);
                                }, weekDays: weekDays, cancelhandler: modalCloseHandler })))))));
    };
    var returnslots = function (errors, touched, setFieldValue) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { item: true, xs: 14, lg: 12 },
                React.createElement("div", { className: "p-24" },
                    daySlots && (React.createElement(Lab_Master_slots_1.default, { name: "selectedSlots", onChange: function (val) {
                            setFieldValue('selectedSlots', val);
                        }, selectedSlots: selectedSlots, setDaySlots: setDaySlots, setSelectedSlots: setSelectedSlots, apislotdetails: apislotdata, selectedDay: selectedDay, isDayselected: isDayselected, slotid: slotid, selectedList: selectedList, setSelectedList: setSelectedList, daySlots: daySlots, dayset: '', addedData: addedData, setAddedData: setAddedData, editData: editData === null || editData === void 0 ? void 0 : editData.schedule, weekDay: weekDay && weekDay, weekdayid: weekdayid && weekdayid })),
                    isSlotsEmpty ? (React.createElement("div", { className: "error" },
                        'Please Select Time Slots',
                        " ")) : null,
                    React.createElement("div", { className: "cc-slot-legend" },
                        React.createElement("div", { className: "legend-item bg-existing" }, "Available"),
                        React.createElement("div", { className: "legend-item bg-selected" }, "Selected"))))));
    };
    return (React.createElement("div", { className: "block-slot-wrapper" },
        React.createElement("div", { className: "scrollable-content" },
            React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, enableReinitialize: true, validationSchema: schema_1.schemas.Lab_RoasterManagement_Schema, onSubmit: formSubmitHandler }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched, setValues = _a.setValues;
                return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                        React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                            React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTOListPage }),
                            React.createElement("h2", null, id ? 'Edit Time Window' : 'Modify Time Window')),
                        React.createElement("div", { className: "page-control-wrapper" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateTOListPage }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))),
                    React.createElement("div", { className: "add-slotform form-filed-wrapper" },
                        React.createElement("button", { type: "submit", ref: hiddenInnerSubmitFormRef, className: "display_hide" }, "Submit"),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement("div", { className: "clear-dropdown" },
                                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "labcode", value: editData && (editData === null || editData === void 0 ? void 0 : editData.lab_code)
                                            ? (0, CC_helper_1.getSelectedLabDetils)(labList, editData.lab_code)
                                            : labcode, dropDownList: convertedLabList && convertedLabList, onChange: function (val) {
                                            if (!val) {
                                                clearsetfieldvalues(setValues);
                                            }
                                            clearSlots();
                                            setActiveTab(1);
                                            setSelecteFeIds(val);
                                            setlabcode(val);
                                            setFieldValue('state', val.center_state);
                                            setFieldValue('city', val.center_city);
                                            setFieldValue('warehouse_code', '');
                                            setFieldValue('base_location_address', '');
                                            setFieldValue('baseLocation', '');
                                            setFieldValue('geofence_enabled', 0);
                                            setFieldValue('geofence_radius', 0);
                                            setFieldValue('labcode', val);
                                            setFieldTouched('labcode', true);
                                            callbackOnselectedLabList(val, setFieldValue, values, setValues);
                                        }, placeholder: "Lab Code ", required: true, isDisabled: id ? true : false }),
                                    labcode === null ? (React.createElement("div", { className: "error" },
                                        errorMsg,
                                        " ")) : errors['labcode'] && touched['labcode'] ? (React.createElement("div", { className: "error" },
                                        errors['labcode'],
                                        " ")) : null)),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "state", placeholder: 'State', label: "State", isDisabled: true, value: values.state, key: values.state })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "city", placeholder: 'City', label: "City", isDisabled: true, value: values.city, key: values.city })),
                            React.createElement(Grid_1.default, { item: true, xs: 12, lg: 12 },
                                React.createElement("div", { className: "panner-wrapper" },
                                    React.createElement("div", { className: "planner-accordian-wrapper create-accordian-wrapper" },
                                        React.createElement(material_1.Accordion, { disableGutters: true, elevation: 0 },
                                            React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null), "aria-controls": "panel1a-content", id: "panel1a-header" },
                                                React.createElement(material_1.Typography, { className: "accordian-heading" }, "Static Route Configuration")),
                                            React.createElement(material_1.AccordionDetails, { className: "custom-accordion-details" },
                                                React.createElement("div", { className: "filter-options city-state-wrapper" },
                                                    React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "staticspeed", placeholder: 'Speed Km/hr', label: "Speed Km/hr", isDisabled: true, value: selectedroute && selectedroute.staticspeed
                                                                    ? selectedroute.staticspeed
                                                                    : values.staticspeed, key: selectedroute && selectedroute.staticspeed
                                                                    ? selectedroute.staticspeed
                                                                    : values.staticspeed })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "staticradius", placeholder: 'FE Radius Km', label: "FE Radius Km", isDisabled: true, value: selectedroute && selectedroute.staticradius
                                                                    ? selectedroute.staticradius
                                                                    : values.staticradius, key: selectedroute && selectedroute.staticradius
                                                                    ? selectedroute.staticradius
                                                                    : values.staticradius })))),
                                                React.createElement("h3", null, "Buffer Times"),
                                                React.createElement("div", { className: "filter-options city-state-wrapper" },
                                                    React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "static_cc", placeholder: 'Time for CC(min)', label: "Time for CC(min)", isDisabled: true, value: selectedroute && selectedroute.static_cc
                                                                    ? selectedroute.static_cc
                                                                    : values.static_cc, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.static_cc })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "static_fpsc", placeholder: 'Time for FPSC(min)', label: "Time for FPSC(min)", isDisabled: true, value: selectedroute && selectedroute.static_fpsc
                                                                    ? selectedroute.static_fpsc
                                                                    : values.static_fpsc, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.static_fpsc })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "static_pup", placeholder: 'Time for PUP(min)', label: "Time for PUP(min)", isDisabled: true, value: selectedroute && selectedroute.static_pup
                                                                    ? selectedroute.static_pup
                                                                    : values.static_pup, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.static_pup })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "static_psc", placeholder: 'Time for PSC(min)', label: "Time for PSC(min)", isDisabled: true, value: selectedroute && selectedroute.static_psc
                                                                    ? selectedroute.static_psc
                                                                    : values.static_psc, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.static_psc })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "static_hub", placeholder: 'Time for HUB(min)', label: "Time for HUB(min)", isDisabled: true, value: selectedroute && selectedroute.static_hub
                                                                    ? selectedroute.static_hub
                                                                    : values.static_hub, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.static_hub })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "static_lab", placeholder: 'Time for LAB(min)', label: "Time for LAB(min)", isDisabled: true, value: selectedroute && selectedroute.static_lab
                                                                    ? selectedroute.static_lab
                                                                    : values.static_lab, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.static_lab }))))))))),
                            React.createElement(Grid_1.default, { item: true, xs: 12, lg: 12 },
                                React.createElement("div", { className: "panner-wrapper" },
                                    React.createElement("div", { className: "planner-accordian-wrapper create-accordian-wrapper" },
                                        React.createElement(material_1.Accordion, { disableGutters: true, elevation: 0 },
                                            React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null), "aria-controls": "panel1a-content", id: "panel1a-header" },
                                                React.createElement(material_1.Typography, { className: "accordian-heading" }, "Dynamic Route Configuration")),
                                            React.createElement(material_1.AccordionDetails, { className: "custom-accordion-details" },
                                                React.createElement("div", { className: "filter-options city-state-wrapper" },
                                                    React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "dynamicspeed", placeholder: 'Speed Km/hr', label: "Speed Km/hr", isDisabled: true, value: selectedroute && selectedroute.dynamicspeed
                                                                    ? selectedroute.dynamicspeed
                                                                    : values.dynamic_speed, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.dynamicspeed })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "dynamicradius", placeholder: 'FE Radius Km', label: "FE Radius Km", isDisabled: true, value: selectedroute && selectedroute.dynamicradius
                                                                    ? selectedroute.dynamicradius
                                                                    : values.dynamicradius, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.dynamicradius })))),
                                                React.createElement("h3", null, "Buffer Times"),
                                                React.createElement("div", { className: "filter-options city-state-wrapper" },
                                                    React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "dynamic_cc", placeholder: 'Time for CC(min)', label: "Time for CC(min)", isDisabled: true, value: selectedroute && selectedroute.dynamic_cc
                                                                    ? selectedroute.dynamic_cc
                                                                    : values.dynamic_cc, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.dynamic_cc })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "dynamic_fpsc", placeholder: 'Time for FPSC(min)', label: "Time for FPSC(min)", isDisabled: true, value: selectedroute && selectedroute.dynamic_fpsc
                                                                    ? selectedroute.dynamic_fpsc
                                                                    : values.dynamic_fpsc, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.dynamic_fpsc })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "dynamic_pup", placeholder: 'Time for PUP(min)', label: "Time for PUP(min)", isDisabled: true, value: selectedroute && selectedroute.dynamic_pup
                                                                    ? selectedroute.dynamic_pup
                                                                    : values.dynamic_pup, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.dynamic_pup })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "dynamic_psc", placeholder: 'Time for PSC(min)', label: "Time for PSC(min)", isDisabled: true, value: selectedroute && selectedroute.dynamic_psc
                                                                    ? selectedroute.dynamic_psc
                                                                    : values.dynamic_psc, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.dynamic_psc })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "dynamic_hub", placeholder: 'Time for HUB(min)', label: "Time for HUB(min)", isDisabled: true, value: selectedroute && selectedroute.dynamic_hub
                                                                    ? selectedroute.dynamic_hub
                                                                    : values.dynamic_hub, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.dynamic_hub })),
                                                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                                            React.createElement(text_filed_component_1.default, { name: "dynamic_lab", placeholder: 'Time for LAB(min)', label: "Time for LAB(min)", isDisabled: true, value: selectedroute && selectedroute.dynamic_lab
                                                                    ? selectedroute.dynamic_lab
                                                                    : values.dynamic_lab, key: selectedroute === null || selectedroute === void 0 ? void 0 : selectedroute.dynamic_lab }))))))))),
                            React.createElement(Grid_1.default, { item: true, xs: 12, lg: 12 },
                                React.createElement("div", { className: "panner-wrapper" },
                                    React.createElement("div", { className: "planner-accordian-wrapper create-accordian-wrapper" },
                                        React.createElement(material_1.Accordion, { disableGutters: true, elevation: 0 },
                                            React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null), "aria-controls": "panel1a-content", id: "panel1a-header" },
                                                React.createElement(material_1.Typography, { className: "accordian-heading" }, "UltrasStatic Route Configuration")),
                                            React.createElement(material_1.AccordionDetails, { className: "custom-accordion-details" },
                                                React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                                    React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "geofence-switch-btn switch-button-wrapper" },
                                                        React.createElement(switch_button_1.default, { swicthLabel: "Ultrastatic Routing Enable", defaultChecked: values.ultrastatic_routing_enabled, value: values.ultrastatic_routing_enabled, key: values.ultrastatic_routing_enabled, onChangeHandler: function (event) {
                                                                setFieldValue('ultrastatic_routing_enabled', event.target.checked);
                                                            } }))),
                                                React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3, className: 'holiday-schedule-wrapper' },
                                                    React.createElement(Grid_1.default, { item: true, xs: 12 },
                                                        React.createElement("h3", null, "Holiday Schedule")),
                                                    React.createElement(Grid_1.default, { item: true, xs: 2.5, lg: 2.5 },
                                                        React.createElement("div", { className: "cursor-pointer date-range-right" },
                                                            React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: function (event) { callBackOnDateRangeSelect(event, setFieldValue); }, minimumDate: new Date(), editValue: id ? editDateRange ? "".concat(editDateRange === null || editDateRange === void 0 ? void 0 : editDateRange.fromDate, "-").concat(editDateRange === null || editDateRange === void 0 ? void 0 : editDateRange.toDate) : null : null, disabled: id ? values.ultrastatic_routing_enabled ? editDateRange ? true : false : true : values.ultrastatic_routing_enabled ? false : true }))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 2.5, lg: 2.5 },
                                                        React.createElement(drop_down_component_1.default, { disableClearable: true, name: "type", value: values === null || values === void 0 ? void 0 : values.type, dropDownList: (filtered_schedules === null || filtered_schedules === void 0 ? void 0 : filtered_schedules.length) > 0 ? filtered_schedules : [], onChange: function (val) {
                                                                setFieldValue('type', val);
                                                                setFieldTouched('sceduleType', true);
                                                            }, placeholder: "Schedule Type", isDisabled: id ? values.ultrastatic_routing_enabled ? editDateRange ? true : false : true : values.ultrastatic_routing_enabled ? false : true }),
                                                        errors['type'] && touched['type'] ? (React.createElement("div", { className: "error" },
                                                            errors['type'],
                                                            " ")) : null))))))),
                            React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                React.createElement(Grid_1.default, { item: true, xs: 12 },
                                    React.createElement("h2", { className: "label-heading heading" }, "Lab Base Location")),
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement(text_filed_with_icon_1.default, { name: "base_location_address", placeholder: "Base Location Address", label: "Base Location Address", onClick: function () { return toggleMapView(!showMaps); }, value: values.base_location_address, key: editLocation }),
                                    errors['base_location_address'] &&
                                        touched['base_location_address'] ? (React.createElement("div", { className: "error" },
                                        errors['base_location_address'],
                                        " ")) : null),
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement(text_filed_component_1.default, { name: "baseLocation", placeholder: "FE Base Location", label: "Latitude & Longitude", onChange: function (val) {
                                            setFieldValue('baseLocation', val);
                                            setFieldTouched('baseLocation', true);
                                        }, value: values.baseLocation, isDisabled: true })),
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: "geofence-switch-btn" },
                                    React.createElement(switch_button_1.default, { swicthLabel: "Geofence Enable", defaultChecked: values.geofence_enabled, value: values.geofence_enabled, key: values.geofence_enabled, onChangeHandler: function (event) {
                                            setFieldValue('geofence_enabled', event.target.checked);
                                            setFieldTouched('geofence_enabled', true);
                                        } })),
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 2 },
                                    React.createElement(text_filed_component_1.default, { isDisabled: values.geofence_enabled ? false : true, name: "geofence_radius", placeholder: 'Geofence Radius', label: "Geofence Radius", onChange: function (val) {
                                            setFieldValue('geofence_radius', Number(val));
                                            setFieldTouched('geofence_radius', true);
                                        }, value: values.geofence_radius, onKeyPress: function (e) {
                                            if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                                e.target.value.length <= 2) {
                                            }
                                            else {
                                                e.preventDefault();
                                            }
                                        } }),
                                    errors['geofence_radius'] && touched['geofence_radius'] ? (React.createElement("div", { className: "error" },
                                        errors['geofence_radius'],
                                        " ")) : null),
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: "geofence-switch-btn" },
                                    React.createElement(switch_button_1.default, { swicthLabel: "ETRF Enable", defaultChecked: values.etrf_scan_enabled, value: values.etrf_scan_enabled === 0 ? false : true, key: values.etrf_scan_enabled, onChangeHandler: function (event) {
                                            setFieldValue('etrf_scan_enabled', event.target.checked ? 1 : 0);
                                            setFieldTouched('etrf_scan_enabled', true);
                                        } }))))),
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                        selecteFeIds && (selecteFeIds === null || selecteFeIds === void 0 ? void 0 : selecteFeIds.length) !== 0 && (React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true },
                            React.createElement(Grid_1.default, { xs: 6, lg: 6, item: true },
                                React.createElement(selected_CC_1.default, { selecteFeIds: labcode ? selecteFeIds : '', setSelecteFeIds: setSelecteFeIds, name: "LAB", setFieldValue: setFieldValue, clearSlots: clearSlots, setlabcode: setlabcode })))),
                        returnweekdays(setFieldValue, touched, errors),
                        returnslots(errors, touched, setFieldValue),
                        showMaps && (React.createElement(React.Fragment, null,
                            React.createElement(map_1.default, { bast_lat_lng: values.baseLocation, baselocation_address: values.base_location_address, submitSelectedPlace: function (latAndLng, fe_current_location) {
                                    if (latAndLng) {
                                        setFieldValue('baseLocation', "".concat(latAndLng));
                                        setFieldTouched('baseLocation', true);
                                        setFieldValue('base_location_address', "".concat(fe_current_location));
                                        setFieldTouched('base_location_address', true);
                                        toggleMapView(false);
                                    }
                                }, close: function () { return toggleMapView(false); } })))),
                    React.createElement(ModalComponentWithoutButton_1.default, { open: isRoutechanged },
                        React.createElement("div", { className: "modal-header " },
                            React.createElement("h2", { className: "heading" }, "Confirmation"),
                            React.createElement("p", { className: "sub-heading" }, "Are you sure you want to change the route type?")),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: "NO", color: "yellow-outline", onClickHandler: function () {
                                    setRouteType(editData ? editData.routing_assignment : 'NON_ROUTE');
                                    setIsRoutechanged(false);
                                } }),
                            React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", onClickHandler: function () {
                                    setIsRoutechanged(false);
                                    setIsRouteChanged(!isRouteChanged);
                                } }))),
                    React.createElement(ModalComponentWithoutButton_1.default, { open: isSlotsEmpty },
                        React.createElement("div", { className: "modal-header " },
                            React.createElement("h2", { className: "heading" }, "Confirmation"),
                            React.createElement("p", { className: "sub-heading" }, "Are you sure you want to submit without slots?")),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: "NO", color: "yellow-outline", onClickHandler: function () {
                                    setIsSlotsEmpty(false);
                                } }),
                            React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", onClickHandler: function () {
                                    formSubmitHandler(values);
                                } }))),
                    getLoactionFromLatLong && (React.createElement(reverseGeoCode_1.default, { setFieldValue: setFieldValue, setEditLocation: setEditLocation, lat: editLatitude ? editLatitude : addressInfo.latitude, lng: editLongitude ? editLongitude : addressInfo.longitude }))));
            }))));
};
exports.default = CreateLabMaster;
