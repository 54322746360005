"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var Delete_1 = require("@mui/icons-material/Delete");
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var button_component_1 = require("../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../constants/appConstants");
require("../ShimentRoute/shipmentRoute.scss");
var Switch_1 = require("@mui/material/Switch");
require("../ultra_static/ultra_static.scss");
var material_1 = require("@mui/material");
var react_hot_toast_1 = require("react-hot-toast");
var Checked_Icon__Button_1 = require("../../../common/buttons/Checked_Icon _Button");
var Cross_Icon_Button_1 = require("../../../common/buttons/Cross_Icon_Button");
var Edit_1 = require("@mui/icons-material/Edit");
var lodash_1 = require("../../../utils/lodash");
var react_redux_1 = require("react-redux");
var fe_services_1 = require("../../field_exicutive/fe_services");
var api_service_1 = require("../../../api/api_service");
var CC_api_calls_1 = require("../CC/CC_api_calls");
var array_helpers_1 = require("../../../utils/array_helpers");
var ultra_static_api_1 = require("./ultra_static_api");
var UltraStaticVisit = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var values = _a.values, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, remove = _a.remove, index = _a.index, rowId = _a.rowId, checkIsEditFalse = _a.checkIsEditFalse, routeType = _a.routeType, setVisitsList = _a.setVisitsList;
    var _h = React.useState(''), filteredCCList = _h[0], setFilteredCCList = _h[1];
    var _j = React.useState(''), storedTimeSlot = _j[0], setStoredTimeSlot = _j[1];
    var _k = React.useState(), timeSlotss = _k[0], setTimeSlots = _k[1];
    var _l = React.useState(''), storedMins = _l[0], setStoredMins = _l[1];
    var _m = React.useState(''), storedStatus = _m[0], setStoredStatus = _m[1];
    var _o = React.useState(), deleteIndex = _o[0], setDeleteIndex = _o[1];
    var _p = React.useState(false), _openPop = _p[0], setOpenPop = _p[1];
    var ultrastatic_route_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_route_type; });
    var updated_route_types = ultrastatic_route_types.map(function (route) { return (__assign(__assign({}, route), { label: route.label.replace(/_/g, ' ') })); });
    var ultrastatic_route_status_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_route_status_type; });
    var formSubmitHandler = function (values, index, setFieldValue, remove) {
        var routes = {};
        var submittedSlot = remove ? values.slots[deleteIndex] : values.slots[index];
        var valid;
        if (submittedSlot.isNewRoute) {
            valid = validateValues(submittedSlot);
        }
        else {
            valid = false;
        }
        if (!valid && submittedSlot.isError === '') {
            if (!remove) {
                setFieldValue("slots[".concat(index, "].isSubmitted"), true);
            }
            var slot = {};
            var _a = submittedSlot.timeSlots.value.split(' - '), fromSlot = _a[0], toSlot = _a[1];
            if (submittedSlot.isDelete) {
                var routeStatus = ultrastatic_route_status_types.filter(function (route) { return route.label === 'DELETED'; });
                slot['status'] = routeStatus[0].value;
            }
            else {
                var routeStatus = ultrastatic_route_status_types.filter(function (route) { return route.label === submittedSlot.status; });
                slot['status'] = routeStatus[0].value;
            }
            slot['warehouse_code'] = submittedSlot === null || submittedSlot === void 0 ? void 0 : submittedSlot.ccCode.value;
            slot['lab_code'] = values.labCode.value;
            slot['visit_type'] = submittedSlot.event.id;
            slot['slot_from'] = fromSlot.substring(0, 2);
            slot['slot_to'] = toSlot.substring(0, 2);
            slot['eta'] = "".concat(submittedSlot === null || submittedSlot === void 0 ? void 0 : submittedSlot.hours, ":").concat(submittedSlot === null || submittedSlot === void 0 ? void 0 : submittedSlot.minutes.value);
            var createRoutes = [];
            var updatedRoutes = [];
            if (submittedSlot.isNewRoute) {
                createRoutes.push(slot);
                routes['create_routes'] = createRoutes;
            }
            else {
                slot['id'] = submittedSlot.routeId;
                updatedRoutes.push(slot);
                routes['update_routes'] = updatedRoutes;
            }
            var payload = {
                routes: routes
            };
            if (values.feCode) {
                payload['fe_id'] = rowId ? parseInt(rowId) : values.feMobile.id;
            }
            if (values.scheduleType) {
                payload['schedule_type'] = values.scheduleType.id;
            }
            (0, ultra_static_api_1.submitUltraStatic)(payload)
                .then(function (response) {
                if (response.statusCode === 200) {
                    if (remove) {
                        remove(deleteIndex);
                    }
                    else {
                        var updatedVisits = __spreadArray([], values.slots, true).map(function (slot, sIndex) {
                            slot['time'] = parseInt("".concat(slot.hours).concat(slot.minutes.label));
                            if (sIndex === index) {
                                return __assign(__assign({}, slot), { isNewRoute: false, routeId: response.data[0].id, isEdit: false });
                            }
                            return __assign({}, slot);
                        });
                        var sortedVisits = lodash_1.default.sortBy(__spreadArray([], updatedVisits, true), 'time');
                        setFieldValue('slots', sortedVisits);
                        setVisitsList(sortedVisits);
                        setStoredTimeSlot('');
                        setStoredMins('');
                        setStoredStatus('');
                    }
                }
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err.message);
            });
        }
        else {
            if (submittedSlot.isError.length > 0) {
                react_hot_toast_1.default.error(submittedSlot.isError, { duration: 4000 });
            }
            setFieldValue("slots[".concat(index, "].isSubmitted"), true);
        }
    };
    var getSlotsList = function (val, values, code) {
        var queryParam = "";
        if ((val.label.toLowerCase().includes('lab') || val.label.toLowerCase().includes('cash'))) {
            queryParam = queryParam + "center_type=LAB";
        }
        if (val.label.toLowerCase().includes('hub')) {
            queryParam = queryParam + "center_type=HUB";
        }
        if (val.label === 'PICKUP') {
            queryParam = queryParam + "center_type=CENTER";
        }
        if (code) {
            queryParam = queryParam + "&warehouse_code=".concat(code);
        }
        if (values.feMobile) {
            queryParam = queryParam + "&fe_id=".concat(values.feMobile.id);
        }
        if (values.scheduleType) {
            queryParam = queryParam + "&schedule_type=".concat(values.scheduleType.value);
        }
        (0, ultra_static_api_1.getUltraStaticTimeSlots)(queryParam)
            .then(function (response) {
            var resultantArray = [];
            if (response.data.slots.length > 0) {
                var responseTimeSlots = response.data.slots;
                responseTimeSlots.forEach(function (slot) {
                    appConstants_1.TimeSlots.filter(function (timeSlot) {
                        if (slot.substring(0, 2) === timeSlot.value.substring(0, 2)) {
                            resultantArray.push(timeSlot);
                        }
                    });
                });
            }
            setTimeSlots(resultantArray);
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var onVisitChange = function (val, values, index, setFieldValue, setFieldTouched) {
        setFieldValue("slots[".concat(index, "].codePlaceHolder"), 'CC Code');
        setFieldValue("slots[".concat(index, "].namePlaceHolder"), 'CC Name');
        setFieldValue("slots[".concat(index, "].ccCode"), '');
        setFieldValue("slots[".concat(index, "].ccName"), '');
        setFieldValue("slots[".concat(index, "].timeSlots"), '');
        setFieldValue("slots[".concat(index, "].hours"), '');
        setFieldValue("slots[".concat(index, "].minutes"), '');
        if (values.slots.length === 1 && (val.label === 'HANDOVER HUB' || val.label === 'HANDOVER LAB')) {
            react_hot_toast_1.default.error('The first visit cannot be a HANDOVER visit', { duration: 3000 });
        }
        else {
            if (values.slots[index].isSubmitted) {
                setFieldValue("slots[".concat(index, "].isSubmitted"), false);
            }
            setFieldValue("slots[".concat(index, "].event"), val);
            setFieldTouched('event', true);
            if (val.label === 'HANDOVER HUB') {
                setFieldValue("slots[".concat(index, "].codePlaceHolder"), 'HUB Code');
                setFieldValue("slots[".concat(index, "].namePlaceHolder"), 'HUB Name');
                (0, fe_services_1.fetchFEProfileList)(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, { user_id: values.feMobile.id }, process.env.VENDOR_BASE_URL)
                    .then(function (res) {
                    if (!res.hub_code) {
                        setFieldValue("slots[".concat(index, "].ccCode"), '');
                        setFieldValue("slots[".concat(index, "].ccName"), '');
                        react_hot_toast_1.default.error("Selected FE didn't assigned to any hub", { duration: 3000 });
                    }
                    else {
                        setFieldValue("slots[".concat(index, "].ccCode"), { label: res.hub_code, value: res.hub_code });
                        setFieldValue("slots[".concat(index, "].ccName"), res.hub_name);
                        getSlotsList(val, values, res.hub_code);
                    }
                })
                    .catch(function (e) { return console.log(e); });
            }
            if (val.label === 'HANDOVER LAB' || val.label === 'HANDOVER CASH') {
                setFieldValue("slots[".concat(index, "].codePlaceHolder"), 'LAB Code');
                setFieldValue("slots[".concat(index, "].namePlaceHolder"), 'LAB Name');
                setFieldValue("slots[".concat(index, "].ccCode"), { label: values.labCode.value, value: values.labCode.value });
                setFieldValue("slots[".concat(index, "].ccName"), values.labName);
                getSlotsList(val, values, values.labCode.value);
            }
            if (val.label === 'PICKUP') {
                var ccPayload = {
                    lab_code: values.labCode.value,
                    route_type: [routeType[0].label],
                    coordinates_available: true
                };
                (0, CC_api_calls_1.fetchCCTimeSlotList)(ccPayload)
                    .then(function (response) {
                    setFilteredCCList((0, array_helpers_1.convertUltraStaticCCList)(response.result));
                })
                    .catch(function (err) {
                    console.log(err);
                });
            }
        }
    };
    var validateValues = function (obj) {
        if (obj.isError !== '') {
            return true;
        }
        return Object.entries(obj).some(function (_a) {
            var key = _a[0], value = _a[1];
            return (key !== 'routeId') && (key !== 'isError') && (value === undefined || value === null || value === '');
        });
    };
    var validateSlots = function (slots, targetHour, targetMinute) {
        if (targetHour != undefined && targetMinute != undefined) {
            var isNotValid = slots.some(function (slot) {
                return slot &&
                    slot.hours === targetHour &&
                    slot.minutes.label === targetMinute;
            });
            return isNotValid;
        }
        return false;
    };
    var handleOpenPop = function () {
        setOpenPop(true);
    };
    var crossClickHandler = function (setFieldValue, values, index) {
        setFieldValue("slots[".concat(index, "].isEdit"), !values.slots[index].isEdit);
        setStoredTimeSlot('');
        setStoredMins('');
        setStoredStatus('');
    };
    var checkedClickHandler = function (setFieldValue, values, index) {
        var updatedSlot = __assign(__assign({}, values.slots[index]), { isEdit: !values.slots[index].isEdit, timeSlots: (storedTimeSlot === null || storedTimeSlot === void 0 ? void 0 : storedTimeSlot.value) ? storedTimeSlot.value : values === null || values === void 0 ? void 0 : values.slots[index].timeSlots, minutes: (storedMins === null || storedMins === void 0 ? void 0 : storedMins.value) ? storedMins.value : values === null || values === void 0 ? void 0 : values.slots[index].minutes, hours: (storedTimeSlot === null || storedTimeSlot === void 0 ? void 0 : storedTimeSlot.value) ? storedTimeSlot.value.value.substring(0, 2) : values === null || values === void 0 ? void 0 : values.slots[index].hours, status: (storedStatus === null || storedStatus === void 0 ? void 0 : storedStatus.value) ? storedStatus.value : values === null || values === void 0 ? void 0 : values.slots[index].status });
        var updatedSlots = __spreadArray([], values.slots, true);
        updatedSlots[index] = updatedSlot;
        formSubmitHandler(__assign(__assign({}, values), { slots: updatedSlots }), index, setFieldValue);
    };
    var handleClosePop = function (setFieldValue, index) {
        setFieldValue("slots[".concat(index, "].isDelete"), false);
    };
    var deleteRoute = function (setFieldValue, remove, values, index) {
        handleClosePop(setFieldValue, index);
        formSubmitHandler(values, index, setFieldValue, remove);
    };
    return (React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, spacing: 3, alignItems: "center" },
        React.createElement(Grid_1.default, { item: true, xs: 1.9, lg: 1.9 },
            React.createElement(drop_down_component_1.default, { disableClearable: true, name: "event", value: values === null || values === void 0 ? void 0 : values.slots[index].event, isDisabled: ((_b = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _b === void 0 ? void 0 : _b.isNewRoute) ? false : true, dropDownList: (updated_route_types === null || updated_route_types === void 0 ? void 0 : updated_route_types.length) > 0 ? updated_route_types : [], placeholder: "Event", onChange: function (val) {
                    if (val != null) {
                        onVisitChange(val, values, index, setFieldValue, setFieldTouched);
                    }
                    else {
                        setFieldValue("slots[".concat(index, "].event"), '');
                        setFieldValue("slots[".concat(index, "].ccCode"), '');
                        setFieldValue("slots[".concat(index, "].ccName"), '');
                        setFieldValue("slots[".concat(index, "].timeSlots"), '');
                        setFieldValue("slots[".concat(index, "].hours"), '');
                        setFieldValue("slots[".concat(index, "].minutes"), '');
                    }
                }, required: true }),
            values.slots[index].isSubmitted && values.slots[index].event === '' ? (React.createElement("div", { className: "error" }, " Please select an event ")) : null),
        React.createElement(Grid_1.default, { item: true, xs: (values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) ? 2.2 : 2, lg: (values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) ? 2.2 : 2 },
            React.createElement(drop_down_component_1.default, { disableClearable: true, name: "ccCode", value: values === null || values === void 0 ? void 0 : values.slots[index].ccCode, isDisabled: ((_c = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _c === void 0 ? void 0 : _c.isNewRoute) ? (values === null || values === void 0 ? void 0 : values.slots[index].event.label) != 'PICKUP' ? true : false : true, dropDownList: filteredCCList.length > 0 ? filteredCCList : [], placeholder: values === null || values === void 0 ? void 0 : values.slots[index].codePlaceHolder, label: values === null || values === void 0 ? void 0 : values.slots[index].codePlaceHolder, required: true, onChange: function (val) {
                    setFieldValue("slots[".concat(index, "].ccCode"), { id: 1, label: val.value, value: val.value });
                    setFieldValue("slots[".concat(index, "].ccName"), val.center_name);
                    getSlotsList(values === null || values === void 0 ? void 0 : values.slots[index].event, values, val.value);
                    setFieldTouched('ccCode', true);
                } }),
            values.slots[index].isSubmitted && values.slots[index].event != '' && values.slots[index].ccCode === '' ? (React.createElement("div", { className: "error" }, " Please select a CC ")) : null),
        React.createElement(Grid_1.default, { item: true, xs: !(values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) ? 2.5 : 2.8, lg: !(values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) ? (values === null || values === void 0 ? void 0 : values.slots[index].isEdit) ? 2.6 : 2.8 : 2.8 },
            React.createElement(text_filed_component_1.default, { name: "ccName", placeholder: values === null || values === void 0 ? void 0 : values.slots[index].namePlaceHolder, label: values === null || values === void 0 ? void 0 : values.slots[index].namePlaceHolder, isDisabled: true, value: (_d = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _d === void 0 ? void 0 : _d.ccName, key: values.ccName })),
        React.createElement(Grid_1.default, { item: true, xs: !(values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) ? 1.6 : 1.8, lg: !(values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) ? (values === null || values === void 0 ? void 0 : values.slots[index].isEdit) ? 1.6 : 1.8 : 1.8 },
            React.createElement(material_1.Box, null,
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "timeSlots", value: (storedTimeSlot === null || storedTimeSlot === void 0 ? void 0 : storedTimeSlot.value) !== undefined && (storedTimeSlot === null || storedTimeSlot === void 0 ? void 0 : storedTimeSlot.value) !== '' ? storedTimeSlot.index === index ? storedTimeSlot.value : values === null || values === void 0 ? void 0 : values.slots[index].timeSlots : values === null || values === void 0 ? void 0 : values.slots[index].timeSlots, isDisabled: ((_e = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _e === void 0 ? void 0 : _e.isNewRoute) ? values.slots[index].event && values.slots[index].event.label != 'PICKUP' ? false : values.slots[index].ccCode ? false : true : !values.slots[index].isEdit ? true : false, dropDownList: (timeSlotss === null || timeSlotss === void 0 ? void 0 : timeSlotss.length) > 0 ? timeSlotss : [], placeholder: "Time Slots", onChange: function (val) {
                        if (values.slots[index].isNewRoute) {
                            var targetHour = val.value.substring(0, 2);
                            var targetMin = values === null || values === void 0 ? void 0 : values.slots[index].minutes.label;
                            var fistHandoverSlotValidate = false;
                            if ((values === null || values === void 0 ? void 0 : values.slots[index].event.label) != 'PICKUP' && (values === null || values === void 0 ? void 0 : values.slots[index].minutes) != '') {
                                var eta = "".concat(val.value.substring(0, 2)).concat(values.slots[index].minutes.label);
                                var firstVisitETA = "".concat(values.slots[0].hours).concat(values.slots[0].minutes.label);
                                if (parseInt(eta) < parseInt(firstVisitETA)) {
                                    fistHandoverSlotValidate = true;
                                    react_hot_toast_1.default.error('The first visit cannot be a HANDOVER visit');
                                }
                            }
                            if (!fistHandoverSlotValidate) {
                                var validation = validateSlots(values.slots, targetHour, targetMin);
                                setFieldValue("slots[".concat(index, "].timeSlots"), val);
                                if (!validation) {
                                    setFieldValue("slots[".concat(index, "].hours"), val.value.substring(0, 2));
                                    setFieldValue("slots[".concat(index, "].isError"), '');
                                    setFieldTouched('timeSlots', true);
                                }
                                else {
                                    setFieldValue("slots[".concat(index, "].isError"), "The selected timeslot is already assigned. Please select a different one.");
                                }
                            }
                            setFieldTouched('timeSlots', true);
                        }
                        else {
                            setStoredTimeSlot({ index: index, value: val });
                        }
                    }, required: true }),
                values.slots[index].isSubmitted && values.slots[index].event != '' && values.slots[index].timeSlots === '' ? (React.createElement("div", { className: "error" }, " Please select a timeslot ")) : null)),
        React.createElement(Grid_1.default, { item: true, xs: 1, lg: 1 },
            React.createElement(text_filed_component_1.default, { name: "hours", placeholder: "Hours", label: "Hours", value: (storedTimeSlot === null || storedTimeSlot === void 0 ? void 0 : storedTimeSlot.value) ? storedTimeSlot === null || storedTimeSlot === void 0 ? void 0 : storedTimeSlot.value.value.substring(0, 2) : values === null || values === void 0 ? void 0 : values.slots[index].hours, isDisabled: true, required: true })),
        React.createElement(Grid_1.default, { item: true, xs: 1.1, lg: 1.1 },
            React.createElement(drop_down_component_1.default, { disableClearable: true, name: "minutes", value: (storedMins === null || storedMins === void 0 ? void 0 : storedMins.value) !== undefined && (storedMins === null || storedMins === void 0 ? void 0 : storedMins.value) !== '' ? storedMins.index === index ? storedMins.value : values === null || values === void 0 ? void 0 : values.slots[index].minutes : values === null || values === void 0 ? void 0 : values.slots[index].minutes, dropDownList: appConstants_1.MinutesUltrStatic.length > 0 ? appConstants_1.MinutesUltrStatic : [], onChange: function (val) {
                    if (values.slots[index].isNewRoute) {
                        var fistHandoverSlotValidate = false;
                        if ((values === null || values === void 0 ? void 0 : values.slots[index].event.label) != 'PICKUP') {
                            var eta = "".concat(values.slots[index].hours).concat(val.label);
                            var firstVisitETA = "".concat(values.slots[0].hours).concat(values.slots[0].minutes.label);
                            if (parseInt(eta) < parseInt(firstVisitETA)) {
                                fistHandoverSlotValidate = true;
                                react_hot_toast_1.default.error('The first visit cannot be a HANDOVER visit');
                            }
                        }
                        if (!fistHandoverSlotValidate) {
                            var targetHour = values === null || values === void 0 ? void 0 : values.slots[index].timeSlots.value.substring(0, 2);
                            var targetMin = val.label;
                            var validation = validateSlots(values.slots, targetHour, targetMin);
                            if (!validation) {
                                setFieldValue("slots[".concat(index, "].minutes"), val);
                                setFieldValue("slots[".concat(index, "].isError"), '');
                                setFieldTouched('timeSlots', true);
                            }
                            else {
                                setFieldValue("slots[".concat(index, "].isError"), 'The selected timeslot is already assigned. Please select a different one.');
                                react_hot_toast_1.default.error('The selected timeslot is already assigned. Please select a different one.', { duration: 3000 });
                            }
                            setFieldTouched('minutes', true);
                        }
                    }
                    else {
                        setStoredMins({ index: index, value: val });
                    }
                }, onInputChange: function (val) {
                    var _a;
                    if (parseInt((_a = val === null || val === void 0 ? void 0 : val.target) === null || _a === void 0 ? void 0 : _a.value) > 59) {
                        react_hot_toast_1.default.error('Select a minute under 59', { duration: 3000 });
                    }
                }, placeholder: "Minutes", required: true, isDisabled: ((_f = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _f === void 0 ? void 0 : _f.isNewRoute) ? values.slots[index].event && values.slots[index].event.label != 'PICKUP' ? false : values.slots[index].ccCode ? false : true : !values.slots[index].isEdit ? true : false }),
            values.slots[index].isSubmitted && values.slots[index].event != '' && values.slots[index].minutes === '' ? (React.createElement("div", { className: "error" }, " Please select a minute ")) : null),
        React.createElement(Grid_1.default, { item: true, xs: 1, lg: 1, className: "DeleteIcon" },
            React.createElement("div", { className: 'displayflex' },
                !(values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) && React.createElement("div", { style: { marginTop: '10px' } },
                    React.createElement(Switch_1.default, { name: 'status', checked: (storedStatus === null || storedStatus === void 0 ? void 0 : storedStatus.value) !== undefined && (storedStatus === null || storedStatus === void 0 ? void 0 : storedStatus.value) !== '' ? storedStatus.index === index ? storedStatus.value === 'ACTIVE' ? true : false :
                            (values === null || values === void 0 ? void 0 : values.slots[index].status) === 'ACTIVE' ? true : false : (values === null || values === void 0 ? void 0 : values.slots[index].status) === 'ACTIVE' ? true : false, onChange: function (event) {
                            if (values.slots[index].isNewRoute) {
                                setFieldValue("slots[".concat(index, "].status"), event.target.checked ? 'ACTIVE' : 'INACTIVE');
                                setFieldTouched('status', true);
                            }
                            else {
                                if ((values === null || values === void 0 ? void 0 : values.slots[index].status) === 'ACTIVE' || (values === null || values === void 0 ? void 0 : values.slots[index].status) === 'INACTIVE') {
                                    setStoredStatus({ index: index, value: event.target.checked ? 'ACTIVE' : 'INACTIVE' });
                                }
                                else {
                                    if ((values === null || values === void 0 ? void 0 : values.slots[index].status) === 'SYNC_INACTIVE') {
                                        react_hot_toast_1.default.error('Sorry! The center cannot be enabled as it is deactivated from LPL.', { duration: 3000 });
                                    }
                                    else if ((values === null || values === void 0 ? void 0 : values.slots[index].status) === 'ROUTE_ASSIGNMENT_INACTIVE') {
                                        react_hot_toast_1.default.error('Sorry! The center cannot be enabled as the route type is not ultrastatic.', { duration: 3000 });
                                    }
                                }
                            }
                        }, disabled: ((_g = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _g === void 0 ? void 0 : _g.isNewRoute) ? false : !values.slots[index].isEdit ? true : false })),
                !(values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) &&
                    React.createElement(button_component_1.default, { type: "button", onClickHandler: function () {
                            setFieldValue("slots[".concat(index, "].isDelete"), true);
                            setDeleteIndex(index);
                            handleOpenPop();
                        }, startIcon: React.createElement(Delete_1.default, null), buttonName: '' }),
                !(values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) && !(values === null || values === void 0 ? void 0 : values.slots[index].isEdit) &&
                    React.createElement(material_1.Box, { style: { marginTop: '5px' } },
                        React.createElement(material_1.IconButton, { className: "icon-btn", onClick: function () {
                                if (checkIsEditFalse(values.slots)) {
                                    react_hot_toast_1.default.error('Please complete the current action', { duration: 3000 });
                                }
                                else {
                                    setFieldValue("slots[".concat(index, "].isEdit"), !values.slots[index].isEdit);
                                    if (rowId) {
                                        getSlotsList(values.slots[index].event, values, values.slots[index].ccCode.value);
                                    }
                                }
                            } },
                            React.createElement(Edit_1.default, { sx: { fontSize: '27px' } }))),
                !(values === null || values === void 0 ? void 0 : values.slots[index].isNewRoute) ? (values === null || values === void 0 ? void 0 : values.slots[index].isEdit) ?
                    React.createElement(React.Fragment, null,
                        React.createElement(Cross_Icon_Button_1.default, { onClick: function () { return crossClickHandler(setFieldValue, values, index); } }),
                        React.createElement(Checked_Icon__Button_1.default, { onClick: function () { return checkedClickHandler(setFieldValue, values, index); } })) : '' :
                    React.createElement(React.Fragment, null,
                        React.createElement(Cross_Icon_Button_1.default, { onClick: function () {
                                remove(index);
                            } }),
                        React.createElement(Checked_Icon__Button_1.default, { onClick: function () {
                                checkedClickHandler(setFieldValue, values, index);
                            } })))),
        React.createElement(material_1.Modal, { open: values === null || values === void 0 ? void 0 : values.slots[index].isDelete },
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                    React.createElement("h2", null, "Delete FE Visit")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, "Are you sure want to delete this Visit?")),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "No", color: "yellow-outline", onClickHandler: function () { handleClosePop(setFieldValue, index); } }),
                    React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", onClickHandler: function () {
                            deleteRoute(setFieldValue, remove, values, index);
                        } }))))));
};
exports.default = React.memo(UltraStaticVisit);
