"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var react_query_1 = require("react-query");
var button_component_1 = require("../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var table_data_1 = require("../../../constants/table_data");
var array_helpers_1 = require("../../../utils/array_helpers");
var react_router_dom_1 = require("react-router-dom");
var route_path_1 = require("../../../constants/route_path");
var ultra_static_api_1 = require("./ultra_static_api");
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var pop_up_1 = require("../../../common/modal/pop-up");
var schema_1 = require("../../../constants/schema");
require("../ultra_static/ultra_static.scss");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var CC_api_calls_1 = require("../CC/CC_api_calls");
var react_hot_toast_1 = require("react-hot-toast");
var menu_constants_1 = require("../../../menuList/menu_constants");
var UltraStaticList = function () {
    var nav = (0, react_router_dom_1.useNavigate)();
    var labels = [
        {
            id: 1,
            label: 'FE',
            value: 'FE',
        },
        {
            id: 2,
            label: 'CENTER',
            value: 'CENTER',
        },
        {
            id: 3,
            label: 'LAB',
            value: 'LAB',
        },
    ];
    var routing_types_list = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.routing_assignment_type; });
    var routeType = routing_types_list.filter(function (routeType) { return routeType.label === "ULTRA_STATIC"; });
    var _a = React.useState(undefined), ultraStaticList = _a[0], setUltraStaticList = _a[1];
    var _b = React.useState(null), selectlab = _b[0], setSelectedLab = _b[1];
    var _c = React.useState(''), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = React.useState(0), totalPageCount = _d[0], setTotalPageCount = _d[1];
    var _e = React.useState(1), pageCount = _e[0], setpageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var _g = React.useState(false), openPop = _g[0], setOpenPop = _g[1];
    var _h = React.useState(false), downloadPopUp = _h[0], setDownloadPopUp = _h[1];
    var _j = React.useState(false), deleteID = _j[0], setDeleteID = _j[1];
    var _k = React.useState([]), dropdownOptions = _k[0], setDropdownOptions = _k[1];
    var _l = React.useState(false), navigationPopup = _l[0], setNavigationPopup = _l[1];
    var ultrastatic_route_status_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_route_status_type; });
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _m = React.useState(appConstants_1.defaultPageLimit), PageLimit = _m[0], setPageLimit = _m[1];
    var _o = React.useState(), errorMessage = _o[0], setErrorMessage = _o[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.ULTRA_STATIC);
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var refetch = (0, react_query_1.useQuery)(['ultra-static-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var queryParam = "page=".concat(currentPage, "&size=").concat(PageLimit ? parseInt(PageLimit.value.toString()) : 50);
        if (selectlab !== null) {
            queryParam = queryParam + "&lab_code=".concat(selectlab === null || selectlab === void 0 ? void 0 : selectlab.value);
        }
        if (searchTerm.trim()) {
            queryParam = queryParam + "&mobile=".concat(searchTerm);
        }
        (0, ultra_static_api_1.getUltraStaticList)(queryParam)
            .then(function (response) {
            console.log(response, 'datadat');
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setTotalPageCount(count);
            setUltraStaticList((0, array_helpers_1.convertUltraStaticFEList)(response.data.results));
            setTotalCount(response.data.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setUltraStaticList([]);
        });
    }).refetch;
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    var LabHandler = function (Lab) {
        setSelectedLab(Lab);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var navigateToUltraStatic = function () {
        nav("/".concat(route_path_1.default.PATHS.ultra_static_list, "/").concat(route_path_1.default.PATHS.create_ultra_static));
    };
    var updateStatus = function (payload) {
        (0, ultra_static_api_1.ultraStaticUpdateList)(payload)
            .then(function (response) {
            if (response.statusCode === 200) {
                setOpenPop(false);
                refetch();
            }
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var deleteHandler = function () {
        var status = 'DELETED';
        var routeStatus = ultrastatic_route_status_types.filter(function (route) { return route.label === status; });
        var payload = {
            id: deleteID,
            status: routeStatus[0].value
        };
        updateStatus(payload);
    };
    var handleClosePop = function () {
        setOpenPop(false);
    };
    var navigateToDetailsPage = function (event) {
        var evenType = event.event.target.className;
        if (evenType.includes('PrivateSwitchBase')) {
            var status_1 = event.data.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
            var routeStatus = ultrastatic_route_status_types.filter(function (route) { return route.label === status_1; });
            var payload = {
                id: event.data.id,
                status: routeStatus[0].value
            };
            updateStatus(payload);
        }
        else if (evenType.includes('icon-delete_forever')) {
            setDeleteID(event.data.id);
            setOpenPop(true);
        }
        else if (evenType.includes('icon-edit')) {
            nav("/".concat(route_path_1.default.PATHS.ultra_static_list, "/").concat(route_path_1.default.PATHS.edit_ultra_static, "/").concat(event.data.fe_id));
        }
    };
    var formSubmitHandler = function (values) {
        var _a;
        setDownloadPopUp(false);
        setNavigationPopup(true);
        var payload = {
            report_by: values.reportBy
        };
        var selectedRecords = (_a = values.selectedRecords) === null || _a === void 0 ? void 0 : _a.map(function (record) { return record.value; });
        console.log(selectedRecords);
        if (values.reportBy === 'FE') {
            payload["fe_id"] = selectedRecords;
        }
        if (values.reportBy === 'CENTER') {
            payload["warehouse_code"] = selectedRecords;
        }
        if (values.reportBy === 'LAB') {
            payload["lab_code"] = selectedRecords;
        }
        (0, ultra_static_api_1.generateUltraStaticReport)(payload);
    };
    var openSwapPop = function () { return setDownloadPopUp(true); };
    var closeSwapPop = function () { return setDownloadPopUp(false); };
    var fetchCCList = function () {
        var ccPayload = {
            route_type: [routeType[0].label],
            coordinates_available: true
        };
        (0, CC_api_calls_1.fetchCCTimeSlotList)(ccPayload)
            .then(function (response) {
            setDropdownOptions((0, array_helpers_1.convertUltraStaticCCList)(response.result));
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var radioOnChangeHandler = function (event, setFieldValue, setFieldTouched) {
        setFieldValue('selectedRecords', []);
        if (event.target.value === 'FE') {
            setFieldValue('reportBy', 'FE');
            setFieldTouched('reportBy', true);
            setDropdownOptions((0, array_helpers_1.convertUltraStaticFEResponse)(ultraStaticList));
        }
        else if (event.target.value === 'CENTER') {
            setFieldValue('reportBy', 'CENTER');
            setFieldTouched('reportBy', true);
            fetchCCList();
        }
        else {
            setFieldValue('reportBy', 'LAB');
            setFieldTouched('reportBy', true);
            setDropdownOptions((0, array_helpers_1.convertLabcodeResponse)(labList));
        }
    };
    var multiSelectorChangeHandler = function (event, setFieldValue, setFieldTouched) {
        if (event.length <= 5) {
            setFieldValue('selectedRecords', event);
            setFieldTouched('selectedRecords', true);
        }
        else {
            react_hot_toast_1.default.error('Only 5 records are selectable', { duration: 3000 });
        }
    };
    var navigateToMyReports = function () {
        setNavigationPopup(false);
        nav("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
            React.createElement("div", { className: "tool-bar-wrapper" },
                React.createElement("div", { className: "page-heaidng" },
                    React.createElement("h2", null, "Ultra Static ")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "Download", color: "yellow-outline", endIcon: "icon-import download-icon", onClickHandler: openSwapPop }),
                    React.createElement(button_component_1.default, { buttonName: "Create Ultra Static Route", color: "yellow", onClickHandler: navigateToUltraStatic, isError: !features[menu_constants_1.FEATURE_CONSTANTS.CREATE_ULTRA_STATIC] }))),
            React.createElement("div", { className: "filter-menu-wrapper long-filter" },
                React.createElement("div", { className: "search-filter search-lg" },
                    React.createElement(search_component_1.default, { placeholder: "Search Mobile Number", searchValue: searchTerm, searchHandler: searchingHandler })),
                React.createElement("div", { className: "filter-options" },
                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "labcode", value: selectlab || null, dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList), placeholder: "Lab code", onChange: LabHandler }),
                    React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                    React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
            React.createElement("div", { className: "scrollable-content fe-managment-screen" },
                React.createElement("div", { className: "custom-pagination-table" },
                    React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ULTRA_STATIC_COLUMNS, rows: ultraStaticList, pagination: false, multiSelector: "multiselect", errorMessage: errorMessage, onRowClicked: navigateToDetailsPage }),
                    React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: ultraStaticList === null || ultraStaticList === void 0 ? void 0 : ultraStaticList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })))),
        React.createElement(material_1.Modal, { open: openPop },
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                    React.createElement("h2", null, "Delete FE Route")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, "Are you sure want to delete this FE Routes?")),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClosePop }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: "Confirm", color: "yellow", onClickHandler: deleteHandler })))),
        React.createElement(pop_up_1.default, { open: downloadPopUp, popUpSize: "popup-lg" },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("h2", { className: "heading" }, "Report Genaration")),
            React.createElement(formik_1.Formik, { initialValues: {
                    reportBy: '',
                    selectedRecords: ''
                }, validateOnBlur: false, onSubmit: formSubmitHandler, validationSchema: schema_1.schemas.Ultrastatic_Genarate_Report_Schema }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, null,
                    React.createElement(material_1.Box, null,
                        React.createElement("div", { style: { width: '100%' } },
                            React.createElement("h2", { className: "sub-heading" }, "Please select an option to generate a report"),
                            React.createElement("div", { className: "ultrastatic-radio-button-wrapper" },
                                React.createElement(material_1.FormControl, null,
                                    React.createElement(material_1.RadioGroup, { className: "radio-group", "aria-labelledby": "demo-radio-buttons-group-label", name: "radio-buttons-group" }, labels &&
                                        labels.map(function (list) {
                                            return (React.createElement(material_1.FormControlLabel, { name: "reportBy", value: list.value, control: React.createElement(material_1.Radio, null), label: list.label, className: "radio-btn", disabled: list.disabled === true, onChange: function (event) { return radioOnChangeHandler(event, setFieldValue, setFieldTouched); } }));
                                        })))),
                            errors['reportBy'] && touched['reportBy'] ? (React.createElement("div", { className: "error" },
                                errors['reportBy'],
                                " ")) : null),
                        React.createElement("div", { style: { width: '83%' } },
                            React.createElement("h2", { className: "sub-heading" }, "Please select upto 5 records"),
                            React.createElement(multi_selector_1.default, { size: "dropdown-lg", dropDownList: dropdownOptions, label: '', isDisabled: (values === null || values === void 0 ? void 0 : values.reportBy) === '', hasSelectAll: true, onChangeHnadlre: function (event) { return multiSelectorChangeHandler(event, setFieldValue, setFieldTouched); }, value: values === null || values === void 0 ? void 0 : values.selectedRecords }),
                            errors['selectedRecords'] && touched['selectedRecords'] ? (React.createElement("div", { className: "error" },
                                errors['selectedRecords'],
                                " ")) : null)),
                    React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Close", color: "yellow-outline", onClickHandler: closeSwapPop }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Done", color: "yellow" }))));
            })),
        React.createElement(pop_up_1.default, { open: navigationPopup },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setNavigationPopup(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = UltraStaticList;
