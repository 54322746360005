"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetch_etrf_status = exports.fetch_masterbox_status = exports.featchCurrentActiveMenu = exports.hub_types = exports.fetch_route_planner_types = exports.fetch_visit_types = exports.fetch_visit_status_types = exports.fetch_route_plan_data_types = exports.fetch_route_plan_center_types = exports.fetch_centre_types = exports.fetch_cash_status = exports.fetch_report_types = exports.fetch_report_status_types = exports.features_list = exports.menu_list = exports.FETCH_ROUTE_ASSIGNMENT_TYPE = exports.fetch_SampleStatusList = exports.fetch_AuditStatusList = exports.fetch_HubIDList = exports.fetch_AuditCityList = exports.fetch_feList = exports.fetch_ccList = exports.fetch_userTypes = exports.fetch_vendor_contract_types = exports.fetch_vendor_duration = exports.fetch_entity_types = exports.fetch_user_type = exports.fetch_vendor = exports.fetch_zoneTypes = exports.fetch_genderTypes = exports.fetch_vehicleTypes = exports.fetch_lab_code = exports.fetch_permanentCities = exports.fetch_address_proof_types = exports.fetch_cities = exports.fetch_states = exports.fetch_countries = exports.fetch_onboarding_status = exports.fetch_reject_reason_list = exports.fetch_in_voluntorily_exit_reson_list = exports.fetch_voluntorily_exit_reson_list = exports.fetch_exit_reson_list = exports.fetch_exit_fe_id = exports.fetch_zone = exports.fetch_company = exports.fetch_tensure = exports.fetch_inventories = exports.fetch_page_state = exports.fetch_types = exports.CONFIG_ACTIONS = void 0;
exports.ultrastaticScheduleTypes = exports.ultrastaticRouteTypes = exports.ultrastaticRouteStatusTypes = exports.fetch_centerAditee_types = exports.fetchSCFSlabsList = exports.fetchSCFPaymentTypes = exports.fetchSCFPaymentMode = exports.fetchSCFCourierChargesTypes = exports.fetchSCFClientStatusList = exports.fetchOrcRejectResons = exports.fetchOrcDeactiveResons = exports.fetchOrcActionList = exports.fetchOrcStatusList = exports.fetch_fe_working_cities = exports.fetch_audit_types = exports.fetch_user_region_labs = exports.fetch_route_assignments = exports.shipment_status = exports.fetch_sso_user_details = exports.fetch_notification_occurrence = exports.fetch_notification_category = void 0;
exports.CONFIG_ACTIONS = {
    FE_TYPES: 'FETCH_FE_TYPES',
    CC_LIST: 'CC_LIST',
    FE_LIST: 'FE_LIST',
    INVENTORIES: 'FETCH__INVENTORIES',
    TENSURE: 'FETCH__TENSURE',
    COMPANY: 'FETCH_COMPANY',
    ZONE: 'FETCH_ZONE',
    FE_EXIT_RESON_LIST: 'FE_EXIT_RESON_LIST',
    FE_EXIT_VOLUNTORLY_RESON_LIST: 'FE_EXIT_VOLUNTORLY_RESON_LIST',
    FE_EXIT_INVOLUNTORLY_RESON_LIST: 'FE_EXIT_INVOLUNTORLY_RESON_LIST',
    FE_REJECT_RESON_LIST: 'FE_REJECT_RESON_LIST',
    FETCH_ONBAORDING_STATUS: 'FETCH_ONBAORDING_STATUS',
    FETCH_COUNTRIES: 'FETCH_COUNTRIES',
    FETCH_STATES: 'FETCH_STATES',
    FETCH_PRESENTCITIES: 'FETCH_CITIES',
    FETCH_PERMANENTCITIES: 'FETCH_PERMANENTCITIES',
    LAB_CODE: 'FETCH_LAB_CODE',
    VEHICLE_TYPES: 'VEHICLE_TYPES',
    GENDER_TYPES: 'GENDER_TYPES',
    ZONE_TYPES: 'ZONE_TYPES',
    EXIT_FE_LIST_TYPES: 'EXIT_FE_LIST_TYPES',
    PAGE_STATE: 'PAGE_STATE',
    USER_TYPES: 'USER_TYPES',
    AUDIT_CITY_LIST: 'AUDIT_CITY_LIST',
    HUBID_LIST: 'HUBID_LIST',
    AUDIT_STATUS_LIST: 'AUDIT_STATUS_LIST',
    ADDRESS_PROOF_TYPE_LIST: 'ADDRESS_PROOF_TYPE_LIST',
    FETCH_VENDOR: 'FETCH_VENDOR',
    FETCH_ENTITY: 'FETCH_ENTITY',
    FETCH_VENDOR_DURATION: 'FETCH_VENDOR_DURATION',
    FETCH_VENDOR_CONTRACT: 'FETCH_VENDOR_CONTRACT',
    FETCH_SAMPLE_STATUS: 'FETCH_SAMPLE_STATUS',
    FETCH_USER_TYPE: 'FETCH_USER_TYPE',
    FETCH_ROUTE_ASSIGNMENT_TYPE: 'FETCH_ROUTE_ASSIGNMENT_TYPE',
    MENU_LIST: 'MENU_LIST',
    FEATURES_LIST: 'FEATURES_LIST',
    REPORT_STATUS_TYPES: 'REPORT_STATUS_TYPES',
    REPORT_TYPES: 'REPORT_TYPES',
    CASH_STATUS: 'CASH_STATUS',
    CENTRE_TYPES: 'CENTRE_TYPES',
    ROUTE_PLANNER_CENTER_TYPES: 'ROUTE_PLANNER_CENTER_TYPES',
    ROUTE_PLANNER_DATA_TYPES: 'ROUTE_PLANNER_DATA_TYPES',
    VISIT_STATUS: 'VISIT_STATUS',
    VISIT_TYPES: 'VISIT_TYPES',
    ROUTE_PLANNER_STATUS_TYPES: 'ROUTE_PLANNER_STATUS_TYPES',
    HUB_TYPES: 'HUB_TYPES',
    MASTER_BOX_STATUS: 'MASTER_BOX_STATUS',
    CURRENT_MENU_NAME: 'CURRENT_MENU_NAME',
    ETRF_STATUS: 'ETRF_STATUS',
    NOTIFICATION_CATEGORY: 'NOTIFICATION_CATEGORY',
    NOTIFICATION_OCCURRENCE: 'NOTIFICATION_OCCURRENCE',
    SSO_USER_DETAILS: 'SSO_USER_DETAILS',
    SHIPMENT_STATUS: 'SHIPMENT_STATUS',
    ROUTING_ASSIGNMENTS: 'ROUTING_ASSIGNMENTS',
    USER_REGION_LABS: 'USER_REGION_LABS',
    AUDIT_TYPES: 'AUDIT_TYPES',
    FETCH_FE_WORKING_CITIES: 'FETCH_FE_WORKING_CITIES',
    ORC_CLIENT_STATUS: 'ORC_STATUS_LIST',
    ORCSCF_ACTION: 'ORCSCF_ACTION',
    ORC_DEACTIVE_RESONS: 'ORC_INACTIVE_RESONS',
    ORC_REJECT_RESONS: 'ORC_REJECT_RESONS',
    SCF_CLIENT_STATUS: 'SCF_CLIENT_STATUS',
    SCF_COURIER_CHARGES_TYEPS: 'SCF_COURIER_CHARGES_TYEPS',
    SCF_PAYMENT_MODE_OPTION: 'SCF_PAYMENT_MODE_OPTION',
    SCF_PAYMENT_TYPE: 'SCF_PAYMENT_TYPE',
    SCF_SLABS_LIST: 'SCF_SLABS_LIST',
    CENTER_AUDITEES_TYPES: 'CENTER_AUDITEES_TYPES',
    ULTRASTATIC_STATUS_TYPES: 'ULTRASTATIC_STATUS_TYPES',
    ULTRASTATIC_ROUTE_TYPES: 'ULTRASTATIC_ROUTE_TYPES',
    ULTRASTATIC_SCHEDULE_TYPES: 'ULTRASTATIC_SCHEDULE_TYPES',
};
var fetch_types = function (feTypes) {
    return {
        type: exports.CONFIG_ACTIONS.FE_TYPES,
        payload: feTypes,
    };
};
exports.fetch_types = fetch_types;
var fetch_page_state = function (page_state) {
    return {
        type: exports.CONFIG_ACTIONS.PAGE_STATE,
        payload: page_state,
    };
};
exports.fetch_page_state = fetch_page_state;
var fetch_inventories = function (inventories) {
    return {
        type: exports.CONFIG_ACTIONS.INVENTORIES,
        payload: inventories,
    };
};
exports.fetch_inventories = fetch_inventories;
var fetch_tensure = function (tensure) {
    return {
        type: exports.CONFIG_ACTIONS.TENSURE,
        payload: tensure,
    };
};
exports.fetch_tensure = fetch_tensure;
var fetch_company = function (company) {
    return {
        type: exports.CONFIG_ACTIONS.COMPANY,
        payload: company,
    };
};
exports.fetch_company = fetch_company;
var fetch_zone = function (zone) {
    return {
        type: exports.CONFIG_ACTIONS.ZONE,
        payload: zone,
    };
};
exports.fetch_zone = fetch_zone;
var fetch_exit_fe_id = function (exit_fe_id) {
    return {
        type: exports.CONFIG_ACTIONS.EXIT_FE_LIST_TYPES,
        payload: exit_fe_id,
    };
};
exports.fetch_exit_fe_id = fetch_exit_fe_id;
var fetch_exit_reson_list = function (exitResonList) {
    return {
        type: exports.CONFIG_ACTIONS.FE_EXIT_RESON_LIST,
        payload: exitResonList,
    };
};
exports.fetch_exit_reson_list = fetch_exit_reson_list;
var fetch_voluntorily_exit_reson_list = function (exitResonList) {
    return {
        type: exports.CONFIG_ACTIONS.FE_EXIT_VOLUNTORLY_RESON_LIST,
        payload: exitResonList,
    };
};
exports.fetch_voluntorily_exit_reson_list = fetch_voluntorily_exit_reson_list;
var fetch_in_voluntorily_exit_reson_list = function (exitResonList) {
    return {
        type: exports.CONFIG_ACTIONS.FE_EXIT_INVOLUNTORLY_RESON_LIST,
        payload: exitResonList,
    };
};
exports.fetch_in_voluntorily_exit_reson_list = fetch_in_voluntorily_exit_reson_list;
var fetch_reject_reason_list = function (rejectReasonList) {
    return {
        type: exports.CONFIG_ACTIONS.FE_REJECT_RESON_LIST,
        payload: rejectReasonList,
    };
};
exports.fetch_reject_reason_list = fetch_reject_reason_list;
var fetch_onboarding_status = function (onboardingList) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_ONBAORDING_STATUS,
        payload: onboardingList,
    };
};
exports.fetch_onboarding_status = fetch_onboarding_status;
var fetch_countries = function (country) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_COUNTRIES,
        payload: country,
    };
};
exports.fetch_countries = fetch_countries;
var fetch_states = function (satesList) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_STATES,
        payload: satesList,
    };
};
exports.fetch_states = fetch_states;
var fetch_cities = function (cityList) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_PRESENTCITIES,
        payload: cityList,
    };
};
exports.fetch_cities = fetch_cities;
var fetch_address_proof_types = function (addressProofTypes) {
    return {
        type: exports.CONFIG_ACTIONS.ADDRESS_PROOF_TYPE_LIST,
        payload: addressProofTypes,
    };
};
exports.fetch_address_proof_types = fetch_address_proof_types;
var fetch_permanentCities = function (cityList) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_PERMANENTCITIES,
        payload: cityList,
    };
};
exports.fetch_permanentCities = fetch_permanentCities;
var fetch_lab_code = function (labList) {
    return {
        type: exports.CONFIG_ACTIONS.LAB_CODE,
        payload: labList,
    };
};
exports.fetch_lab_code = fetch_lab_code;
var fetch_vehicleTypes = function (vehicleTypeList) {
    return {
        type: exports.CONFIG_ACTIONS.VEHICLE_TYPES,
        payload: vehicleTypeList,
    };
};
exports.fetch_vehicleTypes = fetch_vehicleTypes;
var fetch_genderTypes = function (genderTypeList) {
    return {
        type: exports.CONFIG_ACTIONS.GENDER_TYPES,
        payload: genderTypeList,
    };
};
exports.fetch_genderTypes = fetch_genderTypes;
var fetch_zoneTypes = function (zoneTypeList) {
    return {
        type: exports.CONFIG_ACTIONS.ZONE_TYPES,
        payload: zoneTypeList,
    };
};
exports.fetch_zoneTypes = fetch_zoneTypes;
var fetch_vendor = function (vendor_type) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_VENDOR,
        payload: vendor_type,
    };
};
exports.fetch_vendor = fetch_vendor;
var fetch_user_type = function (user_type) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_USER_TYPE,
        payload: user_type,
    };
};
exports.fetch_user_type = fetch_user_type;
var fetch_entity_types = function (entity_type) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_ENTITY,
        payload: entity_type,
    };
};
exports.fetch_entity_types = fetch_entity_types;
var fetch_vendor_duration = function (vendor_duration) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_VENDOR_DURATION,
        payload: vendor_duration,
    };
};
exports.fetch_vendor_duration = fetch_vendor_duration;
var fetch_vendor_contract_types = function (list) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_VENDOR_CONTRACT,
        payload: list,
    };
};
exports.fetch_vendor_contract_types = fetch_vendor_contract_types;
var fetch_userTypes = function (userTypeList) {
    return {
        type: exports.CONFIG_ACTIONS.USER_TYPES,
        payload: userTypeList,
    };
};
exports.fetch_userTypes = fetch_userTypes;
var fetch_ccList = function (ccList) {
    return {
        type: exports.CONFIG_ACTIONS.CC_LIST,
        payload: ccList,
    };
};
exports.fetch_ccList = fetch_ccList;
var fetch_feList = function (feList) {
    return {
        type: exports.CONFIG_ACTIONS.FE_LIST,
        payload: feList,
    };
};
exports.fetch_feList = fetch_feList;
var fetch_AuditCityList = function (cityList) {
    return {
        type: exports.CONFIG_ACTIONS.AUDIT_CITY_LIST,
        payload: cityList,
    };
};
exports.fetch_AuditCityList = fetch_AuditCityList;
var fetch_HubIDList = function (HubIdList) {
    return {
        type: exports.CONFIG_ACTIONS.HUBID_LIST,
        payload: HubIdList,
    };
};
exports.fetch_HubIDList = fetch_HubIDList;
var fetch_AuditStatusList = function (AuditStatusList) {
    return {
        type: exports.CONFIG_ACTIONS.AUDIT_STATUS_LIST,
        payload: AuditStatusList,
    };
};
exports.fetch_AuditStatusList = fetch_AuditStatusList;
var fetch_SampleStatusList = function (sample_status) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_SAMPLE_STATUS,
        payload: sample_status,
    };
};
exports.fetch_SampleStatusList = fetch_SampleStatusList;
var FETCH_ROUTE_ASSIGNMENT_TYPE = function (routeAssignmentType) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_ROUTE_ASSIGNMENT_TYPE,
        payload: routeAssignmentType,
    };
};
exports.FETCH_ROUTE_ASSIGNMENT_TYPE = FETCH_ROUTE_ASSIGNMENT_TYPE;
var menu_list = function (menuList) {
    return {
        type: exports.CONFIG_ACTIONS.MENU_LIST,
        payload: menuList,
    };
};
exports.menu_list = menu_list;
var features_list = function (featuresList) {
    return {
        type: exports.CONFIG_ACTIONS.FEATURES_LIST,
        payload: featuresList,
    };
};
exports.features_list = features_list;
var fetch_report_status_types = function (reportStatusTypes) {
    return {
        type: exports.CONFIG_ACTIONS.REPORT_STATUS_TYPES,
        payload: reportStatusTypes,
    };
};
exports.fetch_report_status_types = fetch_report_status_types;
var fetch_report_types = function (reportTypes) {
    return {
        type: exports.CONFIG_ACTIONS.REPORT_TYPES,
        payload: reportTypes,
    };
};
exports.fetch_report_types = fetch_report_types;
var fetch_cash_status = function (cash_status) {
    return {
        type: exports.CONFIG_ACTIONS.CASH_STATUS,
        payload: cash_status,
    };
};
exports.fetch_cash_status = fetch_cash_status;
var fetch_centre_types = function (centre_types) {
    return {
        type: exports.CONFIG_ACTIONS.CENTRE_TYPES,
        payload: centre_types,
    };
};
exports.fetch_centre_types = fetch_centre_types;
var fetch_route_plan_center_types = function (route_plan_center_types) {
    return {
        type: exports.CONFIG_ACTIONS.ROUTE_PLANNER_CENTER_TYPES,
        payload: route_plan_center_types,
    };
};
exports.fetch_route_plan_center_types = fetch_route_plan_center_types;
var fetch_route_plan_data_types = function (route_plan_data_types) {
    return {
        type: exports.CONFIG_ACTIONS.ROUTE_PLANNER_DATA_TYPES,
        payload: route_plan_data_types,
    };
};
exports.fetch_route_plan_data_types = fetch_route_plan_data_types;
var fetch_visit_status_types = function (visitStatus) {
    return {
        type: exports.CONFIG_ACTIONS.VISIT_STATUS,
        payload: visitStatus,
    };
};
exports.fetch_visit_status_types = fetch_visit_status_types;
var fetch_visit_types = function (visitTypes) {
    return {
        type: exports.CONFIG_ACTIONS.VISIT_TYPES,
        payload: visitTypes,
    };
};
exports.fetch_visit_types = fetch_visit_types;
var fetch_route_planner_types = function (routePlanneTypes) {
    return {
        type: exports.CONFIG_ACTIONS.ROUTE_PLANNER_STATUS_TYPES,
        payload: routePlanneTypes,
    };
};
exports.fetch_route_planner_types = fetch_route_planner_types;
var hub_types = function (hubtype) {
    return {
        type: exports.CONFIG_ACTIONS.HUB_TYPES,
        payload: hubtype,
    };
};
exports.hub_types = hub_types;
var featchCurrentActiveMenu = function (currentMenuName) {
    return {
        type: exports.CONFIG_ACTIONS.CURRENT_MENU_NAME,
        payload: currentMenuName,
    };
};
exports.featchCurrentActiveMenu = featchCurrentActiveMenu;
var fetch_masterbox_status = function (status) {
    return {
        type: exports.CONFIG_ACTIONS.MASTER_BOX_STATUS,
        payload: status,
    };
};
exports.fetch_masterbox_status = fetch_masterbox_status;
var fetch_etrf_status = function (status) {
    return {
        type: exports.CONFIG_ACTIONS.ETRF_STATUS,
        payload: status,
    };
};
exports.fetch_etrf_status = fetch_etrf_status;
var fetch_notification_category = function (data) {
    return {
        type: exports.CONFIG_ACTIONS.NOTIFICATION_CATEGORY,
        payload: data,
    };
};
exports.fetch_notification_category = fetch_notification_category;
var fetch_notification_occurrence = function (data) {
    return {
        type: exports.CONFIG_ACTIONS.NOTIFICATION_OCCURRENCE,
        payload: data,
    };
};
exports.fetch_notification_occurrence = fetch_notification_occurrence;
var fetch_sso_user_details = function (userDetails) {
    return {
        type: exports.CONFIG_ACTIONS.SSO_USER_DETAILS,
        payload: userDetails,
    };
};
exports.fetch_sso_user_details = fetch_sso_user_details;
var shipment_status = function (status) {
    return {
        type: exports.CONFIG_ACTIONS.SHIPMENT_STATUS,
        payload: status,
    };
};
exports.shipment_status = shipment_status;
var fetch_route_assignments = function (data) {
    return {
        type: exports.CONFIG_ACTIONS.ROUTING_ASSIGNMENTS,
        payload: data,
    };
};
exports.fetch_route_assignments = fetch_route_assignments;
var fetch_user_region_labs = function (userRegionLabs) {
    return {
        type: exports.CONFIG_ACTIONS.USER_REGION_LABS,
        payload: userRegionLabs,
    };
};
exports.fetch_user_region_labs = fetch_user_region_labs;
var fetch_audit_types = function (data) {
    return {
        type: exports.CONFIG_ACTIONS.AUDIT_TYPES,
        payload: data,
    };
};
exports.fetch_audit_types = fetch_audit_types;
var fetch_fe_working_cities = function (cityList) {
    return {
        type: exports.CONFIG_ACTIONS.FETCH_FE_WORKING_CITIES,
        payload: cityList,
    };
};
exports.fetch_fe_working_cities = fetch_fe_working_cities;
var fetchOrcStatusList = function (orcStatusList) {
    return {
        type: exports.CONFIG_ACTIONS.ORC_CLIENT_STATUS,
        payload: orcStatusList,
    };
};
exports.fetchOrcStatusList = fetchOrcStatusList;
var fetchOrcActionList = function (orcActionList) {
    return {
        type: exports.CONFIG_ACTIONS.ORCSCF_ACTION,
        payload: orcActionList,
    };
};
exports.fetchOrcActionList = fetchOrcActionList;
var fetchOrcDeactiveResons = function (orcDactiveResons) {
    return {
        type: exports.CONFIG_ACTIONS.ORC_DEACTIVE_RESONS,
        payload: orcDactiveResons,
    };
};
exports.fetchOrcDeactiveResons = fetchOrcDeactiveResons;
var fetchOrcRejectResons = function (orcRejectResons) {
    return {
        type: exports.CONFIG_ACTIONS.ORC_REJECT_RESONS,
        payload: orcRejectResons,
    };
};
exports.fetchOrcRejectResons = fetchOrcRejectResons;
var fetchSCFClientStatusList = function (scfStatusList) {
    return {
        type: exports.CONFIG_ACTIONS.SCF_CLIENT_STATUS,
        payload: scfStatusList,
    };
};
exports.fetchSCFClientStatusList = fetchSCFClientStatusList;
var fetchSCFCourierChargesTypes = function (scfCourierChargesTypes) {
    return {
        type: exports.CONFIG_ACTIONS.SCF_COURIER_CHARGES_TYEPS,
        payload: scfCourierChargesTypes,
    };
};
exports.fetchSCFCourierChargesTypes = fetchSCFCourierChargesTypes;
var fetchSCFPaymentMode = function (scfPaymentMode) {
    return {
        type: exports.CONFIG_ACTIONS.SCF_PAYMENT_MODE_OPTION,
        payload: scfPaymentMode,
    };
};
exports.fetchSCFPaymentMode = fetchSCFPaymentMode;
var fetchSCFPaymentTypes = function (scfPaymentTypes) {
    return {
        type: exports.CONFIG_ACTIONS.SCF_PAYMENT_TYPE,
        payload: scfPaymentTypes,
    };
};
exports.fetchSCFPaymentTypes = fetchSCFPaymentTypes;
var fetchSCFSlabsList = function (scfSlabsList) {
    return {
        type: exports.CONFIG_ACTIONS.SCF_SLABS_LIST,
        payload: scfSlabsList,
    };
};
exports.fetchSCFSlabsList = fetchSCFSlabsList;
var fetch_centerAditee_types = function (data) {
    return {
        type: exports.CONFIG_ACTIONS.CENTER_AUDITEES_TYPES,
        payload: data,
    };
};
exports.fetch_centerAditee_types = fetch_centerAditee_types;
var ultrastaticRouteStatusTypes = function (data) {
    return {
        type: exports.CONFIG_ACTIONS.ULTRASTATIC_STATUS_TYPES,
        payload: data,
    };
};
exports.ultrastaticRouteStatusTypes = ultrastaticRouteStatusTypes;
var ultrastaticRouteTypes = function (data) {
    return {
        type: exports.CONFIG_ACTIONS.ULTRASTATIC_ROUTE_TYPES,
        payload: data,
    };
};
exports.ultrastaticRouteTypes = ultrastaticRouteTypes;
var ultrastaticScheduleTypes = function (data) {
    return {
        type: exports.CONFIG_ACTIONS.ULTRASTATIC_SCHEDULE_TYPES,
        payload: data,
    };
};
exports.ultrastaticScheduleTypes = ultrastaticScheduleTypes;
