"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.converReginalMappingLabList = exports.searcRightReginalUser = exports.searcLeftReginalUser = exports.searchRightSideLabs = exports.searchleftSideLabs = void 0;
var searchleftSideLabs = function (currentLeftLabs, searchTerm, RightSideLabs, initialLabs) {
    var filterRightIds = RightSideLabs.map(function (val) { return val.lab_code; });
    var filterLabs = initialLabs.filter(function (val) {
        return !filterRightIds.includes(val.warehouse_code);
    });
    if (searchTerm.length >= 3) {
        var filterData = currentLeftLabs &&
            currentLeftLabs.filter(function (val) {
                if (val.lab_code === searchTerm.toUpperCase()) {
                    return val;
                }
            });
        return filterData;
    }
    else {
        return filterLabs;
    }
};
exports.searchleftSideLabs = searchleftSideLabs;
var searchRightSideLabs = function (currentRightLabs, searchTerm, leftSideLabs, initialLabs) {
    var filterRightIds = leftSideLabs.map(function (val) { return val.warehouse_code; });
    var filterLabs = initialLabs.filter(function (val) {
        return !filterRightIds.includes(val.warehouse_code);
    });
    if (searchTerm.length >= 3) {
        var serchedData = currentRightLabs &&
            currentRightLabs.filter(function (val) {
                if (val.lab_code === searchTerm.toUpperCase()) {
                    return val;
                }
            });
        return serchedData;
    }
    else {
        return filterLabs;
    }
};
exports.searchRightSideLabs = searchRightSideLabs;
var searcLeftReginalUser = function (currenLeftUsers, searchTerm, rightsideUsers, inintalUsers) {
    var filterRightIds = rightsideUsers.map(function (val) { return val.id; });
    var filterUsets = inintalUsers.filter(function (val) {
        return !filterRightIds.includes(val.id);
    });
    if (searchTerm.length >= 3) {
        var serchedData = currenLeftUsers &&
            currenLeftUsers.filter(function (val) {
                if (val.userName.includes(searchTerm.toUpperCase())) {
                    return val;
                }
            });
        return serchedData;
    }
    else {
        return filterUsets;
    }
};
exports.searcLeftReginalUser = searcLeftReginalUser;
var searcRightReginalUser = function (currenRightUsers, searchTerm, leftsideUsers, inintalUsers) {
    var filterRightIds = leftsideUsers.map(function (val) { return val.id; });
    var filterUsets = inintalUsers.filter(function (val) {
        return !filterRightIds.includes(val.id);
    });
    if (searchTerm.length >= 3) {
        var serchedData = currenRightUsers &&
            currenRightUsers.filter(function (val) {
                if (val.userName.includes(searchTerm.toUpperCase())) {
                    return val;
                }
            });
        return serchedData;
    }
    else {
        return filterUsets;
    }
};
exports.searcRightReginalUser = searcRightReginalUser;
var converReginalMappingLabList = function (data) {
    var convertedData = [];
    if (data && data.length > 0) {
        data.map(function (val) {
            var _a, _b;
            var newObj = {
                region_id: val === null || val === void 0 ? void 0 : val.region_id,
                region_labs: val === null || val === void 0 ? void 0 : val.region_labs,
                region_name: (_a = val.region_name) !== null && _a !== void 0 ? _a : '',
                labs_count: val.labs_count != 0 && val.labs_count != undefined && val.labs_count != ''
                    ? Number(val.labs_count)
                    : '',
                users_count: val.user_count != 0 && val.user_count != undefined && val.user_count != ''
                    ? Number(val.user_count)
                    : '',
                created_date_time: (_b = val.created_date_time) !== null && _b !== void 0 ? _b : '',
            };
            convertedData.push(newObj);
        });
    }
    return convertedData;
};
exports.converReginalMappingLabList = converReginalMappingLabList;
