"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConcateCreFnameLname = exports.creExcelReport = exports.updateCre = exports.createCre = exports.creMobileExist = exports.fetchCreUsersList = exports.fetchCreProfile = void 0;
var api_service_1 = require("../../../api/api_service");
var fetchCreProfile = function (creId) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.CRE_PROFILE, "?id=").concat(creId),
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (response) {
            if (response.status === 200) {
                console.log('1324 fetchCreProfile catch response.status === 200', response);
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCreProfile = fetchCreProfile;
var fetchCreUsersList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CRE_USERS_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            var _a;
            if (response.status === 200) {
                return resolve((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCreUsersList = fetchCreUsersList;
var creMobileExist = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.CRE_MOBILE_EXIST),
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            return resolve(res);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.creMobileExist = creMobileExist;
var createCre = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CRE_CREATE_USER,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data.message);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.createCre = createCre;
var updateCre = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CRE_UPDATE_USER,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log('13246 updateCRE', response);
            if (response.status === 200) {
                return resolve(response.data.message);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            console.log('13246 updateCRE', err);
            return reject(err);
        });
    });
};
exports.updateCre = updateCre;
var creExcelReport = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CRE_REPORT,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.creExcelReport = creExcelReport;
var ConcateCreFnameLname = function (fName, lName) {
    var fullName = '';
    if (!fName && !lName) {
        return fullName;
    }
    else if (fName && lName) {
        fullName = "".concat(fName, " ").concat(lName);
        return fullName;
    }
    else if (fName) {
        fullName = fName;
        return fullName;
    }
    else if (lName) {
        fullName = lName;
        return fullName;
    }
    return fullName;
};
exports.ConcateCreFnameLname = ConcateCreFnameLname;
