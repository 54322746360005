"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LAB_INTITAL_VALUES = exports.FE_InventoryDetailsInitialState = exports.FE_EmploymentDetailsInitialState = exports.FE_DrivingLicenceDetailsInitialState = exports.FE_BankAccountDetailsInitialState = exports.FE_ContactDetailsInitialState = exports.FE_PersonalDetailsInitialState = void 0;
var index_1 = require("../store/index");
var vendorCode = index_1.store.getState().appConfigList.vendorCode;
var vendorName = index_1.store.getState().appConfigList.vendor_name;
exports.FE_PersonalDetailsInitialState = {
    firstName: '',
    lastName: '',
    dob: '',
    mobileNumber: '',
    email: '',
    emergencyContactNo: '',
    gender: null,
    uploadPhoto: '',
    referenceContactName1: '',
    referenceMobileNumber1: '',
    referenceContactName2: '',
    referenceMobileNumber2: '',
    isActiveFE: false,
};
exports.FE_ContactDetailsInitialState = {
    addresslane1: '',
    addresslane2: '',
    landmark: '',
    state: null,
    city: null,
    country: null,
    pincode: '',
    addressProofType: null,
    proofOfAddress: '',
    permenentAddresslane1: '',
    permenentAddresslane2: '',
    permenentLandmark: '',
    permenentState: null,
    permenentCity: null,
    permenentCountry: null,
    permenentPincode: '',
    permanentProofOfAddressType: null,
    permenentProofOfAddress: '',
    isActiveFE: false,
};
exports.FE_BankAccountDetailsInitialState = {
    AccountNumber: '',
    IFSC_Code: '',
    Bank_Name: '',
    Bank_Branch: '',
    UPI_ID: '',
    isActiveFE: false,
    proofOfBankAccountDetails: '',
};
exports.FE_DrivingLicenceDetailsInitialState = {
    vehicleType: null,
    drivingLicence: '',
    insuranceNumber: '',
    uploadInsuranceDoc: '',
    insuranceExpirydate: '',
    drivingLicenceExpirydate: '',
    isActiveFE: false,
    proofOfDrivingLicence: '',
};
exports.FE_EmploymentDetailsInitialState = {
    employee_vendor_id: '',
    fe_Type: null,
    dateOfJoining: '',
    company: null,
    tenure: null,
    labCode: null,
    HudId: null,
    labName: '',
    regionalManager: '',
    zone: null,
    FEWorkingState: '',
    FEWorkingCity: '',
    fe_working_state: null,
    fe_working_city: null,
    baseLocation: '',
    base_location_address: '',
    FEVendorCode: vendorCode,
    vendorName: vendorName,
    isActiveFE: false,
    assign_route: null,
    isSuperVisor: false,
    isBackUpFE: false,
    canEditCCCoordinates: false,
};
exports.FE_InventoryDetailsInitialState = {
    selectedInventoryList: [],
};
exports.LAB_INTITAL_VALUES = {
    labcode: null,
    id: null,
    state: '',
    city: '',
    day: null,
    warehouse_code: null,
    schedule_time_slot: '',
    baseLocation: '',
    base_location_address: '',
    staticspeed: null,
    staticradius: null,
    static_cc: null,
    static_fpsc: null,
    static_psc: '',
    static_pup: '',
    static_hub: '',
    static_lab: '',
    dynamicspeed: '',
    dynamicradius: '',
    dynamic_cc: '',
    dynamic_fpsc: '',
    dynamic_psc: '',
    dynamic_pup: '',
    dynamic_hub: '',
    dynamic_lab: '',
    geofence_enabled: 0,
    geofence_radius: 0,
    etrf_scan_enabled: 0,
    ultrastatic_routing_enabled: false,
    us_from_date: '',
    us_to_date: '',
    type: '',
};
