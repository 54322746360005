"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateUltraStaticReport = exports.ultraStaticUpdateList = exports.getUltraStaticTimeSlots = exports.getUltraStaticRoutes = exports.submitUltraStatic = exports.getUltraStaticList = void 0;
var api_service_1 = require("../../../api/api_service");
var react_hot_toast_1 = require("react-hot-toast");
var getUltraStaticList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.ULTRA_STATIC_LIST, "?").concat(payload),
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getUltraStaticList = getUltraStaticList;
var submitUltraStatic = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ULTRA_STATIC,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                react_hot_toast_1.default.success(res.data.message, { duration: 3000 });
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message, { duration: 3000 });
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.submitUltraStatic = submitUltraStatic;
var getUltraStaticRoutes = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.ULTRA_STATIC, "?").concat(payload),
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message, { duration: 3000 });
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getUltraStaticRoutes = getUltraStaticRoutes;
var getUltraStaticTimeSlots = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.CENTER_SLOTS, "?").concat(payload),
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message, { duration: 3000 });
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getUltraStaticTimeSlots = getUltraStaticTimeSlots;
var ultraStaticUpdateList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .put({
            endPoint: api_service_1.api_service.api_urls.LIST_UPDATE,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.ultraStaticUpdateList = ultraStaticUpdateList;
var generateUltraStaticReport = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ULTRASTATIC_DOWNLOAD,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                react_hot_toast_1.default.success(res.data.message);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.generateUltraStaticReport = generateUltraStaticReport;
