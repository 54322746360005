"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.orcProfildeDetailsConvertedAsPerUI = exports.convertInitialValuedFormate = exports.newlyAddedData = exports.convertORCProfileAPIResponse = exports.convertORCListingAPIResponse = exports.ORC_CLIENT_ACTION_STATUS = exports.preparePayloadForWarehouseCodeByFe = exports.convertPrimaryClientData = exports.ocr_lables = exports.ORC_STATUS_TYPE = exports.initialValues = exports.associative_clinet_initial_values = exports.ORC_CLIENT_STATUS_OBJ = void 0;
var array_helpers_1 = require("../../../../utils/array_helpers");
exports.ORC_CLIENT_STATUS_OBJ = {
    PENDING_LEVEL_ONE: 0,
    LEVEL_ONE_REJECTED: 1,
    PENDING_LEVEL_TWO: 2,
    LEVEL_TWO_REJECTED: 3,
    LEVEL_TWO_APPROVED: 4,
    ACTIVE: 5,
    INACTIVE: 6,
    DELETED: 7
};
exports.associative_clinet_initial_values = [{
        associative_client: null,
        invoice_code: '',
        client_name: '',
        state: '',
        city: '',
        lab_code: '',
        lab_name: '',
        route_type: '',
        company_id: '',
        percentage_of_sales: '',
        compping_amount: '',
        inactive_reason: '',
        status: exports.ORC_CLIENT_STATUS_OBJ.ACTIVE
    }];
exports.initialValues = {
    primary_client: null,
    invoice_code: '',
    client_name: '',
    state: '',
    city: '',
    lab_code: '',
    lab_name: '',
    route_type: '',
    company_id: '',
    associative_clients: exports.associative_clinet_initial_values
};
exports.ORC_STATUS_TYPE = {
    Active: 'Active',
    PendingForApprovalOne: 'Pending level-1',
    PendingForApprovalTwo: 'Pending level-2',
    Deactivated: 'Deactivated',
    Rejected: "Rejected"
};
exports.ocr_lables = {
    Primary_Client: 'Primary Client',
    Associative_clients: 'Associative client',
    Invoice_Code: 'Invoice Code',
    Client_Name: 'Client Name',
    state: 'State',
    city: 'City',
    Labcode: 'Labcode',
    Lab_Name: 'Lab Name',
    Route_Type: 'Route Type',
    Compnay_ID: 'Compnay ID',
    Percentage_of_sales: 'Percentage of sales',
    Capping_Amount: 'Capping Amount',
    Status: 'Status',
    ReasonFor_Deactivate: 'Reason For Deactivate',
    PreviousStatus: 'Previouse Status',
    UpdatedStatus: 'Updated Status'
};
var convertPrimaryClientData = function (response) {
    var convertedData = [];
    if (response.length > 0) {
        response.map(function (val) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            var newObj = {
                id: (_a = val.id) !== null && _a !== void 0 ? _a : '',
                label: (_b = "".concat(val.warehouse_code, ", ").concat(val.center_name)) !== null && _b !== void 0 ? _b : '',
                value: (_c = val.warehouse_code) !== null && _c !== void 0 ? _c : '',
                invoice_code: (_d = val.invoice_code) !== null && _d !== void 0 ? _d : '',
                client_name: (_e = val.center_name) !== null && _e !== void 0 ? _e : '',
                state_name: (_f = val.center_state) !== null && _f !== void 0 ? _f : '',
                city_name: (_g = val.center_city) !== null && _g !== void 0 ? _g : '',
                lab_code: (_h = val.lab_code) !== null && _h !== void 0 ? _h : '',
                lab_name: (_j = val.lab_name) !== null && _j !== void 0 ? _j : '',
                route_type: (_k = val.route_type) !== null && _k !== void 0 ? _k : '',
                company_id: (_l = val.company_id) !== null && _l !== void 0 ? _l : ''
            };
            convertedData.push(newObj);
        });
    }
    return convertedData;
};
exports.convertPrimaryClientData = convertPrimaryClientData;
var preparePayloadForWarehouseCodeByFe = function (searchValue) {
    var payload;
    if (searchValue) {
        payload = {
            search_string: searchValue,
        };
    }
    return payload;
};
exports.preparePayloadForWarehouseCodeByFe = preparePayloadForWarehouseCodeByFe;
exports.ORC_CLIENT_ACTION_STATUS = {
    CREATE: 0,
    UPDATE: 1,
    DELETE: 2
};
var convertORCListingAPIResponse = function (response) {
    var convertedData = [];
    if (response.length > 0) {
        response.map(function (val) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            var newObj = {
                primary_client: (_a = val.warehouse_code) !== null && _a !== void 0 ? _a : '',
                invoice_code: (_b = val.invoice_code) !== null && _b !== void 0 ? _b : '',
                client_name: (_c = val.center_name) !== null && _c !== void 0 ? _c : '',
                state: (_d = val.center_state) !== null && _d !== void 0 ? _d : '',
                city: (_e = val.center_city) !== null && _e !== void 0 ? _e : '',
                status: (_f = val.status) !== null && _f !== void 0 ? _f : '',
                lab_code: (_g = val.lab_code) !== null && _g !== void 0 ? _g : '',
                lab_name: (_h = val.lab_name) !== null && _h !== void 0 ? _h : '',
                route_type: (_j = val.route_type) !== null && _j !== void 0 ? _j : '',
                company_id: (_k = val.data_area_id) !== null && _k !== void 0 ? _k : '',
                center_id: (_l = val.center_id) !== null && _l !== void 0 ? _l : '',
                id: (_m = val.id) !== null && _m !== void 0 ? _m : '',
                created_date_time: val.created_date_time ? (0, array_helpers_1.shipmentNormaldateandTimeFormatte)(val.created_date_time) : '',
                updated_date_time: val.updated_date_time ? (0, array_helpers_1.shipmentNormaldateandTimeFormatte)(val.updated_date_time) : '',
                rejected_comments: val.rejected_comments ? val.rejected_comments.toString().split('-')[0] : '',
                status_reason: val.status_reason ? val.status_reason.toString().split('-')[0] : ''
            };
            convertedData.push(newObj);
        });
    }
    return convertedData;
};
exports.convertORCListingAPIResponse = convertORCListingAPIResponse;
var convertORCProfileAPIResponse = function (response) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    var convertedData = {
        primary_center_data: {
            center_id: '',
            status: '',
            center_name: '',
            center_state: '',
            center_city: '',
            invoice_code: '',
            warehouse_code: '',
            lab_code: '',
            lab_name: '',
            route_type: '',
            data_area_id: '',
            status_reason: ''
        },
        associate_center_data: [],
        updated_data: {
            primary_center_data: {},
            associate_center_data: [],
        }
    };
    if (Object.keys(response).length > 0) {
        var primary_center_data = response.primary_center_data, associate_center_data = response.associate_center_data, updated_data = response.updated_data;
        if (Object.keys(primary_center_data).length > 0) {
            convertedData.primary_center_data = {
                center_id: (_a = primary_center_data.center_id) !== null && _a !== void 0 ? _a : '',
                status: (_b = primary_center_data.status) !== null && _b !== void 0 ? _b : '',
                center_name: (_c = primary_center_data.center_name) !== null && _c !== void 0 ? _c : '',
                center_state: (_d = primary_center_data.center_state) !== null && _d !== void 0 ? _d : '',
                center_city: (_e = primary_center_data.center_city) !== null && _e !== void 0 ? _e : '',
                invoice_code: (_f = primary_center_data.invoice_code) !== null && _f !== void 0 ? _f : '',
                warehouse_code: (_g = primary_center_data.warehouse_code) !== null && _g !== void 0 ? _g : '',
                lab_code: (_h = primary_center_data.lab_code) !== null && _h !== void 0 ? _h : '',
                lab_name: (_j = primary_center_data.lab_name) !== null && _j !== void 0 ? _j : '',
                route_type: (_k = primary_center_data.route_type) !== null && _k !== void 0 ? _k : '',
                data_area_id: (_l = primary_center_data.data_area_id) !== null && _l !== void 0 ? _l : ''
            };
        }
        if (associate_center_data.length > 0) {
            var convertedAssociate_center_data_1 = [];
            associate_center_data.map(function (val) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                var newObje = {
                    center_id: (_a = val.center_id) !== null && _a !== void 0 ? _a : '',
                    status: (_b = val.status) !== null && _b !== void 0 ? _b : '',
                    center_name: (_c = val.center_name) !== null && _c !== void 0 ? _c : '',
                    center_state: (_d = val.center_state) !== null && _d !== void 0 ? _d : '',
                    center_city: (_e = val.center_city) !== null && _e !== void 0 ? _e : '',
                    invoice_code: (_f = val.invoice_code) !== null && _f !== void 0 ? _f : '',
                    warehouse_code: (_g = val.warehouse_code) !== null && _g !== void 0 ? _g : '',
                    lab_code: (_h = val.lab_code) !== null && _h !== void 0 ? _h : '',
                    lab_name: (_j = val.lab_name) !== null && _j !== void 0 ? _j : '',
                    route_type: (_k = val.route_type) !== null && _k !== void 0 ? _k : '',
                    data_area_id: (_l = val.data_area_id) !== null && _l !== void 0 ? _l : '',
                    capping_amount: (_m = val.capping_amount) !== null && _m !== void 0 ? _m : '',
                    sales_percentage: (_o = val.sales_percentage) !== null && _o !== void 0 ? _o : '',
                    action: (_p = val.action) !== null && _p !== void 0 ? _p : ''
                };
                convertedAssociate_center_data_1.push(newObje);
            });
            convertedData.associate_center_data = convertedAssociate_center_data_1;
        }
        if (updated_data && updated_data.primary_center_data && Object.keys(updated_data.primary_center_data).length > 0) {
            convertedData.updated_data.primary_center_data = {
                center_id: (_m = updated_data.primary_center_data.center_id) !== null && _m !== void 0 ? _m : '',
                status: (_o = updated_data.primary_center_data.status) !== null && _o !== void 0 ? _o : '',
                center_name: (_p = updated_data.primary_center_data.center_name) !== null && _p !== void 0 ? _p : '',
                center_state: (_q = updated_data.primary_center_data.center_state) !== null && _q !== void 0 ? _q : '',
                center_city: (_r = updated_data.primary_center_data.center_city) !== null && _r !== void 0 ? _r : '',
                invoice_code: (_s = updated_data.primary_center_data.invoice_code) !== null && _s !== void 0 ? _s : '',
                warehouse_code: (_t = updated_data.primary_center_data.warehouse_code) !== null && _t !== void 0 ? _t : '',
                lab_code: (_u = updated_data.primary_center_data.lab_code) !== null && _u !== void 0 ? _u : '',
                lab_name: (_v = updated_data.primary_center_data.lab_name) !== null && _v !== void 0 ? _v : '',
                route_type: (_w = updated_data.primary_center_data.route_type) !== null && _w !== void 0 ? _w : '',
                data_area_id: (_x = updated_data.primary_center_data.data_area_id) !== null && _x !== void 0 ? _x : '',
                status_reason: (_y = updated_data.primary_center_data.status_reason) !== null && _y !== void 0 ? _y : ''
            };
        }
        if (updated_data && updated_data.associate_center_data && updated_data.associate_center_data.length > 0) {
            var convertedAssociate_center_data_2 = [];
            updated_data.associate_center_data.map(function (val) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                var newObje = {
                    center_id: (_a = val.center_id) !== null && _a !== void 0 ? _a : '',
                    status: (_b = val.status) !== null && _b !== void 0 ? _b : '',
                    center_name: (_c = val.center_name) !== null && _c !== void 0 ? _c : '',
                    center_state: (_d = val.center_state) !== null && _d !== void 0 ? _d : '',
                    center_city: (_e = val.center_city) !== null && _e !== void 0 ? _e : '',
                    invoice_code: (_f = val.invoice_code) !== null && _f !== void 0 ? _f : '',
                    warehouse_code: (_g = val.warehouse_code) !== null && _g !== void 0 ? _g : '',
                    lab_code: (_h = val.lab_code) !== null && _h !== void 0 ? _h : '',
                    lab_name: (_j = val.lab_name) !== null && _j !== void 0 ? _j : '',
                    route_type: (_k = val.route_type) !== null && _k !== void 0 ? _k : '',
                    data_area_id: (_l = val.data_area_id) !== null && _l !== void 0 ? _l : '',
                    capping_amount: (_m = val.capping_amount) !== null && _m !== void 0 ? _m : '',
                    sales_percentage: (_o = val.sales_percentage) !== null && _o !== void 0 ? _o : '',
                    action: (_p = val.action) !== null && _p !== void 0 ? _p : '',
                    status_reason: (_q = val.status_reason) !== null && _q !== void 0 ? _q : ''
                };
                convertedAssociate_center_data_2.push(newObje);
            });
            convertedData.updated_data.associate_center_data = convertedAssociate_center_data_2;
        }
    }
    return convertedData;
};
exports.convertORCProfileAPIResponse = convertORCProfileAPIResponse;
var newlyAddedData = function (response) {
    var newlyAddedData = [];
    if (Object.keys(response).length > 0) {
        var associate_center_data_1 = response.associate_center_data, updated_data = response.updated_data;
        if (associate_center_data_1.length > 0 && updated_data && updated_data.associate_center_data && updated_data.associate_center_data.length > 0) {
            var newlyAdded = updated_data.associate_center_data.filter(function (val) { return !associate_center_data_1.some(function (obj) { return val.warehouse_code == obj.warehouse_code; }); });
            newlyAddedData = newlyAdded;
        }
    }
    return newlyAddedData;
};
exports.newlyAddedData = newlyAddedData;
var convertInitialValuedFormate = function (response) {
    var inintialValue = {
        primary_client: null,
        invoice_code: '',
        client_name: '',
        state: '',
        city: '',
        lab_code: '',
        lab_name: '',
        route_type: '',
        company_id: '',
        associative_clients: [{
                associative_client: null,
                invoice_code: '',
                client_name: '',
                state: '',
                city: '',
                lab_code: '',
                lab_name: '',
                route_type: '',
                company_id: '',
                percentage_of_sales: '',
                compping_amount: '',
                status: exports.ORC_CLIENT_STATUS_OBJ.ACTIVE
            }]
    };
    if (Object.keys(response).length > 0) {
        var primary_center_data = response.primary_center_data, associate_center_data = response.associate_center_data;
        var clientData_1 = [];
        if (associate_center_data.length > 0) {
            associate_center_data.map(function (val) {
                var childDropdownObje = {
                    id: Number(val.center_id),
                    label: val.warehouse_code,
                    value: val.warehouse_code,
                    invoice_code: val.invoice_code,
                    client_name: val.center_name,
                    state_name: val.center_state,
                    city_name: val.center_city,
                    lab_code: val.lab_code,
                    lab_name: val.lab_name,
                    route_type: val.route_type,
                    company_id: val.data_area_id
                };
                var finalObj = {
                    associative_client: childDropdownObje,
                    invoice_code: val.invoice_code,
                    client_name: val.center_name,
                    state: val.center_state,
                    city: val.center_city,
                    lab_code: val.lab_code,
                    lab_name: val.lab_name,
                    route_type: val.route_type,
                    company_id: val.data_area_id,
                    percentage_of_sales: val.sales_percentage,
                    compping_amount: val.capping_amount,
                    status: Number(val.status)
                };
                clientData_1.push(finalObj);
            });
        }
        inintialValue = {
            primary_client: {
                id: Number(primary_center_data.center_id),
                label: "".concat(primary_center_data.warehouse_code, ", ").concat(primary_center_data.center_name),
                value: primary_center_data.warehouse_code,
                invoice_code: primary_center_data.invoice_code,
                client_name: primary_center_data.center_name,
                state_name: primary_center_data.center_state,
                city_name: primary_center_data.center_city,
                lab_code: primary_center_data.lab_code,
                lab_name: primary_center_data.lab_name,
                route_type: primary_center_data.route_type,
                company_id: primary_center_data.data_area_id,
            },
            invoice_code: primary_center_data.invoice_code,
            client_name: primary_center_data.center_name,
            state: primary_center_data.center_state,
            city: primary_center_data.center_city,
            lab_code: primary_center_data.lab_code,
            lab_name: primary_center_data.lab_name,
            route_type: primary_center_data.route_type,
            company_id: primary_center_data.data_area_id,
            associative_clients: clientData_1
        };
    }
    return inintialValue;
};
exports.convertInitialValuedFormate = convertInitialValuedFormate;
var orcProfildeDetailsConvertedAsPerUI = function (response) {
    var finalObj = {
        primary_center_data: {},
        updated_data: {
            primary_center_data: {},
        },
        sameAsPrevious: [],
        deleted: [],
        modified: [],
        newlyAdded: []
    };
    if (Object.keys(response).length > 0) {
        var primary_center_data = response.primary_center_data, associate_center_data_2 = response.associate_center_data, updated_data_1 = response.updated_data;
        if (Object.keys(primary_center_data).length > 0) {
            finalObj.primary_center_data = primary_center_data;
        }
        if (updated_data_1 && updated_data_1.primary_center_data && Object.keys(updated_data_1.primary_center_data).length > 0) {
            finalObj.updated_data.primary_center_data = updated_data_1.primary_center_data;
        }
        updated_data_1.associate_center_data.forEach(function (updatedItem) {
            var foundInPrevious = associate_center_data_2.find(function (prevItem) { return prevItem.warehouse_code == updatedItem.warehouse_code; });
            if (foundInPrevious) {
                if (updatedItem.action == exports.ORC_CLIENT_ACTION_STATUS.UPDATE.toString()) {
                    finalObj.modified.push({ previous: foundInPrevious, updated: updatedItem });
                }
                else {
                    if (updatedItem.action == exports.ORC_CLIENT_ACTION_STATUS.DELETE.toString()) {
                        finalObj.deleted.push(updatedItem);
                    }
                }
            }
            else {
                if (updatedItem.action == exports.ORC_CLIENT_ACTION_STATUS.CREATE.toString()) {
                    finalObj.newlyAdded.push(updatedItem);
                }
            }
        });
        if (updated_data_1.associate_center_data.length == 0) {
            finalObj.sameAsPrevious = associate_center_data_2;
        }
        else {
            var filteredData = associate_center_data_2.filter(function (val) { return !updated_data_1.associate_center_data.some(function (obj) { return obj.warehouse_code == val.warehouse_code; }); });
            finalObj.sameAsPrevious = filteredData;
        }
    }
    return finalObj;
};
exports.orcProfildeDetailsConvertedAsPerUI = orcProfildeDetailsConvertedAsPerUI;
