"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../actions/config_action");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var auto_routing_api_calls_1 = require("./auto_routing_api_calls");
var auto_routing_helper_1 = require("./auto_routing_helper");
var leavetracking_apicalls_1 = require("../../field_exicutive/leave_tracking/leavetracking.apicalls");
var TotalFEComponent = function (_a) {
    var totalFEFilter = _a.totalFEFilter, selectedLab = _a.selectedLab, PageLimit = _a.PageLimit, payloadexport = _a.payloadexport;
    var dispatch = (0, react_redux_1.useDispatch)();
    var nav = (0, react_router_1.useNavigate)();
    var routing_types_list = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.routing_assignment_type; });
    var _b = React.useState(undefined), totalFEList = _b[0], setTotalFEList = _b[1];
    var _c = React.useState(''), header = _c[0], setHeader = _c[1];
    var _d = React.useState(0), totalPageCount = _d[0], setTotalPageCount = _d[1];
    var _e = React.useState(1), pageCount = _e[0], setpageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var _g = React.useState(false), open = _g[0], setOpen = _g[1];
    var _h = React.useState(''), errorMessage = _h[0], setErrorMessage = _h[1];
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        nav("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var refetch = (0, react_query_1.useQuery)(['dynamic-clients-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        console.log(payloadexport, 'payloadexport');
        payloadexport['page'] = currentPage;
        payloadexport['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        console.log('21selectedLab', selectedLab);
        if (totalFEFilter === 6) {
            (0, auto_routing_api_calls_1.fetchLogoutFEList)(payloadexport)
                .then(function (response) {
                var pageSize = PageLimit
                    ? parseInt(PageLimit.value.toString())
                    : 50;
                var count = Math.ceil(response.count / pageSize);
                setTotalPageCount(count);
                setTotalCount(response.count);
                if (totalFEFilter === 6) {
                    var convertedData = (0, array_helpers_1.convertUnavailableFEListFromRes)(response.result);
                    setTotalFEList(convertedData);
                }
            })
                .catch(function (e) {
                setErrorMessage(e.message);
                setTotalFEList([]);
                react_hot_toast_1.default.error(e.message);
                console.log(e.message);
            });
        }
        else if (totalFEFilter === 7) {
            var routeType = routing_types_list.filter(function (routeType) { return routeType.label === "ULTRA_STATIC"; });
            var payload = {
                date_range: payloadexport.date_range,
                page: payloadexport.page,
                route_type: routeType[0].value,
                limit: payloadexport.size
            };
            (0, leavetracking_apicalls_1.fetchLeavesList)(payload)
                .then(function (response) {
                var pageSize = PageLimit
                    ? parseInt(PageLimit.value.toString())
                    : 50;
                var count = Math.ceil(response.data.count / pageSize);
                setErrorMessage('');
                setTotalPageCount(count);
                var convertedData = (0, array_helpers_1.convertLeaveListFromRes)(response.data);
                setTotalFEList(convertedData);
                setTotalCount(response.data.count);
            })
                .catch(function (err) {
            });
        }
        else {
            (0, auto_routing_api_calls_1.fetchTotalFEList)(payloadexport)
                .then(function (response) {
                console.log(response.result, '7yugh');
                var pageSize = PageLimit
                    ? parseInt(PageLimit.value.toString())
                    : 50;
                var count = Math.ceil(response.count / pageSize);
                setTotalPageCount(count);
                setTotalCount(response.count);
                if (totalFEFilter === 3) {
                    var convertedData = (0, array_helpers_1.convertUnavailableFEListFromRes)(response.result);
                    setTotalFEList(convertedData);
                }
                else {
                    setTotalFEList(response.result);
                }
            })
                .catch(function (e) {
                react_hot_toast_1.default.error(e.message);
                console.log(e.message);
            });
        }
        if (totalFEFilter === 1) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalFE);
        }
        else if (totalFEFilter === 2) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.availableFEAsperRoster);
        }
        else if (totalFEFilter === 6) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.loggedOutUsers);
        }
        else if (totalFEFilter === 4) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.availableDynamicFE);
        }
        else if (totalFEFilter === 7) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.LeavedUltrastaticFE);
        }
        else {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.availableStaticFE);
        }
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [totalFEFilter, PageLimit, selectedLab, payloadexport]);
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        payloadexport['page'] = currentPage;
        payloadexport['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.auto_routing_fe_list_generate,
            payLoad: payloadexport,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "mid-page-heaidng" },
            React.createElement("h2", { className: "F-18" }, "".concat(header)),
            totalFEFilter !== 7 &&
                React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler, color: "yellow-outline" })),
        React.createElement("div", { className: "unclickablerow custom-pagination-table control-tower-modal-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: totalFEFilter === 7 ? table_data_1.default.ULTRASTATIC_FE_LEAVE_COLUMNS : table_data_1.default.TOTAL_FE_COLUMNS, rows: totalFEList, pagination: false, errorMessage: errorMessage })),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: totalFEList === null || totalFEList === void 0 ? void 0 : totalFEList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = TotalFEComponent;
