"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ExpandMore_1 = require("@mui/icons-material/ExpandMore");
var Timeline_1 = require("@mui/lab/Timeline");
var TimelineConnector_1 = require("@mui/lab/TimelineConnector");
var TimelineContent_1 = require("@mui/lab/TimelineContent");
var TimelineDot_1 = require("@mui/lab/TimelineDot");
var TimelineItem_1 = require("@mui/lab/TimelineItem");
var TimelineSeparator_1 = require("@mui/lab/TimelineSeparator");
var material_1 = require("@mui/material");
var Drawer_1 = require("@mui/material/Drawer");
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var react_router_dom_1 = require("react-router-dom");
var config_action_1 = require("../../actions/config_action");
var api_service_1 = require("../../api/api_service");
var api_urls_1 = require("../../api/api_urls");
var button_component_1 = require("../../common/buttons/button_component");
var iconButton_component_1 = require("../../common/buttons/iconButton_component");
var profile_grid_1 = require("../../common/profile_grid/profile_grid");
var FECreationTabConstant_1 = require("../../constants/FECreationTabConstant");
var profile_detaile_lable_1 = require("../../constants/profile_detaile_lable");
var route_path_1 = require("../../constants/route_path");
var menu_constants_1 = require("../../menuList/menu_constants");
var array_helpers_1 = require("../../utils/array_helpers");
var masterBoxService_1 = require("./masterBoxService");
var MasterBoxDetailsScreen = function () {
    var _a = (0, react_router_1.useParams)(), bag_id = _a.bag_id, warehouse_code = _a.warehouse_code;
    var nav = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState(false), trackBagopen = _c[0], setTrackBagopen = _c[1];
    var _d = React.useState(null), masterBoxDetails = _d[0], setMasterBoxDetails = _d[1];
    var _e = React.useState(''), masterboxPhotoImageurl = _e[0], setMasterboxPhotoImageurl = _e[1];
    var _f = React.useState(''), masterboxPhotoUploadedImageurl = _f[0], setMasterboxPhotoUploadedImageurl = _f[1];
    var _g = React.useState(''), masterboxUploadedDocName = _g[0], setMasterboxUploadedDocName = _g[1];
    var _h = React.useState(''), labHandoverPhotoImageurl = _h[0], setLabHandoverPhotoImageurl = _h[1];
    var _j = React.useState(''), labHandoverUploadedImageurl = _j[0], setLabHandoverUploadedImageurl = _j[1];
    var _k = React.useState(''), labHandoverUploadedDocName = _k[0], setLabHandoverUploadedDocName = _k[1];
    var _l = React.useState(''), intercityHandoverPhoto = _l[0], setIntercityHandoverPhoto = _l[1];
    var _m = React.useState(''), intercityHandoverUploadedPhoto = _m[0], setIntercityHandoverUploadedPhoto = _m[1];
    var _o = React.useState(''), intercityHandoverUploadedDocName = _o[0], setIntercityHandoverUploadedDocName = _o[1];
    var _p = React.useState(''), intercityrecievingPhoto = _p[0], setIntercityrecievingPhoto = _p[1];
    var _q = React.useState(''), intercityRecievingUploadedPhoto = _q[0], setIntercityRecievingUploadedPhoto = _q[1];
    var _r = React.useState(''), intercityRecievingUploadedPhotoDocName = _r[0], setIntercityRecievingUploadedPhotoDocName = _r[1];
    var _s = React.useState([]), trackingDetails = _s[0], settrackingDetails = _s[1];
    var navgationToMasterBoxLisitng = function () {
        nav("/".concat(route_path_1.default.PATHS.masterBoxtracking));
    };
    console.log(masterBoxDetails, 'masterBoxDetails');
    var _t = (0, react_query_1.useQuery)(['masterbox-details', bag_id], function () {
        var payload = {};
        payload['master_box_id'] = bag_id;
        payload['warehouse_code'] = warehouse_code;
        (0, masterBoxService_1.fetchMasterBoxDetails)(api_service_1.api_service.api_urls.master_box_details, payload, process.env.SAMPLE_TRAKING_URL)
            .then(function (response) {
            var convertedMasterBoxDetails = (0, masterBoxService_1.convertMasterBoxDetailsFromRes)(response);
            setMasterBoxDetails(convertedMasterBoxDetails);
            var nameArr = response && response.master_box_image
                ? response.master_box_image.split('/')
                : [];
            setMasterboxPhotoImageurl(response === null || response === void 0 ? void 0 : response.master_box_image);
            setMasterboxPhotoUploadedImageurl(response === null || response === void 0 ? void 0 : response.master_box_image);
            setMasterboxUploadedDocName(nameArr[nameArr.length - 1]);
            var labHandoverImageNameArr = response && response.lab_handover_image
                ? response.lab_handover_image.split('/')
                : [];
            setLabHandoverPhotoImageurl(response === null || response === void 0 ? void 0 : response.lab_handover_image);
            setLabHandoverUploadedImageurl(response === null || response === void 0 ? void 0 : response.lab_handover_image);
            setLabHandoverUploadedDocName(labHandoverImageNameArr[nameArr.length - 1]);
            var intercityHandoverPhotoNameArr = response && response.intercity_handover_image
                ? response.intercity_handover_image.split('/')
                : [];
            setIntercityHandoverPhoto(response === null || response === void 0 ? void 0 : response.intercity_handover_image);
            setIntercityHandoverUploadedPhoto(response === null || response === void 0 ? void 0 : response.intercity_handover_image);
            setIntercityHandoverUploadedDocName(intercityHandoverPhotoNameArr[intercityHandoverPhotoNameArr.length - 1]);
            var intercityrecievingPhotoNameArr = response && response.intercity_pickup_image
                ? response.intercity_pickup_image.split('/')
                : [];
            setIntercityrecievingPhoto(response === null || response === void 0 ? void 0 : response.intercity_pickup_image);
            setIntercityRecievingUploadedPhoto(response === null || response === void 0 ? void 0 : response.intercity_pickup_image);
            setIntercityRecievingUploadedPhotoDocName(intercityrecievingPhotoNameArr[intercityrecievingPhotoNameArr.length - 1]);
        })
            .catch(function (err) {
            console.log('error in the error', err);
            setMasterBoxDetails(null);
        });
    });
    var handleDrawerClose = function () {
        setOpen(false);
    };
    var navigateToBasicDetails = function (bagId) {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: 'Operations Management',
            subModule: menu_constants_1.FEATURE_CONSTANTS.sampleTracking,
            subChaildModule: '',
        }));
        nav("/".concat(route_path_1.default.PATHS.masterbox_basicDeatils, "/").concat(route_path_1.default.PATHS.sampleTraking_basicDetails, "/").concat(bagId));
    };
    var TrackingBagDetails = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "drawer-content drawer-track-bag" },
                React.createElement("div", { className: "drawer-header" },
                    React.createElement("div", { className: "drawer-heading-wrapper" },
                        React.createElement("h2", { className: "traking-details-heading" },
                            ' ',
                            profile_detaile_lable_1.default.SAMPLE_BAG_HEADINGS[6]),
                        React.createElement("small", { className: "sub-heading" },
                            "Masterbox ID:", "".concat((_a = (masterBoxDetails && masterBoxDetails.master_box_id)) !== null && _a !== void 0 ? _a : FECreationTabConstant_1.emptyDetails))),
                    React.createElement("div", { onClick: handleDrawerClose, className: "drawer-close-btn" },
                        React.createElement("span", { className: "icon-close" }))),
                React.createElement("div", { className: "stepper-container timeline-wrapper" }, trackingDetails &&
                    (trackingDetails === null || trackingDetails === void 0 ? void 0 : trackingDetails.map(function (trackInfo) {
                        var _a, _b, _c, _d;
                        var transportationDetails = trackInfo.transport_details && trackInfo.transport_details;
                        console.log(transportationDetails, 'transportationDetails');
                        return (React.createElement(Timeline_1.default, { position: "right" },
                            React.createElement(React.Fragment, null,
                                React.createElement(TimelineItem_1.default, null,
                                    React.createElement(TimelineSeparator_1.default, null,
                                        React.createElement(TimelineDot_1.default, { className: "step-icon-wrapper " },
                                            React.createElement("span", { className: trackInfo.event_type
                                                    ? trackInfo.event_type == masterBoxService_1.MASTER_BOX_EVENT_TYPES.Created
                                                        ? 'icon-menu-sheet step-icon'
                                                        : trackInfo.event_type == masterBoxService_1.MASTER_BOX_EVENT_TYPES.In_transit
                                                            ? 'icon-sethoscope step-icon'
                                                            : trackInfo.event_type ==
                                                                masterBoxService_1.MASTER_BOX_EVENT_TYPES.Picked_up_at_destination
                                                                ? 'icon-Group-1 step-icon'
                                                                : 'icon-Vector-25 step-icon'
                                                    : '' })),
                                        React.createElement(TimelineConnector_1.default, null)),
                                    React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                        React.createElement("div", { className: "step-content" },
                                            React.createElement("p", { className: "step-label" }, trackInfo.event_type
                                                ? trackInfo.event_type == masterBoxService_1.MASTER_BOX_EVENT_TYPES.Created
                                                    ? masterBoxService_1.MASTER_BOX_EVENT_NAMES.Created
                                                    : trackInfo.event_type == masterBoxService_1.MASTER_BOX_EVENT_TYPES.In_transit
                                                        ? masterBoxService_1.MASTER_BOX_EVENT_NAMES.In_transit
                                                        : trackInfo.event_type ==
                                                            masterBoxService_1.MASTER_BOX_EVENT_TYPES.Picked_up_at_destination
                                                            ? masterBoxService_1.MASTER_BOX_EVENT_NAMES.Picked_up_at_destination
                                                            : masterBoxService_1.MASTER_BOX_EVENT_NAMES.Handed_over_at_lab
                                                : FECreationTabConstant_1.emptyDetails),
                                            React.createElement("div", { className: "step-details-wrapper" },
                                                React.createElement("p", { className: "step-desc" }, trackInfo.event_type
                                                    ? trackInfo.event_type == masterBoxService_1.MASTER_BOX_EVENT_TYPES.Created
                                                        ? 'Masterbox Created'
                                                        : "Masterbox handover to ".concat(trackInfo.to_user_type
                                                            ? trackInfo.to_user_type
                                                            : FECreationTabConstant_1.emptyDetails)
                                                    : FECreationTabConstant_1.emptyDetails),
                                                React.createElement("p", { className: "step-time-stamp" }, trackInfo.timestamp
                                                    ? (0, array_helpers_1.normaldateandTimeFormatte)(trackInfo.timestamp)
                                                    : FECreationTabConstant_1.emptyDetails),
                                                (trackInfo.event_type &&
                                                    trackInfo.event_type ==
                                                        masterBoxService_1.MASTER_BOX_EVENT_TYPES.Handed_over_at_lab) ||
                                                    trackInfo.event_type ==
                                                        masterBoxService_1.MASTER_BOX_EVENT_TYPES.Picked_up_at_destination ? (React.createElement(React.Fragment, null,
                                                    React.createElement("p", { className: "step-desc" }, "Masterbox Received By ".concat(trackInfo.from_user_type
                                                        ? trackInfo.from_user_type
                                                        : FECreationTabConstant_1.emptyDetails)),
                                                    React.createElement("p", { className: "step-time-stamp" }, trackInfo.timestamp
                                                        ? (0, array_helpers_1.normaldateandTimeFormatte)(trackInfo.timestamp)
                                                        : FECreationTabConstant_1.emptyDetails))) : null,
                                                trackInfo.event_type &&
                                                    trackInfo.event_type == masterBoxService_1.MASTER_BOX_EVENT_TYPES.In_transit &&
                                                    trackInfo.event_type.transport_details ? (React.createElement("div", null,
                                                    React.createElement("h3", { className: "transportation_heading" }, "Transportation Details"),
                                                    React.createElement("div", { className: "master-box-accordian" },
                                                        React.createElement(material_1.Accordion, { disableGutters: true, elevation: 0 },
                                                            React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null), "aria-controls": "panel1a-content", id: "panel1a-header" },
                                                                React.createElement(material_1.Typography, { className: "accordian-heading" }, "Mode of Transportation"),
                                                                React.createElement(material_1.Typography, { className: "accordian-info" }, transportationDetails &&
                                                                    transportationDetails.transport_mode
                                                                    ? transportationDetails.transport_mode
                                                                    : FECreationTabConstant_1.emptyDetails)),
                                                            React.createElement(material_1.AccordionDetails, null,
                                                                React.createElement(material_1.Stack, { direction: "row" },
                                                                    React.createElement(Grid_1.default, { container: true, rowGap: 2 },
                                                                        React.createElement(Grid_1.default, { sm: 6 },
                                                                            React.createElement("p", { className: "user-label" }, transportationDetails.transport_mode ==
                                                                                masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Bus
                                                                                ? 'Bus No'
                                                                                : transportationDetails.transport_mode ==
                                                                                    masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Train
                                                                                    ? 'Train No'
                                                                                    : transportationDetails.transport_mode ==
                                                                                        masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Air
                                                                                        ? 'Name Of Airlines'
                                                                                        : transportationDetails.transport_mode ==
                                                                                            masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Courier
                                                                                            ? 'Name of Courier'
                                                                                            : transportationDetails.transport_mode ==
                                                                                                masterBoxService_1.MASTERBOX_TRANSPORT_MODES.OBC_OTC
                                                                                                ? 'OBC/OTC No'
                                                                                                : 'Name Of The Contact Person'),
                                                                            React.createElement("p", { className: "user-value" },
                                                                                transportationDetails &&
                                                                                    transportationDetails.transport_number
                                                                                    ? transportationDetails.transport_number
                                                                                    : FECreationTabConstant_1.emptyDetails,
                                                                                ' ')),
                                                                        React.createElement(Grid_1.default, { sm: 6 },
                                                                            React.createElement("p", { className: "user-label" }, transportationDetails.transport_mode ==
                                                                                masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Bus
                                                                                ? 'Bus Name'
                                                                                : transportationDetails.transport_mode ==
                                                                                    masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Train
                                                                                    ? 'Train Name'
                                                                                    : transportationDetails.transport_mode ==
                                                                                        masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Air
                                                                                        ? 'Waybill Number'
                                                                                        : transportationDetails.transport_mode ==
                                                                                            masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Courier
                                                                                            ? 'Name Of Courier'
                                                                                            : transportationDetails.transport_mode ==
                                                                                                masterBoxService_1.MASTERBOX_TRANSPORT_MODES.OBC_OTC
                                                                                                ? 'OBC/OTC ID'
                                                                                                : 'Mobile No Of The Contact Person'),
                                                                            React.createElement("p", { className: "user-value" },
                                                                                transportationDetails &&
                                                                                    transportationDetails.transport_name
                                                                                    ? transportationDetails.transport_name
                                                                                    : FECreationTabConstant_1.emptyDetails,
                                                                                ' ')),
                                                                        transportationDetails.transport_mode ==
                                                                            masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Bus ? (React.createElement(Grid_1.default, { sm: 6 },
                                                                            React.createElement("p", { className: "user-label" }, "Conductor Mobile No."),
                                                                            React.createElement("p", { className: "user-value" },
                                                                                transportationDetails &&
                                                                                    transportationDetails.transport_mobile
                                                                                    ? transportationDetails.transport_mobile
                                                                                    : FECreationTabConstant_1.emptyDetails,
                                                                                ' '))) : null,
                                                                        transportationDetails.transport_mode ==
                                                                            masterBoxService_1.MASTERBOX_TRANSPORT_MODES.Bus ? (React.createElement(Grid_1.default, { sm: 6 },
                                                                            React.createElement("p", { className: "user-label" }, "Conductor Name"),
                                                                            React.createElement("p", { className: "user-value" },
                                                                                transportationDetails &&
                                                                                    transportationDetails.vendor_name
                                                                                    ? transportationDetails.vendor_name
                                                                                    : FECreationTabConstant_1.emptyDetails,
                                                                                ' '))) : null),
                                                                    React.createElement(material_1.Box, { pl: 2 },
                                                                        React.createElement("p", { className: "user-label" }, "Masterbox Image "),
                                                                        transportationDetails &&
                                                                            transportationDetails.box_images &&
                                                                            ((_a = transportationDetails === null || transportationDetails === void 0 ? void 0 : transportationDetails.box_images[0]) === null || _a === void 0 ? void 0 : _a.url) ? (React.createElement("div", { className: "img-block" },
                                                                            React.createElement("img", { src: transportationDetails &&
                                                                                    transportationDetails.box_images &&
                                                                                    ((_b = transportationDetails === null || transportationDetails === void 0 ? void 0 : transportationDetails.box_images[0]) === null || _b === void 0 ? void 0 : _b.url), alt: "" }),
                                                                            React.createElement("div", { className: "download-btn" },
                                                                                React.createElement("a", { className: "icon-Vector", target: "_blank", href: transportationDetails &&
                                                                                        transportationDetails.box_images &&
                                                                                        ((_c = transportationDetails === null || transportationDetails === void 0 ? void 0 : transportationDetails.box_images[0]) === null || _c === void 0 ? void 0 : _c.url), download: true })))) : (React.createElement("p", { className: "master-journey" }, "No pic uploaded")))),
                                                                React.createElement(Grid_1.default, { sm: 6 },
                                                                    React.createElement("p", { className: "user-label" }, "No.of Masterboxes"),
                                                                    React.createElement("p", { className: "user-value" },
                                                                        transportationDetails &&
                                                                            transportationDetails.no_of_boxes
                                                                            ? transportationDetails.no_of_boxes
                                                                            : FECreationTabConstant_1.emptyDetails,
                                                                        ' ')),
                                                                React.createElement(material_1.Stack, { direction: "row" },
                                                                    React.createElement(Grid_1.default, { container: true, rowGap: 2 },
                                                                        React.createElement(Grid_1.default, { sm: 6 },
                                                                            React.createElement("p", { className: "user-label" }, "Date and Time of Arrival"),
                                                                            React.createElement("p", { className: "user-value" },
                                                                                transportationDetails &&
                                                                                    transportationDetails.date_of_arrival
                                                                                    ? (0, array_helpers_1.normaldateandTimeFormatte)(transportationDetails.date_of_arrival)
                                                                                    : FECreationTabConstant_1.emptyDetails,
                                                                                ' ')),
                                                                        React.createElement(Grid_1.default, { sm: 6 },
                                                                            React.createElement("p", { className: "user-label" }, "Destination Contact Name"),
                                                                            React.createElement("p", { className: "user-value" },
                                                                                transportationDetails &&
                                                                                    transportationDetails.destination_contact_name
                                                                                    ? transportationDetails.destination_contact_name
                                                                                    : FECreationTabConstant_1.emptyDetails,
                                                                                ' ')),
                                                                        React.createElement(Grid_1.default, { sm: 6 },
                                                                            React.createElement("p", { className: "user-label" }, "Destination Contact No"),
                                                                            React.createElement("p", { className: "user-value" },
                                                                                transportationDetails &&
                                                                                    transportationDetails.destination_contact_number
                                                                                    ? transportationDetails.destination_contact_number
                                                                                    : FECreationTabConstant_1.emptyDetails,
                                                                                ' '))),
                                                                    React.createElement(material_1.Box, { pl: 2 },
                                                                        React.createElement("p", { className: "user-label" }, "Waybill Image "),
                                                                        transportationDetails &&
                                                                            transportationDetails.transport_images &&
                                                                            ((_d = transportationDetails === null || transportationDetails === void 0 ? void 0 : transportationDetails.transport_images[0]) === null || _d === void 0 ? void 0 : _d.url) ? (React.createElement("div", { className: "img-block" },
                                                                            React.createElement("img", { src: transportationDetails &&
                                                                                    transportationDetails.transport_images &&
                                                                                    transportationDetails.transport_images[0].url, alt: "" }),
                                                                            React.createElement("div", { className: "download-btn" },
                                                                                React.createElement("a", { className: "icon-Vector", target: "_blank", href: transportationDetails &&
                                                                                        transportationDetails.transport_images &&
                                                                                        transportationDetails.transport_images[0].url, download: true })),
                                                                            ' ')) : (React.createElement("p", { className: "master-journey" }, "No pic uploaded"))))))))) : null)))))));
                    }))))));
    };
    var BasicInformation = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "sample-details-wrapper small-overflow-hide" },
                React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 } },
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                            React.createElement("h2", null,
                                " ",
                                profile_detaile_lable_1.default.MASTERBOXDETAILS_HEADING[0]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.masterBoXID, value: (_a = (masterBoxDetails && masterBoxDetails.master_box_id)) !== null && _a !== void 0 ? _a : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.currentStatus, value: (_b = (masterBoxDetails && masterBoxDetails.status)) !== null && _b !== void 0 ? _b : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                            React.createElement("h2", null,
                                " ",
                                profile_detaile_lable_1.default.MASTERBOXDETAILS_HEADING[1]))),
                    React.createElement(Grid_1.default, { item: true, xs: 6 },
                        React.createElement("div", { className: "user-profile-wrapper" },
                            React.createElement("small", { className: "user-label" }, " Master Box Photo"),
                            React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 10, lg: 10 } },
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" }, masterboxPhotoImageurl ? (React.createElement(React.Fragment, null,
                                    React.createElement("img", { src: masterBoxDetails === null || masterBoxDetails === void 0 ? void 0 : masterBoxDetails.master_box_image, alt: "profile" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: masterboxPhotoUploadedImageurl, download: true })),
                                    React.createElement("div", { className: "image-title" }, masterboxUploadedDocName))) : ('No pic uploaded'))))),
                    React.createElement(Grid_1.default, { item: true, xs: 6 },
                        React.createElement("div", { className: "user-profile-wrapper" },
                            React.createElement("small", { className: "user-label" }, " Lab Handover Photo"),
                            React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 10, lg: 10 } },
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" }, labHandoverPhotoImageurl ? (React.createElement(React.Fragment, null,
                                    React.createElement("img", { src: masterBoxDetails === null || masterBoxDetails === void 0 ? void 0 : masterBoxDetails.lab_handover_image, alt: "profile" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: labHandoverUploadedImageurl, download: true })),
                                    React.createElement("div", { className: "image-title" }, labHandoverUploadedDocName))) : ('No pic uploaded'))))),
                    React.createElement(Grid_1.default, { item: true, xs: 6 },
                        React.createElement("div", { className: "user-profile-wrapper" },
                            React.createElement("small", { className: "user-label" }, " Intercity Handover Photo"),
                            React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 10, lg: 10 } },
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" }, intercityHandoverPhoto ? (React.createElement(React.Fragment, null,
                                    React.createElement("img", { src: masterBoxDetails === null || masterBoxDetails === void 0 ? void 0 : masterBoxDetails.intercity_handover_image, alt: "profile" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: intercityHandoverUploadedPhoto, download: true })),
                                    React.createElement("div", { className: "image-title" }, intercityHandoverUploadedDocName))) : ('No pic uploaded'))))),
                    React.createElement(Grid_1.default, { item: true, xs: 6 },
                        React.createElement("div", { className: "user-profile-wrapper" },
                            React.createElement("small", { className: "user-label" }, " Intercity Receiving Photo"),
                            React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 10, lg: 10 } },
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" }, intercityrecievingPhoto ? (React.createElement(React.Fragment, null,
                                    React.createElement("img", { src: masterBoxDetails === null || masterBoxDetails === void 0 ? void 0 : masterBoxDetails.intercity_pickup_image, alt: "profile" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: intercityRecievingUploadedPhoto, download: true })),
                                    React.createElement("div", { className: "image-title" }, intercityRecievingUploadedPhotoDocName))) : ('No pic uploaded'))))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.bagNoCount, value: (_c = (masterBoxDetails && masterBoxDetails.bags_count)) !== null && _c !== void 0 ? _c : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.boxCreationTime, value: (_d = (masterBoxDetails &&
                            (0, array_helpers_1.shipmentNormaldateandTimeFormatte)(masterBoxDetails.box_cerated_date_time))) !== null && _d !== void 0 ? _d : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 18 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, "Other Information Of The Masterbox"))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.labCode, value: (_e = (masterBoxDetails && masterBoxDetails.lab_code)) !== null && _e !== void 0 ? _e : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.labName, value: (_f = (masterBoxDetails && masterBoxDetails.lab_name)) !== null && _f !== void 0 ? _f : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.labState, value: (_g = (masterBoxDetails && masterBoxDetails.lab_state)) !== null && _g !== void 0 ? _g : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.labCity, value: (_h = (masterBoxDetails && masterBoxDetails.lab_city)) !== null && _h !== void 0 ? _h : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.pickupFECode, value: (_j = (masterBoxDetails && masterBoxDetails.pickup_fe_code)) !== null && _j !== void 0 ? _j : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.feName, value: (_k = (masterBoxDetails && masterBoxDetails.fe_name)) !== null && _k !== void 0 ? _k : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.feState, value: masterBoxDetails && masterBoxDetails.fe_state
                            ? masterBoxDetails.fe_state
                            : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.feCity, value: masterBoxDetails && masterBoxDetails.fe_city
                            ? masterBoxDetails.fe_city
                            : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.ccState, value: (_l = (masterBoxDetails && masterBoxDetails.cc_state)) !== null && _l !== void 0 ? _l : FECreationTabConstant_1.emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.MASTERBOX_DETAILS.ccCity, value: (_m = (masterBoxDetails && masterBoxDetails.cc_city)) !== null && _m !== void 0 ? _m : FECreationTabConstant_1.emptyDetails })))));
    };
    var SamplesTracking = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "sample-details-wrapper accordian-container small-overflow-hide" },
                React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 } },
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                            React.createElement("h2", null,
                                " ",
                                profile_detaile_lable_1.default.MASTERBOXDETAILS_HEADING[2])))),
                masterBoxDetails &&
                    masterBoxDetails.bags.length > 0 &&
                    masterBoxDetails.bags.map(function (bagDetails) {
                        var _a, _b, _c;
                        return (React.createElement(material_1.Accordion, { className: "custom-accordian" },
                            React.createElement("div", { className: "accordion-tab-wrapper " },
                                React.createElement("div", { className: "accordian-summary" },
                                    React.createElement("div", { className: "label-values-wrapper" },
                                        React.createElement("small", { className: "item-label" }, "Bag ID"),
                                        React.createElement("p", { className: "item-value" }, (_a = bagDetails.barcode_id) !== null && _a !== void 0 ? _a : FECreationTabConstant_1.emptyDetails)),
                                    React.createElement("div", { className: "label-values-wrapper" },
                                        React.createElement("small", { className: "item-label" }, "Center Code"),
                                        React.createElement("p", { className: "item-value blue-text" }, (_b = bagDetails.warehouse_code) !== null && _b !== void 0 ? _b : FECreationTabConstant_1.emptyDetails)),
                                    React.createElement("div", { className: "label-values-wrapper" },
                                        React.createElement("small", { className: "item-label" }, "Center Name"),
                                        React.createElement("p", { className: "item-value" }, bagDetails.center_name ? bagDetails.center_name : FECreationTabConstant_1.emptyDetails))),
                                React.createElement("div", { className: "accordion-item-details" },
                                    React.createElement("div", { className: "bag-details-wrapper" },
                                        React.createElement("p", { className: "item-status" },
                                            "Current Status :",
                                            ' ',
                                            React.createElement("span", null, "".concat((_c = bagDetails.status) !== null && _c !== void 0 ? _c : FECreationTabConstant_1.emptyDetails)),
                                            ' '),
                                        React.createElement("small", { className: "item-details" },
                                            "Bag Handed Over To",
                                            ' ', "".concat(bagDetails.bag_handover_to_name
                                            ? bagDetails.bag_handover_to_name
                                            : FECreationTabConstant_1.emptyDetails)),
                                        React.createElement("small", { className: "item-time-stamp" }, bagDetails.created_date_time
                                            ? (0, array_helpers_1.shipmentNormaldateandTimeFormatte)(bagDetails.created_date_time)
                                            : FECreationTabConstant_1.emptyDetails)),
                                    React.createElement("div", { className: "track-link" },
                                        React.createElement(button_component_1.default, { onClickHandler: function () {
                                                return navigateToBasicDetails(bagDetails.barcode_id);
                                            }, buttonName: "Track", endIcon: "icon-right", color: "transparent btn-icon-right", isError: false }))))));
                    }))));
    };
    var trackingBaghandleDrawerOpen = function () {
        setTrackBagopen(true);
        setOpen(true);
        (0, masterBoxService_1.fetchMastersTrackingDetails)("".concat(api_urls_1.api_urls.master_box_journey), {
            master_box_id: bag_id,
        }, process.env.SAMPLE_TRAKING_URL)
            .then(function (response) {
            settrackingDetails(response);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
            settrackingDetails([]);
        });
    };
    return (React.createElement("div", { className: "page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navgationToMasterBoxLisitng }),
                React.createElement("h2", null, "Masterbox Details")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Track Masterbox", onClickHandler: trackingBaghandleDrawerOpen, color: "yellow-outline", isError: false }))),
        React.createElement("div", { className: "sample-tracking-wrapper scroll-xs" },
            React.createElement(Grid_1.default, { container: true, xs: 12, className: "details-tab-wrapper" },
                React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 }, React.createElement(BasicInformation, null)),
                React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 }, React.createElement(SamplesTracking, null)),
                React.createElement("div", { className: "drawer-container" },
                    React.createElement(Drawer_1.default, { variant: "temporary", anchor: "right", open: open, className: "track-bag-container" }, !trackBagopen ? React.createElement(SamplesTracking, null) : React.createElement(TrackingBagDetails, null)))))));
};
exports.default = MasterBoxDetailsScreen;
