"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var config_action_1 = require("../../../../actions/config_action");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../reports_helper");
var FEAttendanceReport = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = React.useState(undefined), dataList = _a[0], setDataList = _a[1];
    var _b = React.useState(''), filterFromDate = _b[0], setFromDateFilter = _b[1];
    var _c = React.useState(''), filterToDate = _c[0], setToDateFilter = _c[1];
    var _d = React.useState(false), isDateRangeUpdated = _d[0], setIsDateRangeUpdated = _d[1];
    var _e = React.useState(''), searchTerm = _e[0], setSearchTerm = _e[1];
    var _f = React.useState(''), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = React.useState(1), pageCount = _g[0], setpageCount = _g[1];
    var _h = React.useState(0), totalPageCount = _h[0], setTotalPageCount = _h[1];
    var _j = React.useState(appConstants_1.defaultPageLimit), PageLimit = _j[0], setPageLimit = _j[1];
    var _k = React.useState(0), totalCount = _k[0], setTotalCount = _k[1];
    var _l = React.useState(false), open = _l[0], setOpen = _l[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var searchFe = function (event) {
        setSearchTerm(event.target.value.trim());
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var refetch = (0, react_query_1.useQuery)(['Fe-Attendance', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        console.log('datedf0', filterFromDate);
        if (filterFromDate && filterToDate !== null) {
            console.log('datedf', filterFromDate);
            payload['date_range'] = {
                start_date: filterFromDate,
                end_date: filterToDate,
            };
        }
        if (searchTerm) {
            payload['search_string'] = searchTerm;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, reports_helper_1.FeAttendanceReports)(payload)
            .then(function (response) {
            console.log('fjhsj', response);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setErrorMessage('');
            setTotalCount(response.count);
            var covertedData = (0, array_helpers_1.convertFeAttendanceReportListFromRes)(response === null || response === void 0 ? void 0 : response.result);
            setDataList(covertedData);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setDataList([]);
            react_hot_toast_1.default.error(err);
        });
    }).refetch;
    var autogenerateReport = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        console.log('datedf0', filterFromDate);
        if (filterFromDate && filterToDate !== null) {
            console.log('datedf', filterFromDate);
            payload['date_range'] = {
                start_date: filterFromDate,
                end_date: filterToDate,
            };
        }
        if (searchTerm) {
            payload['search_string'] = searchTerm;
        }
        payload['page'] = currentPage;
        payload['size'] = 100;
        (0, reports_helper_1.FeAttendanceReportsGenerate)(payload)
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    return (React.createElement("div", { className: "fe-wrapper pay  -fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "FE Attendance Report")),
            React.createElement(button_component_1.default, { type: "button", buttonName: appConstants_1.reportDownload, endIcon: "icon-import download-icon", color: "yellow-outline", onClickHandler: autogenerateReport, isError: (dataList === null || dataList === void 0 ? void 0 : dataList.length) <= 0 })),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" },
                React.createElement(search_component_1.default, { placeholder: "Search FE and Mobile", searchValue: searchTerm, searchHandler: searchFe })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(text_filed_with_fromto_Daterange_1.default, { presentDate: true, onChangeHandler: callBackOnDateRangeSelect }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit, isDisabled: true }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.FE_ATTENDANCE_REPORT_COLUMNS, rows: dataList, errorMessage: errorMessage, pagination: false, className: 'cash-pending-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = FEAttendanceReport;
