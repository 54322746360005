"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var dashboard_1 = require("../components/dashboard/dashboard");
var LoginComponent_1 = require("../components/login/LoginComponent");
var Shipmentdashboard_1 = require("../components/ShipmentTower/Shipmentdashboard");
var assign_active_clients_1 = require("../components/super_admin/control_dashboard/assign_active_clients");
var appConstants_1 = require("../constants/appConstants");
var route_path_1 = require("../constants/route_path");
var Pagenotfound_1 = require("../ErrorBoundary/Pagenotfound");
var changePassword_1 = require("../menuList/changePassword");
var menu_constants_1 = require("../menuList/menu_constants");
var userProfile_1 = require("../menuList/userProfile");
var array_helpers_1 = require("../utils/array_helpers");
var routes_list_1 = require("./routes_list");
var PartialVisits_1 = require("../components/super_admin/Visits/PartialVisits");
var MainRoutes = function () {
    var features = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.features; });
    var is_sso_user = (0, react_redux_1.useSelector)(function (state) { return state.user; }).is_sso_user;
    var _a = React.useState(false), isListReloaded = _a[0], setIsListReloaded = _a[1];
    var filteredRouteData = (0, array_helpers_1.filterFeatures)(routes_list_1.ROUTE_LIST, features);
    var feManagementRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.feManagement, routes_list_1.FE_MANGEMENT_BUTTON_FEATURE_ROUTES);
    var rostorRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.rosterManagement, routes_list_1.ROUTESR_MANAGEMNT_ROUTES);
    var roleManagementRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.roleManagement, routes_list_1.ROLE_MANAGEMNT_ROUTES);
    var vendorManagementRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.vendorManagement, routes_list_1.VENDOR_MANGEMENT_BUTTON_FEATURE_ROUTES);
    var feApprovalManagementRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.approvals, routes_list_1.FE_APPROVAL_BUTTON_FEATURES_ROUTES);
    var VendorApprovalManagementRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.approvals, routes_list_1.VENDOR_APPROVAL_BUTTON_FEATURES_ROUTES);
    var auditManagementRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.audit, routes_list_1.AUDIT_BUTTONS_ROUTES);
    var sampleTrackngRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.sampleTracking, routes_list_1.SAMPLE_TRACKING_BUTTON_ROUES);
    var notificationRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.notificationManagement, routes_list_1.NOTIFICATIONS_BUTTON_ROUES);
    var etrfRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.ETRFDocuments, routes_list_1.ETRF_BUTTON_ROUES);
    var preciousSampleRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.preciousSample, routes_list_1.PRICIOUS_BUTTON_ROUES);
    var visitManagementRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.visitManagement, routes_list_1.VISITS_BUTTON_ROUES);
    var controlTowerRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.controlTower, routes_list_1.CONTROL_TOWER_BUTTTON_ROUES);
    var reportsRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.reports, routes_list_1.REPORTS_BUTTTON_ROUES);
    var my_reportsRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.sub_my_reports, routes_list_1.MY_REPORTS_ROUES);
    var cc_rosterRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.CC_Roster_Management, routes_list_1.CC_ROUSTER_BUTTONS);
    var routePlannersRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.Route_Planner, routes_list_1.ROUTE_PLANNER_ROUTES);
    var user_logistic = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.Admin_User_Management, routes_list_1.USER_LOGISTIC_ROUTES);
    var labMasterRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.Lab_Roster_Management, routes_list_1.LAB_MASTER_ROUTES);
    var hubMasterRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.HUB_Roster_Management, routes_list_1.HUB_MASTER_ROUTES);
    var masterBoxTrakingRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.master_box_tracking, routes_list_1.MASTERBOX_TRACKING_ROUTES);
    var RegionRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.Region_Management, routes_list_1.REGION_BUTTON_ROUES);
    var SSO_MY_REPORTS_ROTUES = (0, array_helpers_1.getAccessibleRoutesForSSOReports)(menu_constants_1.FEATURE_CONSTANTS.sub_my_reports, routes_list_1.SSO_MY_REPORTS_ROUTES, is_sso_user);
    var intracityReportstRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.intracity, routes_list_1.INTRACITY_GRAPH_REPORT_ROUTES);
    var intracityReportsButtonstRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.intracity, routes_list_1.INTRACITY_TAT_GRAPH_REPORT_BUTTON_ROUTES);
    var intercityReportstRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.intercity, routes_list_1.INTERCITY_GRAPH_REPORT_ROUTES);
    var shipmentTrakingRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.shipment_tracking, routes_list_1.SHIPMENT_TRACKING_ROUTES);
    var feLiveTrakingRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.FE_live_tracking, routes_list_1.FE_LIVE_TRAKING_ROUTES);
    var shipmentMasterRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.Shipment_Route_Master, routes_list_1.SHIPMENT_MASTER_ROUTES);
    var ORC_Management_ButtonstRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.ORC_Management, routes_list_1.ORC_MANGAGEMEN_BUTTON_ROUES);
    var SCF_Management_ButtonstRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.SCF_Management, routes_list_1.SCF_MANGAGEMEN_BUTTON_ROUES);
    var ultraStaticRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.ULTRA_STATIC, routes_list_1.ULTRA_STATIC_ROUTES);
    return (React.createElement(react_router_1.Routes, null,
        React.createElement(react_router_1.Route, { path: "/".concat(route_path_1.default.PATHS.overView), element: React.createElement(dashboard_1.default, null) }),
        React.createElement(react_router_1.Route, { path: "/sso/*", element: React.createElement(dashboard_1.default, null) }),
        React.createElement(react_router_1.Route, { path: '/sso/*', element: React.createElement(LoginComponent_1.default, null) }),
        filteredRouteData &&
            filteredRouteData.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        feManagementRoutes &&
            feManagementRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        rostorRoutes &&
            rostorRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        roleManagementRoutes &&
            roleManagementRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        vendorManagementRoutes &&
            vendorManagementRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        feApprovalManagementRoutes &&
            feApprovalManagementRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        VendorApprovalManagementRoutes &&
            VendorApprovalManagementRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        auditManagementRoutes &&
            auditManagementRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        sampleTrackngRoutes &&
            sampleTrackngRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        notificationRoutes &&
            notificationRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        etrfRoutes &&
            etrfRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        preciousSampleRoutes &&
            preciousSampleRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        visitManagementRoutes &&
            visitManagementRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        controlTowerRoutes &&
            controlTowerRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                            isListReloaded: isListReloaded,
                            setIsListReloaded: setIsListReloaded,
                        } },
                        React.createElement(features.component, null)) }));
            }),
        reportsRoutes &&
            reportsRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        my_reportsRoutes &&
            my_reportsRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        cc_rosterRoutes &&
            cc_rosterRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        routePlannersRoutes &&
            routePlannersRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        user_logistic &&
            user_logistic.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        labMasterRoutes &&
            labMasterRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        hubMasterRoutes &&
            hubMasterRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        masterBoxTrakingRoutes &&
            masterBoxTrakingRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        RegionRoutes &&
            RegionRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        SSO_MY_REPORTS_ROTUES &&
            SSO_MY_REPORTS_ROTUES.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        shipmentTrakingRoutes &&
            shipmentTrakingRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        intracityReportstRoutes &&
            intracityReportstRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        intercityReportstRoutes &&
            intercityReportstRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        feLiveTrakingRoutes &&
            feLiveTrakingRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        shipmentMasterRoutes &&
            shipmentMasterRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        intracityReportsButtonstRoutes &&
            intracityReportsButtonstRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        ORC_Management_ButtonstRoutes &&
            ORC_Management_ButtonstRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        SCF_Management_ButtonstRoutes &&
            SCF_Management_ButtonstRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        ultraStaticRoutes &&
            ultraStaticRoutes.map(function (features) {
                return (React.createElement(react_router_1.Route, { path: "".concat(features.path, "/*"), key: features.path, element: React.createElement(features.component, null) }));
            }),
        React.createElement(react_router_1.Route, { path: "/".concat(route_path_1.default.PATHS.userProfile), element: React.createElement(userProfile_1.default, null) }),
        React.createElement(react_router_1.Route, { path: "/".concat(route_path_1.default.PATHS.changePassword), element: React.createElement(changePassword_1.default, null) }),
        React.createElement(react_router_1.Route, { path: "/".concat(route_path_1.default.PATHS.totalActiveClients, "/").concat(route_path_1.default.PATHS.assignRoute, "/:id"), element: React.createElement(assign_active_clients_1.default, null) }),
        React.createElement(react_router_1.Route, { path: "/".concat(route_path_1.default.PATHS.ShipmentTower), element: React.createElement(Shipmentdashboard_1.default, null) }),
        React.createElement(react_router_1.Route, { path: "/".concat(route_path_1.default.PATHS.Visits, "/").concat(route_path_1.default.PATHS.PartialTransfer), element: React.createElement(PartialVisits_1.default, null) }),
        React.createElement(react_router_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) })));
};
exports.default = MainRoutes;
