"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../actions/config_action");
var button_component_1 = require("../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var search_component_1 = require("../../../common/search/search_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var cre_api_calls_1 = require("./cre_api_calls");
var cre_active_list_1 = require("./cre_list/cre_active_list");
var cre_popUp_1 = require("./cre_list/cre_popUp");
var CashReceivingExecutive = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = React.useState(false), filter = _a[0], setFilter = _a[1];
    var _b = React.useState(''), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = React.useState(false), pageLoad = _c[0], setPageload = _c[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.cre);
    var _d = React.useState(false), openPop = _d[0], setOpenPop = _d[1];
    var _e = React.useState(appConstants_1.defaultPageLimit), PageLimit = _e[0], setPageLimit = _e[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _f = React.useState(null), selectedLab = _f[0], setSelectedLab = _f[1];
    var _g = React.useState(1), count = _g[0], setCount = _g[1];
    var _h = React.useState(false), opens = _h[0], setOpens = _h[1];
    var _j = React.useState([]), data = _j[0], setData = _j[1];
    var navigate = (0, react_router_1.useNavigate)();
    var labHandler = function (value) {
        setSelectedLab(value);
    };
    var searchFe = function (event) {
        setSearchTerm(event.target.value.trim());
    };
    var popUpOpen = function () {
        setOpenPop(true);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var autoGenerateReportHandler = function () {
        var payload = {};
        if (searchTerm.trim().length >= 3) {
            payload.search_string = searchTerm.trim();
        }
        if (selectedLab) {
            payload.lab_code = selectedLab.value;
        }
        payload.page = count;
        payload.size = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, cre_api_calls_1.creExcelReport)(payload)
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpens(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var FilterHandler = function () {
        setFilter(!filter);
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Cash Receiving Executive")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { type: "button", buttonName: appConstants_1.reportDownload, color: "yellow-outline", isError: (data === null || data === void 0 ? void 0 : data.length) > 0 ? false : true, endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler }),
                    React.createElement(button_component_1.default, { buttonName: "ADD CRE", color: "yellow", onClickHandler: popUpOpen, isError: !features[menu_constants_1.FEATURE_CONSTANTS.addCRE] })),
                React.createElement(cre_popUp_1.default, { openPopToAdd: openPop, setPopToAdd: setOpenPop, setPageload: setPageload }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "filter-options" },
                React.createElement("div", { className: "search-filter" },
                    React.createElement(search_component_1.default, { placeholder: "Search CRE Name/Mobile", searchValue: searchTerm, searchHandler: searchFe }))),
            React.createElement(material_1.Stack, { direction: 'row' },
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "lab", placeholder: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                        return a.label.localeCompare(b.label);
                    }), onChange: labHandler, value: selectedLab || null }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "scrollable-content fe-managment-screen" },
            React.createElement(cre_active_list_1.default, { pageLoad: pageLoad, searchTerm: searchTerm, selectedLab: selectedLab, PageLimit: PageLimit, setCount: setCount, setData: setData, filter: filter })),
        React.createElement(pop_up_1.default, { open: opens },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpens(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = CashReceivingExecutive;
