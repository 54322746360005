"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var Switch_1 = require("@mui/material/Switch");
var array_helpers_1 = require("../../../utils/array_helpers");
var menu_constants_1 = require("../../../menuList/menu_constants");
var UltraStaticEditButton = function (_a) {
    var data = _a.data;
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.ULTRA_STATIC);
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn", disabled: features[menu_constants_1.FEATURE_CONSTANTS.EDIT_ULTRA_STATIC] ? data.status === 'INACTIVE' ? true : false : true },
            React.createElement("span", { className: "icon-edit" })),
        React.createElement(material_1.IconButton, { className: 'icon-btn', disabled: !features[menu_constants_1.FEATURE_CONSTANTS.DELETE_ULTRA_STATIC] },
            React.createElement("span", { className: "icon-delete_forever" })),
        React.createElement(Switch_1.default, { defaultChecked: data.status === 'ACTIVE' ? true : false, disabled: !features[menu_constants_1.FEATURE_CONSTANTS.EDIT_ULTRA_STATIC] })));
};
exports.default = UltraStaticEditButton;
