"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../../actions/config_action");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../../reports/reports_helper");
var state_city_filter_1 = require("../../sample_traking/state_city_filter");
var hubMaster_api_calls_1 = require("./hubMaster_api_calls");
var HubMasterListing = function () {
    var nav = (0, react_router_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = React.useState(''), searchTerm = _a[0], setSearchTerm = _a[1];
    var _b = React.useState(false), reloadPageAfterDelete = _b[0], setReloadPageAfterDelete = _b[1];
    var _c = React.useState([]), filterStateCity = _c[0], setFilterStateCity = _c[1];
    var _d = React.useState(null), selectlab = _d[0], setSelectedLab = _d[1];
    var _e = React.useState(null), hubType = _e[0], setHubType = _e[1];
    var _f = React.useState(''), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = React.useState(0), totalPageCount = _g[0], setTotalPageCount = _g[1];
    var _h = React.useState(1), pageCount = _h[0], setpageCount = _h[1];
    var _j = React.useState(0), totalCount = _j[0], setTotalCount = _j[1];
    var _k = React.useState(appConstants_1.defaultPageLimit), PageLimit = _k[0], setPageLimit = _k[1];
    var _l = React.useState(undefined), hubList = _l[0], setHubList = _l[1];
    var _m = React.useState(false), open = _m[0], setOpen = _m[1];
    var _o = React.useState(''), deletedHub = _o[0], setDeletedHub = _o[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var hubTypes = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.hub_type; });
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.HUB_Roster_Management);
    var _p = React.useState(false), opens = _p[0], setOpens = _p[1];
    var navigate = (0, react_router_1.useNavigate)();
    var navigateToaddSlot = function () {
        nav("/".concat(route_path_1.default.PATHS.hubMasterList, "/").concat(route_path_1.default.PATHS.create_hub_slot));
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    var hubTypeHandler = function (hubtype) {
        setHubType(hubtype);
    };
    var stateCityHandler = function (event) {
        if (event.length >= 0) {
            setFilterStateCity(event);
        }
    };
    var LabHandler = function (Lab) {
        setSelectedLab(Lab);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var fetchList = function (payload) {
        (0, hubMaster_api_calls_1.fetchHubListing)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setErrorMessage('');
            setTotalPageCount(count);
            var convertedData = (0, array_helpers_1.convertHubListFromResponse)(response === null || response === void 0 ? void 0 : response.result);
            setHubList(convertedData);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setHubList([]);
        });
    };
    var deleteUser = function () {
        (0, hubMaster_api_calls_1.deleteHub)(deletedHub)
            .then(function (response) {
            console.log(response, 'iojuhjg');
            react_hot_toast_1.default.success(response.message);
            setReloadPageAfterDelete(true);
            handleClose();
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
            handleClose();
        });
    };
    var onRowclickofAssign = function (event) {
        console.log(event.event.target.className.includes('CANCEL'), 'kjhgfd');
        if (features[menu_constants_1.FEATURE_CONSTANTS.updateHUBTimeWindows] &&
            event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-edit')) {
            nav("/".concat(route_path_1.default.PATHS.hubMasterList, "/").concat(route_path_1.default.PATHS.edit_hub_slot), { state: event.data });
        }
        else if (features[menu_constants_1.FEATURE_CONSTANTS.updateHUBTimeWindows] &&
            event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-delete_forever')) {
            setDeletedHub(event.data.hub_code);
            setOpen(true);
        }
    };
    var refetch = (0, react_query_1.useQuery)(['sample-tracking-list', pageCount], function () {
        var currentPage = pageCount < 0 ? 0 : pageCount;
        var payload = {};
        if (filterStateCity.length > 0) {
            var state_1 = [];
            var city_1 = [];
            filterStateCity.map(function (selectedCityObj) {
                city_1.push(selectedCityObj.short_code);
                state_1.push(selectedCityObj.state_name);
                payload['city'] = city_1;
                payload['state'] = state_1;
            });
        }
        if (searchTerm.trim().length >= 3) {
            payload['search_string'] = searchTerm.toLocaleLowerCase().trim();
        }
        if (selectlab !== null) {
            payload['lab_code'] = selectlab.value;
        }
        if (hubType !== null) {
            payload['hub_type'] = hubType.label;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        fetchList(payload);
        setReloadPageAfterDelete(false);
    }).refetch;
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 0 ? 0 : pageCount;
        var payload = {};
        if (filterStateCity.length > 0) {
            var state_2 = [];
            var city_2 = [];
            filterStateCity.map(function (selectedCityObj) {
                city_2.push(selectedCityObj.short_code);
                state_2.push(selectedCityObj.state_name);
                payload['city'] = city_2;
                payload['state'] = state_2;
            });
        }
        if (searchTerm.trim().length >= 3) {
            payload['search_string'] = searchTerm.toLocaleLowerCase().trim();
        }
        if (selectlab !== null) {
            payload['lab_code'] = selectlab.value;
        }
        if (hubType !== null) {
            payload['hub_type'] = hubType.label;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, reports_helper_1.hubMasterExcel)(payload)
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpens(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [reloadPageAfterDelete]);
    return (React.createElement("div", { className: "fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Hub Roster Management")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { type: "button", buttonName: appConstants_1.reportDownload, color: "yellow-outline", isError: (hubList === null || hubList === void 0 ? void 0 : hubList.length) > 0 ? false : true, endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler }),
                React.createElement(button_component_1.default, { buttonName: "ADD HUB", color: "yellow", onClickHandler: navigateToaddSlot, isError: !features[menu_constants_1.FEATURE_CONSTANTS.createHUBTimeWindows] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" },
                React.createElement(search_component_1.default, { placeholder: "Search Hub", searchValue: searchTerm, searchHandler: searchingHandler })),
            React.createElement("div", { className: "filter-options" },
                React.createElement("div", { className: "filter-options city-state-wrapper" },
                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "hubType", value: hubType, dropDownList: hubTypes, placeholder: "Select Hub Type", onChange: hubTypeHandler })),
                React.createElement(state_city_filter_1.default, { name: "Hub Roster Management", onChangeHandler: stateCityHandler }),
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "labcode", value: selectlab || null, dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList), placeholder: "Lab code", onChange: LabHandler }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "fe-wrapper pay  -fe-wrapper" },
            React.createElement("div", { className: "scrollable-content ".concat(features[menu_constants_1.FEATURE_CONSTANTS.updateHUBTimeWindows]
                    ? 'clickable-row'
                    : 'unclickablerow') },
                React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.HUB_MASTER_COLUMNS, rows: hubList && hubList, errorMessage: errorMessage, pagination: false, onRowClicked: onRowclickofAssign, className: 'hub-roster-table' }),
                React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: hubList === null || hubList === void 0 ? void 0 : hubList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
            React.createElement(pop_up_1.default, { open: opens },
                React.createElement("div", { className: "modal-header" },
                    React.createElement("h2", { className: "heading" }, "Request Submitted"),
                    React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
                React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpens(false); } }),
                    React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))),
        React.createElement(material_1.Modal, { open: open },
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                    React.createElement("h2", null, "Confirmation")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, "Are you sure  you want to delete ".concat(deletedHub, "?"))),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow", onClickHandler: deleteUser }))))));
};
exports.default = HubMasterListing;
