"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var formik_1 = require("formik");
var React = require("react");
var Grid_1 = require("@mui/material/Grid/Grid");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var table_data_1 = require("../../../constants/table_data");
var array_helpers_1 = require("../../../utils/array_helpers");
require("../../field_exicutive/fe-page.scss");
var user_services_1 = require("./create_user/user_services");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var api_1 = require("../role_management/api");
var api_urls_1 = require("../../../api/api_urls");
var schema_1 = require("../../../constants/schema");
var material_1 = require("@mui/material");
var api_service_1 = require("../../../api/api_service");
var react_hot_toast_1 = require("react-hot-toast");
var appConstants_1 = require("../../../constants/appConstants");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var react_query_1 = require("react-query");
var menu_constants_1 = require("../../../menuList/menu_constants");
var config_action_1 = require("../../../actions/config_action");
var route_path_1 = require("../../../constants/route_path");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var userEditInitialState = {
    employeeName: '',
    userName: '',
    mobile: '',
    role: [],
    location: '',
    branch: '',
    email: '',
    id: '',
    employee_code: '',
};
var UserManagement = function () {
    var _a = React.useState(undefined), dataList = _a[0], setDataList = _a[1];
    var _b = React.useState(userEditInitialState), intialvalues = _b[0], setInitialValues = _b[1];
    var _c = React.useState(false), open = _c[0], setOpen = _c[1];
    var _d = React.useState(false), downloadOpen = _d[0], setDownloadOpen = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(1), pageCount = _f[0], setpageCount = _f[1];
    var _g = React.useState(''), errorMessage = _g[0], setErrorMessage = _g[1];
    var _h = React.useState([]), filterSelectedRoles = _h[0], setSelectedFilterRoles = _h[1];
    var _j = React.useState(''), searchTerm = _j[0], setSearchTerm = _j[1];
    var _k = React.useState([]), roleList = _k[0], setRoleList = _k[1];
    var _l = React.useState(appConstants_1.defaultPageLimit), PageLimit = _l[0], setPageLimit = _l[1];
    var _m = React.useState(0), totalCount = _m[0], setTotalCount = _m[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var callbackUpdateSelectedCities = function (value) {
        setSelectedFilterRoles(value);
    };
    var userFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.userManagement);
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value.trim());
    };
    var refetch = (0, react_query_1.useQuery)(['user-management-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {
            attributes: {
                search: searchTerm,
                city_names: [],
                role_ids: [],
                app_ids: [parseInt(process.env.ROLES_APP_ID, 10)],
                emp_types: [],
            },
        };
        (0, user_services_1.fetchUserList)(currentPage, parseInt(PageLimit.value.toString()), payload)
            .then(function (response) {
            var convertedUsersList = (0, array_helpers_1.convertUsersListFromRes)(response.data.result);
            setDataList(convertedUsersList);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setErrorMessage("");
            setTotalPageCount(count);
            setTotalCount(response.data.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setDataList([]);
        });
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [PageLimit]);
    var fetchRoleList = function (endPoint, payload, domain) {
        (0, api_1.getUserRoleList)(endPoint, payload, domain)
            .then(function (res) {
            setRoleList(res);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setRoleList([]);
        });
    };
    React.useEffect(function () {
        fetchRoleList(api_urls_1.api_urls.ROLE_LIST, { page: 1, size: 200, app_id: parseInt(process.env.ROLES_APP_ID, 10) }, process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL);
    }, []);
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var handleClose = function () {
        setOpen(false);
        setInitialValues(__assign({}, userEditInitialState));
    };
    var formSubmitHnadler = function (values) {
        console.log('values:', values);
        var payload = {};
        payload['username'] = values.userName;
        payload['phone_number'] = values.mobile;
        payload['email'] = values.email;
        var employeeName = values.employeeName
            ? values.employeeName.split(' ')
            : [];
        if (employeeName.length === 1) {
            payload['first_name'] = employeeName[0];
        }
        else if (employeeName.length === 2) {
            payload['first_name'] = employeeName[0];
            payload['last_name'] = employeeName[1];
        }
        else {
            payload['first_name'] = employeeName[0];
            payload['middle_name'] = employeeName[1];
            payload['last_name'] = employeeName[2];
        }
        var accessDetails = [];
        payload['access_details'] = accessDetails;
        payload['gender'] = 'Male';
        payload['employee_code'] = values.employee_code;
        var sortedAttrubutesData = (0, array_helpers_1.sortObjectKeys)(payload);
        api_service_1.api_service
            .put({
            endPoint: "".concat(api_service_1.api_service.api_urls.USER_MANAGEMENT_EDIT).concat(values.id),
            payLoad: { attributes: sortedAttrubutesData },
            domain: process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL,
            customHeaders: {
                'x-signature-version': 'v2'
            }
        })
            .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (res.data.status === 200) {
                    react_hot_toast_1.default.success(res.data.message);
                    setOpen(false);
                    refetch();
                }
                else {
                    react_hot_toast_1.default.error(res.data.message);
                }
                return [2];
            });
        }); })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var onRowClickedHandler = function (event) {
        console.log('event inside onRowClickedHandler:', event);
        var selectedRoles = [];
        if (event.data.role) {
            var roleArr = event.data.role.split(',');
            roleArr.forEach(function (seelctedrole) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, roleList.forEach(function (role) {
                                if (seelctedrole === role.label) {
                                    selectedRoles.push(role);
                                }
                            })];
                        case 1:
                            _a.sent();
                            return [2];
                    }
                });
            }); });
        }
        setSelectedFilterRoles(selectedRoles);
        setInitialValues({
            employeeName: event.data.fullName,
            userName: event.data.userName,
            mobile: event.data.mobile,
            role: selectedRoles,
            location: event.data.location,
            branch: event.data.branch,
            email: event.data.email,
            id: event.data.id,
            employee_code: event.data.employee_code,
        });
        setOpen(true);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (searchTerm) {
            payload['search_string'] = searchTerm;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.USER_MANAGEMENT_DOWNLOAD,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setDownloadOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    return (React.createElement("div", { className: 'fe-wrapper page-wrapper fe-mangamnet-wrapper' },
        React.createElement("div", { className: 'tool-bar-wrapper' },
            React.createElement("div", { className: 'page-heaidng' },
                React.createElement("h2", null, "User List")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, endIcon: 'icon-import download-icon', color: 'yellow-outline', onClickHandler: autoGenerateReportHandler, isError: !userFeatures[menu_constants_1.FEATURE_CONSTANTS.userListDownload] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: 'Search by Employeee, User Name and Mobile Number', searchValue: searchTerm, searchHandler: searchingHandler })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.USER_COLUMNS, onRowClicked: onRowClickedHandler, rows: dataList, pagination: false, errorMessage: errorMessage, className: 'user-management-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(pop_up_1.default, { open: open, popUpSize: 'popup-lg' },
            React.createElement("div", { className: 'modal-header' },
                React.createElement("h2", { className: 'heading' },
                    "Lets Edit User-",
                    intialvalues.userName)),
            React.createElement("div", { className: 'modal-body' },
                React.createElement(formik_1.Formik, { enableReinitialize: true, initialValues: __assign({}, intialvalues), key: 'userListEdit', validateOnBlur: false, validationSchema: schema_1.schemas.USEREDITSCHEMA, onSubmit: formSubmitHnadler }, function (_a) {
                    var errors = _a.errors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                        console.log('initial values inside form:', intialvalues),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                                React.createElement(formik_1.Field, { name: 'employeeName' }, function () {
                                    return (React.createElement(text_filed_component_1.default, { className: 'text-caps', label: 'Employee Name', name: 'employeeName', required: true, maxLength: 255, isDisabled: false, onChange: function (e) {
                                            setFieldTouched('employeeName', true);
                                            setFieldValue('employeeName', e);
                                        }, value: values === null || values === void 0 ? void 0 : values.employeeName }));
                                }),
                                React.createElement(formik_1.ErrorMessage, { name: 'employeeName' }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                                    React.createElement("div", { className: 'error' }, errorMsg))); })),
                            React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                                React.createElement(formik_1.Field, { name: 'mobile' }, function () {
                                    return (React.createElement(text_filed_component_1.default, { className: 'text-caps', label: 'Mobile Number', name: 'mobile', required: true, maxLength: 255, isDisabled: false, onKeyPress: function (e) {
                                            if (new RegExp(/^[a-zA-Z0-9 ]*$/).test(e.key)) {
                                            }
                                            else {
                                                e.preventDefault();
                                            }
                                            var key = e.charCode;
                                            if (key === 32) {
                                                event.preventDefault();
                                            }
                                        }, onChange: function (e) {
                                            setFieldTouched('mobile', true);
                                            setFieldValue('mobile', e);
                                        }, value: values === null || values === void 0 ? void 0 : values.mobile }));
                                }),
                                React.createElement(formik_1.ErrorMessage, { name: 'mobile' }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                                    React.createElement("div", { className: 'error' }, errorMsg))); })),
                            React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                                React.createElement(formik_1.Field, { name: 'userName' }, function () {
                                    return (React.createElement(text_filed_component_1.default, { className: 'text-caps', label: 'User Name', name: 'userName', required: true, maxLength: 255, isDisabled: false, onChange: function (e) {
                                            setFieldTouched('userName', true);
                                            setFieldValue('userName', e);
                                        }, value: values === null || values === void 0 ? void 0 : values.userName }));
                                }),
                                React.createElement(formik_1.ErrorMessage, { name: 'userName' }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                                    React.createElement("div", { className: 'error' }, errorMsg))); })),
                            React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                                React.createElement(formik_1.Field, { name: 'email' }, function () {
                                    return (React.createElement(text_filed_component_1.default, { className: 'text-caps', label: 'Email', name: 'email', required: true, maxLength: 255, isDisabled: false, onChange: function (e) {
                                            setFieldTouched('email', true);
                                            setFieldValue('email', e);
                                        }, value: values === null || values === void 0 ? void 0 : values.email }));
                                }),
                                React.createElement(formik_1.ErrorMessage, { name: 'email' }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                                    React.createElement("div", { className: 'error' }, errorMsg))); })),
                            React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                                React.createElement(formik_1.Field, { name: 'role' }, function () {
                                    return (React.createElement(multi_selector_1.default, { size: "dropdown-lg", label: "Role", dropDownList: roleList, onChangeHnadlre: callbackUpdateSelectedCities, value: filterSelectedRoles || [], isDisabled: true }));
                                }),
                                React.createElement(formik_1.ErrorMessage, { name: 'role' }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                                    React.createElement("div", { className: 'error' }, errorMsg))); }))),
                        React.createElement("div", { className: 'form-btns' },
                            React.createElement(button_component_1.default, { buttonName: 'Cancel', color: 'yellow-outline', onClickHandler: handleClose }),
                            React.createElement(button_component_1.default, { buttonName: "submit", type: "submit", color: "yellow" }))));
                })),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" })),
        React.createElement(pop_up_1.default, { open: downloadOpen },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setDownloadOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = UserManagement;
