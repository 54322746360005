"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var button_component_1 = require("../../common/buttons/button_component");
var iconButton_component_1 = require("../../common/buttons/iconButton_component");
var ModalComponentWithoutButton_1 = require("../../common/modal/ModalComponentWithoutButton");
var text_filed_component_1 = require("../../common/text_filed/text_filed_component");
var route_path_1 = require("../../constants/route_path");
var schema_1 = require("../../constants/schema");
var Region_Mapping_Helpers_1 = require("./Region_Mapping_Helpers");
var UserTransfer_1 = require("./UserTransfer");
var x_Featureids = [];
var MapUser = function () {
    var pathname = (0, react_router_1.useLocation)().pathname;
    var state = (0, react_router_1.useLocation)().state;
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var _a = React.useState([]), selectedFeatures = _a[0], setSelectedFeatures = _a[1];
    var _b = React.useState({}), editRoleList = _b[0], setEditRoleList = _b[1];
    var _c = React.useState([]), editids = _c[0], setEditIds = _c[1];
    var _d = React.useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = React.useState(false), open = _e[0], setOpen = _e[1];
    var navigateToRegionList = function () {
        nav("/".concat(route_path_1.default.PATHS.region_listing));
    };
    var initialValues = {
        RegionName: state ? state.region_name : '',
    };
    var formSubmitHnadler = function (values) {
        setOpen(true);
    };
    var fetchRegionListByRegionId = function () {
        var payload = {};
        payload['region_id'] = state.region_id.toString();
        (0, Region_Mapping_Helpers_1.fetchUserMappingUpdateList)(payload)
            .then(function (res) {
            var _a;
            (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.map(function (e) {
                setEditIds(function (prev) { return __spreadArray(__spreadArray([], prev, true), [e.user_id], false); });
            });
            setErrorMessage('');
            setEditRoleList(function (editRoleList) {
                return __assign(__assign({}, editRoleList), res.data);
            });
        })
            .catch(function (err) {
            setErrorMessage(err);
            setEditRoleList({});
        });
    };
    console.log(editRoleList);
    var formSubmitHandler = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var createUserPayload;
        return __generator(this, function (_a) {
            setOpen(true);
            selectedFeatures.length >= 1 &&
                selectedFeatures.map(function (val) { return x_Featureids.push(val.id); });
            createUserPayload = {};
            createUserPayload['user_id'] = x_Featureids;
            createUserPayload['region_id'] = state.region_id.toString();
            (0, Region_Mapping_Helpers_1.createUserMappingList)(createUserPayload)
                .then(function (res) {
                nav("/".concat(route_path_1.default.PATHS.region_listing));
            })
                .catch(function (err) {
                setOpen(false);
                react_hot_toast_1.default.error(err.message);
            });
            x_Featureids = [];
            return [2];
        });
    }); };
    var renderForm = function () {
        return !errorMessage ? (React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, validationSchema: schema_1.schemas.CreateRegionSchema, onSubmit: function (values, _a) {
                var setFieldError = _a.setFieldError, setSubmitting = _a.setSubmitting;
                formSubmitHnadler(values);
            }, enableReinitialize: true }, function (_a) {
            var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldTouched = _a.setFieldTouched;
            return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                    React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                        React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateToRegionList }),
                        React.createElement("h2", null, "'MAP USERS TO REGION'")),
                    React.createElement("div", { className: "page-control-wrapper" },
                        React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateToRegionList }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow", isError: values.RegionName ? false : true }),
                        React.createElement(ModalComponentWithoutButton_1.default, { open: open },
                            React.createElement("div", { className: "modal-header " },
                                React.createElement("h2", { className: "heading" }, "Confirmation"),
                                React.createElement("p", { className: "sub-heading" }, "Are you sure you want to submit")),
                            React.createElement("div", { className: "modal-footer" },
                                React.createElement(button_component_1.default, { buttonName: "NO", color: "yellow-outline", onClickHandler: function () {
                                        setOpen(false);
                                    } }),
                                React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", onClickHandler: function () {
                                        formSubmitHandler(values);
                                    } }))))),
                React.createElement("div", { className: "scrollable-content" },
                    React.createElement("div", { className: "add-slotform" },
                        React.createElement("div", { className: "input-fileds-wrapper" },
                            React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3.5 },
                                    React.createElement(text_filed_component_1.default, { name: "RegionName", minLength: 4, maxLength: 25, onChange: function (value) {
                                            setFieldTouched('RegionName');
                                            setFieldValue('RegionName', value);
                                        }, isDisabled: (state === null || state === void 0 ? void 0 : state.region_name) ? true : false, label: "Region Name", value: state ? state.region_name : values.RegionName }),
                                    errors['RegionName'] && touched['RegionName'] ? (React.createElement("div", { className: "error" }, errors['RegionName'])) : null)))),
                    React.createElement(UserTransfer_1.default, { pathName: pathname, setSelectedFeatures: setSelectedFeatures, updateEditFeaturesIds: editids, role_id: id }),
                    ")")));
        })) : (React.createElement("div", { className: "server-error" },
            ' ',
            errorMessage && errorMessage.toString()));
    };
    React.useEffect(function () {
        if (state) {
            fetchRegionListByRegionId();
        }
    }, [state]);
    return React.createElement("div", { className: "create-role-wrapper" }, renderForm());
};
exports.default = MapUser;
