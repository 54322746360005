"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../common/buttons/button_component");
var route_path_1 = require("../../constants/route_path");
var menu_constants_1 = require("../../menuList/menu_constants");
var routes_list_1 = require("../../routes/routes_list");
var array_helpers_1 = require("../../utils/array_helpers");
var RegionMapping = function () {
    var nav = (0, react_router_dom_1.useNavigate)();
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Region_Management);
    console.log(features, 'activeTabfeatures');
    var RegionReportRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.Region_Management, routes_list_1.REGION_REPORT_SUB_ROUES);
    console.log(RegionReportRoutes, 'activeTab');
    var createNewUser = function () {
        nav("".concat(route_path_1.default.PATHS.region_create));
    };
    return (React.createElement("div", { className: "orderlist-main-wrapper mis-reports-page" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", { className: "title" }, pathname.includes('userRegion') ? 'User Region Mapping' : 'Region Management')),
            pathname.includes('userRegion') ?
                ''
                : React.createElement(button_component_1.default, { color: "yellow", buttonName: "Create Region", onClickHandler: createNewUser }))));
};
exports.default = RegionMapping;
