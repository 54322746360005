"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var FE_Creation_action_1 = require("../../../../actions/FE_Creation_action");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../../constants/appConstants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../../reports/reports_helper");
var state_city_filter_1 = require("../../sample_traking/state_city_filter");
var auto_routing_api_calls_1 = require("../auto_routing_api_calls");
var auto_routing_helper_1 = require("../auto_routing_helper");
require("../controldashboard.scss");
var on_demand_request_1 = require("../on_demand_request");
var total_active_clients_1 = require("../total_active_clients");
var total_FE_1 = require("../total_FE");
var total_pickups_1 = require("../total_pickups");
var conroldashboardstyles_1 = require("./conroldashboardstyles");
var ControlTowerRouting = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var classes = (0, conroldashboardstyles_1.useStyles)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var auto_routing_filtes = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer.auto_routing_filtes; });
    var _u = React.useState((auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.selected_states)
        ? auto_routing_filtes.selected_states
        : []), filterSelectedStates = _u[0], setSelectedStates = _u[1];
    var _v = React.useState((auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.selected_cities)
        ? auto_routing_filtes.selected_cities
        : []), filterSelectedCities = _v[0], setSelectedCities = _v[1];
    var _w = React.useState({}), dashboard = _w[0], setDashboardData = _w[1];
    var _x = React.useState({}), metrics1 = _x[0], setMetrics1 = _x[1];
    var _y = React.useState({}), metrics2 = _y[0], setMetrics2 = _y[1];
    var _z = React.useState({}), metrics3 = _z[0], setMetrics3 = _z[1];
    var _0 = React.useState({}), metrics4 = _0[0], setMetrics4 = _0[1];
    var _1 = React.useState(1), activeClientsFilter = _1[0], setActiveClientsFilter = _1[1];
    var _2 = React.useState(1), totalFEFilter = _2[0], setTotalFEFilter = _2[1];
    var _3 = React.useState(1), totalPickupFilter = _3[0], setTotalPickupFilter = _3[1];
    var _4 = React.useState(1), onDemandFilter = _4[0], setOnDemandFilter = _4[1];
    var _5 = React.useState(), payloadexport = _5[0], setPayload = _5[1];
    React.useState(1);
    var _6 = React.useState(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients), statName = _6[0], setStatName = _6[1];
    var _7 = React.useState(), classN = _7[0], setClassN = _7[1];
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _8 = React.useState(appConstants_1.defaultPageLimit), PageLimit = _8[0], setPageLimit = _8[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _9 = React.useState((auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.lab_code) ? auto_routing_filtes.lab_code : null), selectedLab = _9[0], setSelectedLab = _9[1];
    var _10 = React.useState(false), open = _10[0], setOpen = _10[1];
    var _11 = React.useState((auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.selected_cities)
        ? auto_routing_filtes.selected_cities
        : []), filterStateCity = _11[0], setFilterStateCity = _11[1];
    var _12 = React.useState(((_a = auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.date_range) === null || _a === void 0 ? void 0 : _a.start_date)
        ? auto_routing_filtes.date_range.start_date
        : new Date().toString()), startDate = _12[0], setStartDate = _12[1];
    var _13 = React.useState(((_b = auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.date_range) === null || _b === void 0 ? void 0 : _b.end_date)
        ? auto_routing_filtes.date_range.end_date
        : new Date().toString()), endDate = _13[0], setEndDate = _13[1];
    var _14 = React.useState(false), isDateRangeUpdated = _14[0], setIsDateRangeUpdated = _14[1];
    var _15 = React.useState(false), reloadPage = _15[0], setReloadPage = _15[1];
    var labHandler = function (value) {
        setSelectedLab(value);
    };
    setEndDate;
    var stateCityHandler = function (event) {
        if (event.length >= 0) {
            setFilterStateCity(event);
        }
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var payload = {};
    if (startDate && endDate) {
        var dateRangeArr = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        };
        payload.date_range = dateRangeArr;
    }
    if (filterStateCity.length > 0) {
        console.log(filterStateCity, 'filterSelectedCities');
        var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(filterStateCity);
        payload['states'] =
            selectedStateAndCity.states.length >= 30
                ? ['ALL']
                : selectedStateAndCity.states;
        payload['cities'] =
            selectedStateAndCity.cities.length >= 500
                ? ['ALL']
                : selectedStateAndCity.cities;
    }
    if (selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.length) {
        payload.lab_codes = selectedLab.map(function (data) { return data.value.toString(); });
    }
    var getActiveClientMetrics = (0, react_query_1.useQuery)(['getActiveClientMetrics'], function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setPayload(payload);
                    return [4, (0, auto_routing_api_calls_1.getAutoRoutingAllMetricsList)(api_urls_1.api_urls.autoRoutingActiveClientMetrcs, payload, process.env.APPROVAL_BASEURL)
                            .then(function (res) {
                            setMetrics1(res);
                            UpdateListData.setIsListReloaded(false);
                            setOpen(false);
                        })
                            .catch(function (err) {
                            react_hot_toast_1.default.error(err);
                        })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); });
    var getOndemandMetrics = (0, react_query_1.useQuery)(['getOndemandMetrics'], function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setPayload(payload);
                    return [4, (0, auto_routing_api_calls_1.getAutoRoutingAllMetricsList)(api_urls_1.api_urls.autoRoutingOndemandtMetrcs, payload, process.env.APPROVAL_BASEURL)
                            .then(function (res) {
                            setMetrics2(res);
                            UpdateListData.setIsListReloaded(false);
                            setOpen(false);
                        })
                            .catch(function (err) {
                            react_hot_toast_1.default.error(err);
                        })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); });
    var getTotalFeMetrics = (0, react_query_1.useQuery)(['getTotalFeMetrics'], function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setPayload(payload);
                    return [4, (0, auto_routing_api_calls_1.getAutoRoutingAllMetricsList)(api_urls_1.api_urls.autoRoutingTotalFEtMetrcs, payload, process.env.APPROVAL_BASEURL)
                            .then(function (res) {
                            setMetrics3(res);
                            UpdateListData.setIsListReloaded(false);
                            setOpen(false);
                        })
                            .catch(function (err) {
                            react_hot_toast_1.default.error(err);
                        })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); });
    var getTotalPickupsMetrics = (0, react_query_1.useQuery)(['getTotalPickupsMetrics'], function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setPayload(payload);
                    return [4, (0, auto_routing_api_calls_1.getAutoRoutingAllMetricsList)(api_urls_1.api_urls.autoRoutingTotalPicukpstMetrcs, payload, process.env.APPROVAL_BASEURL)
                            .then(function (res) {
                            setMetrics4(res);
                            UpdateListData.setIsListReloaded(false);
                            setOpen(false);
                        })
                            .catch(function (err) {
                            react_hot_toast_1.default.error(err);
                        })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); });
    var handleDrawerClose = function () {
        setOpen(false);
    };
    React.useEffect(function () {
        getActiveClientMetrics.refetch();
        getOndemandMetrics.refetch();
        getTotalFeMetrics.refetch();
        getTotalPickupsMetrics.refetch();
    }, [
        reloadPage,
    ]);
    React.useEffect(function () {
        var allMetricsInfo = __assign(__assign(__assign(__assign({}, metrics1), metrics2), metrics3), metrics4);
        setDashboardData(allMetricsInfo);
        console.log(allMetricsInfo, 'allMetricsInfo');
    }, [metrics1, metrics2, metrics3, metrics4]);
    var filterHandler = function () {
        getActiveClientMetrics.refetch();
        getOndemandMetrics.refetch();
        getTotalFeMetrics.refetch();
        getTotalPickupsMetrics.refetch();
        var selctedFlters = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            selctedFlters.date_range = dateRangeArr;
        }
        if (filterStateCity.length > 0) {
            selctedFlters['selected_states'] = filterSelectedStates;
            selctedFlters['selected_cities'] = filterSelectedCities;
        }
        if (selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.length) {
            selctedFlters.lab_code = selectedLab;
        }
        if (selctedFlters) {
            dispatch((0, FE_Creation_action_1.getAutoRoutingPreviousFiltees)(selctedFlters));
        }
        else {
            dispatch((0, FE_Creation_action_1.getAutoRoutingPreviousFiltees)(null));
        }
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var routingAssignment = function (statsArr, routeAssignType) {
        var routeAssignCount;
        statsArr.filter(function (stat) {
            if (stat.routing_assignment === routeAssignType)
                return (routeAssignCount = stat.count);
        });
        return routeAssignCount ? routeAssignCount : 0;
    };
    return (React.createElement("div", { className: "orderlist-main-wrapper" },
        React.createElement("div", { className: "filter-menu-wrapper border-bottom mobile-view-filters" },
            React.createElement("div", { className: "search-filter" }),
            React.createElement("div", { className: "filter-options auto-route mobile-view-filters" },
                React.createElement(multi_selector_1.default, { label: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                        return a.label.localeCompare(b.label);
                    }), onChangeHnadlre: labHandler, value: selectedLab }),
                React.createElement(state_city_filter_1.default, { onChangeHandler: stateCityHandler, setSelectedStates: setSelectedStates, setSelectedCities: setSelectedCities, module: 'Controltower' }),
                React.createElement(text_filed_with_fromto_Daterange_1.default, { presentDate: true, isDateRangeSelcted: ((_c = auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.date_range) === null || _c === void 0 ? void 0 : _c.start_date) &&
                        ((_d = auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.date_range) === null || _d === void 0 ? void 0 : _d.end_date)
                        ? true
                        : false, startDate: (_e = auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.date_range) === null || _e === void 0 ? void 0 : _e.start_date, endDate: (_f = auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.date_range) === null || _f === void 0 ? void 0 : _f.end_date, onChangeHandler: callBackOnDateRangeSelect }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: filterHandler }))),
        React.createElement("div", { className: " long-page-scroll" },
            React.createElement("div", { className: "routingc-card-nav" },
                React.createElement("div", { className: "routing-dashboard-wrapper" },
                    React.createElement(material_1.Grid, { container: true, columns: { xs: 12, lg: 12, xl: 12 }, columnSpacing: 3 },
                        React.createElement(material_1.Grid, { item: true, lg: 12, xs: 12, xl: 12, className: "cards-grids-item" },
                            React.createElement("h2", { className: "heading cards-heaidng" },
                                ' ',
                                "Total Active Clients",
                                ' ',
                                React.createElement(iconButton_component_1.default, { icon: "icon-refresh", onClick: function () { return getActiveClientMetrics.refetch(); } })),
                            React.createElement("div", { className: "orderlist-main" },
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled  ", onClick: function () {
                                        setActiveClientsFilter(1);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content " },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.total_active_clients
                                                ? dashboard.active_clients.total_active_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.totalActiveClients))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(11);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.approved_clients
                                                ? dashboard.active_clients.approved_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.approvedClients))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(12);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.not_approved_clients
                                                ? dashboard.active_clients.not_approved_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.notApprovedClients))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-redshade disabled", onClick: function () {
                                        setActiveClientsFilter(7);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.total_inactive_clients
                                                ? dashboard.active_clients.total_inactive_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.totalInactiveClients))))),
                        React.createElement(material_1.Grid, { item: true, lg: 12, xs: 12, xl: 12, className: "cards-grids-item" },
                            React.createElement("h2", { className: "heading cards-heaidng" }, " Serviceable Clients"),
                            React.createElement("div", { className: "orderlist-main" },
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(7);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.active_dynamic_clients
                                                ? dashboard.active_clients.active_dynamic_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.activeDynamicClients))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(7);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.active_static_clients
                                                ? dashboard.active_clients.active_static_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.activeStaticClients))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(7);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.active_non_route_clients
                                                ? dashboard.active_clients.active_non_route_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.activeNonRouteClients))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue ".concat(((_g = dashboard === null || dashboard === void 0 ? void 0 : dashboard.active_clients) === null || _g === void 0 ? void 0 : _g.untagged_clients) <= 0
                                        ? 'disabled'
                                        : classN === 1
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.active_clients) === null || _a === void 0 ? void 0 : _a.untagged_clients) > 0) {
                                            setOpen(true);
                                            setClassN(1);
                                            setActiveClientsFilter(2);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.untagged_clients
                                                ? dashboard.active_clients.untagged_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content bg-lightred" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.UntaggedClients))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(5);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.active_us_clients
                                                ? dashboard.active_clients.active_us_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.ActiveUSClients))))),
                        React.createElement(material_1.Grid, { item: true, lg: 12, xs: 12, xl: 12, className: "cards-grids-item" },
                            React.createElement("h2", { className: "heading cards-heaidng" }, " Serviceable CC's/PSC's"),
                            React.createElement("div", { className: "orderlist-main" },
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled  ", onClick: function () {
                                        setActiveClientsFilter(3);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.active_cc_or_psc
                                                ? dashboard.active_clients.active_cc_or_psc
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.activeCCPSc))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(14);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.cc_or_psc_breakdown &&
                                                dashboard.active_clients.cc_or_psc_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.cc_or_psc_breakdown, 'DYNAMIC')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.ccPscDynamic))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(16);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.cc_or_psc_breakdown &&
                                                dashboard.active_clients.cc_or_psc_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.cc_or_psc_breakdown, 'STATIC')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.ccPscStatic))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(15);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.cc_or_psc_breakdown &&
                                                dashboard.active_clients.cc_or_psc_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.cc_or_psc_breakdown, 'NON_ROUTE')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.ccPscNonRoute))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(13);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.cc_or_psc_breakdown &&
                                                dashboard.active_clients.cc_or_psc_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.cc_or_psc_breakdown, 'UNTAGGED')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.ccPscUntagged))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(5);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.cc_or_psc_breakdown &&
                                                dashboard.active_clients.cc_or_psc_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.cc_or_psc_breakdown, 'ULTRA_STATIC')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.ccPscBreakdown))))),
                        React.createElement(material_1.Grid, { item: true, lg: 12, xs: 12, xl: 12, className: "cards-grids-item" },
                            React.createElement("h2", { className: "heading cards-heaidng" }, " Serviceable PUP's"),
                            React.createElement("div", { className: "orderlist-main" },
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setActiveClientsFilter(4);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.active_pup
                                                ? dashboard.active_clients.active_pup
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.active_PUP))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(18);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.pup_breakdown &&
                                                dashboard.active_clients.pup_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.pup_breakdown, 'DYNAMIC')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.pupDynamic))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(20);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.pup_breakdown &&
                                                dashboard.active_clients.pup_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.pup_breakdown, 'STATIC')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.pupStatic))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(19);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.pup_breakdown &&
                                                dashboard.active_clients.pup_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.pup_breakdown, 'NON_ROUTE')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.pupNonRoute))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(17);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.pup_breakdown &&
                                                dashboard.active_clients.pup_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.pup_breakdown, 'UNTAGGED')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.pupUntagged))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(5);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.pup_breakdown &&
                                                dashboard.active_clients.pup_breakdown.length > 0
                                                ? routingAssignment(dashboard.active_clients.pup_breakdown, 'ULTRA_STATIC')
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.PupUSBreakdown))))),
                        React.createElement(material_1.Grid, { item: true, lg: 12, xs: 12, xl: 12, className: "cards-grids-item" },
                            React.createElement("h2", { className: "heading cards-heaidng" }, " Pickups"),
                            React.createElement("div", { className: "orderlist-main" },
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(5);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.total_scheduled_pickups
                                                ? dashboard.active_clients.total_scheduled_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.totalScheduledPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(6);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.assigned_dynamic_pickups
                                                ? dashboard.active_clients.assigned_dynamic_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.assignedDynamicPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(7);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.assigned_static_pickups
                                                ? dashboard.active_clients.assigned_static_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.assignedStaticPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue ".concat(((_h = dashboard === null || dashboard === void 0 ? void 0 : dashboard.active_clients) === null || _h === void 0 ? void 0 : _h.unassigned_dynamic_pickups) <= 0
                                        ? 'disabled'
                                        : classN === 7
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.active_clients) === null || _a === void 0 ? void 0 : _a.unassigned_dynamic_pickups) > 0) {
                                            setOpen(true);
                                            setClassN(7);
                                            setActiveClientsFilter(8);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.unassigned_dynamic_pickups
                                                ? dashboard.active_clients.unassigned_dynamic_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content bg-lightred" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.unassignedDynamicPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue ".concat(((_j = dashboard === null || dashboard === void 0 ? void 0 : dashboard.active_clients) === null || _j === void 0 ? void 0 : _j.unassigned_static_pickups) <= 0
                                        ? 'disabled'
                                        : classN === 8
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.active_clients) === null || _a === void 0 ? void 0 : _a.unassigned_static_pickups) > 0) {
                                            setOpen(true);
                                            setClassN(8);
                                            setActiveClientsFilter(9);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.unassigned_static_pickups
                                                ? dashboard.active_clients.unassigned_static_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content bg-lightred" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.unassignedStaticPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.assigned_us_pickups
                                                ? dashboard.active_clients.assigned_us_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.AssignedUSPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients.unassigned_us_pickups
                                                ? dashboard.active_clients.unassigned_us_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.UnassignedUSPickups))))),
                        React.createElement(material_1.Grid, { item: true, lg: 12, xs: 12, xl: 12, className: "cards-grids-item" },
                            React.createElement("h2", { className: "heading cards-heaidng" },
                                "Total FE",
                                React.createElement(iconButton_component_1.default, { icon: "icon-refresh", onClick: function () { return getTotalFeMetrics.refetch(); } })),
                            React.createElement("div", { className: "orderlist-main" },
                                React.createElement(material_1.Card, { className: "route-card card-main green-teritorybg disabled", onClick: function () {
                                        setTotalFEFilter(1);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalFE);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard && dashboard.total_fe && dashboard.total_fe.total_fe
                                                ? dashboard.total_fe.total_fe
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.totalFE))),
                                React.createElement(material_1.Card, { className: "route-card card-main green-teritorybg disabled", onClick: function () {
                                        setTotalFEFilter(2);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalFE);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content " },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.available_fes_as_per_roster
                                                ? dashboard.total_fe.available_fes_as_per_roster
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.AvailableFEAsPerRoster))),
                                React.createElement(material_1.Card, { className: "route-card card-main green-teritorybg disabled", onClick: function () {
                                        setTotalFEFilter(4);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalFE);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.available_dynamic_fe
                                                ? dashboard.total_fe.available_dynamic_fe
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.AvailableDynamicFEs))),
                                React.createElement(material_1.Card, { className: "route-card card-main green-teritorybg disabled", onClick: function () {
                                        setTotalFEFilter(5);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalFE);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.available_static_fe
                                                ? dashboard.total_fe.available_static_fe
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.AvailableStaticFEs))),
                                React.createElement(material_1.Card, { className: "route-card card-main green-teritorybg disabled" },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.available_ultra_static_fe
                                                ? dashboard.total_fe.available_ultra_static_fe
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.UltrastaticFe))),
                                React.createElement(material_1.Card, { className: "route-card card-main green-teritorybg disabled\n                 \n                    ", onClick: function () {
                                        setClassN(11);
                                        setTotalFEFilter(3);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalFE);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.unavailable_fes_as_per_roster
                                                ? dashboard.total_fe.unavailable_fes_as_per_roster
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.UnavailableFEsasperRoster))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled" },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.logged_in_dynamic
                                                ? dashboard.total_fe.logged_in_dynamic
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.LoggedInDynamic))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled" },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.logged_in_static
                                                ? dashboard.total_fe.logged_in_static
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.LoggedInStatic))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled" },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.logged_in_ultra_static
                                                ? dashboard.total_fe.logged_in_ultra_static
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.LoggedInUltraStatic))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled" },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.logged_in_users
                                                ? dashboard.total_fe.logged_in_users
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.LoggedInUsers))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade ".concat(((_k = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_fe) === null || _k === void 0 ? void 0 : _k.logged_out_users) <= 0
                                        ? 'disabled'
                                        : classN === 21
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_fe) === null || _a === void 0 ? void 0 : _a.logged_out_users) > 0) {
                                            setOpen(true);
                                            setClassN(21);
                                            setTotalFEFilter(6);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalFE);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.logged_out_users
                                                ? dashboard.total_fe.logged_out_users
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.LoggedOutUsers))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled" },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard && dashboard.total_fe && dashboard.total_fe.productivity
                                                ? dashboard.total_fe.productivity
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.Productivity))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled" },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.dynamic_route_productivity
                                                ? dashboard.total_fe.dynamic_route_productivity
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.DynamicRouteProductivity))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled" },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.static_route_productivity
                                                ? dashboard.total_fe.static_route_productivity
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.StaticRouteProductivity))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade\n            ".concat(((_l = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_fe) === null || _l === void 0 ? void 0 : _l.ultra_static_leaved_fe) <= 0
                                        ? 'disabled'
                                        : classN === 2
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_fe) === null || _a === void 0 ? void 0 : _a.ultra_static_leaved_fe) > 0) {
                                            setOpen(true);
                                            setClassN(2);
                                            setTotalFEFilter(7);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalFE);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_fe &&
                                                dashboard.total_fe.ultra_static_leaved_fe
                                                ? dashboard.total_fe.ultra_static_leaved_fe
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.LeavedUltrastaticFE))))),
                        React.createElement(material_1.Grid, { item: true, lg: 12, xs: 12, xl: 12, className: "cards-grids-item" },
                            React.createElement("h2", { className: "heading cards-heaidng" },
                                "On Demand Pickups",
                                React.createElement(iconButton_component_1.default, { icon: "icon-refresh", onClick: function () { return getOndemandMetrics.refetch(); } })),
                            React.createElement("div", { className: "orderlist-main" },
                                React.createElement(material_1.Card, { className: "route-card card-main bg-redshade ".concat(((_m = dashboard === null || dashboard === void 0 ? void 0 : dashboard.on_demand_pickups) === null || _m === void 0 ? void 0 : _m.on_demand_pickups) <= 0
                                        ? 'disabled'
                                        : classN === 14
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.on_demand_pickups) === null || _a === void 0 ? void 0 : _a.on_demand_pickups) > 0) {
                                            setOpen(true);
                                            setClassN(14);
                                            setOnDemandFilter(1);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickups);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content " },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.on_demand_pickups &&
                                                dashboard.on_demand_pickups.on_demand_pickups
                                                ? dashboard.on_demand_pickups.on_demand_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.OnDemandPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-redshade disabled", onClick: function () {
                                        setOnDemandFilter(2);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickups);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content " },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.on_demand_pickups &&
                                                dashboard.on_demand_pickups.on_demand_assigned_pickups
                                                ? dashboard.on_demand_pickups.on_demand_assigned_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.OnDemandPickupsAssigned))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-redshade ".concat(((_o = dashboard === null || dashboard === void 0 ? void 0 : dashboard.on_demand_pickups) === null || _o === void 0 ? void 0 : _o.on_demand_unassigned_pickups) <= 0
                                        ? 'disabled'
                                        : classN === 16
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.on_demand_pickups) === null || _a === void 0 ? void 0 : _a.on_demand_unassigned_pickups) > 0) {
                                            setOpen(true);
                                            setClassN(16);
                                            setOnDemandFilter(3);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickups);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.on_demand_pickups &&
                                                dashboard.on_demand_pickups.on_demand_unassigned_pickups
                                                ? dashboard.on_demand_pickups.on_demand_unassigned_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.OnDemandPickupUnAssigned))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-redshade ".concat(((_p = dashboard === null || dashboard === void 0 ? void 0 : dashboard.on_demand_pickups) === null || _p === void 0 ? void 0 : _p.on_demand_processing_pickups) <= 0
                                        ? 'disabled'
                                        : classN === 15
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.on_demand_pickups) === null || _a === void 0 ? void 0 : _a.on_demand_processing_pickups) > 0) {
                                            setOpen(true);
                                            setClassN(15);
                                            setOnDemandFilter(6);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickups);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.on_demand_pickups &&
                                                dashboard.on_demand_pickups.on_demand_processing_pickups
                                                ? dashboard.on_demand_pickups.on_demand_processing_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.OnDemandProcessingPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-redshade ".concat(((_q = dashboard === null || dashboard === void 0 ? void 0 : dashboard.on_demand_pickups) === null || _q === void 0 ? void 0 : _q.on_demand_rejected) <= 0
                                        ? 'disabled'
                                        : classN === 17
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.on_demand_pickups) === null || _a === void 0 ? void 0 : _a.on_demand_rejected) > 0) {
                                            setOpen(true);
                                            setClassN(17);
                                            setOnDemandFilter(5);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickups);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.on_demand_pickups &&
                                                dashboard.on_demand_pickups.on_demand_rejected
                                                ? dashboard.on_demand_pickups.on_demand_rejected
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.OnDemandReject))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-redshade disabled", onClick: function () {
                                        setOnDemandFilter(4);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickups);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.on_demand_pickups &&
                                                dashboard.on_demand_pickups.on_demand_productivity
                                                ? dashboard.on_demand_pickups.on_demand_productivity
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.OnDemandProductivity))))),
                        React.createElement(material_1.Grid, { item: true, lg: 12, xs: 12, xl: 12, className: "cards-grids-item" },
                            React.createElement("h2", { className: "heading cards-heaidng" },
                                "Total Pickups",
                                React.createElement(iconButton_component_1.default, { icon: "icon-refresh", onClick: function () { return getTotalPickupsMetrics.refetch(); } })),
                            React.createElement("div", { className: "orderlist-main" },
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setTotalPickupFilter(1);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content " },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.total_pickups
                                                ? dashboard.total_pickups.total_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.totalPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setTotalPickupFilter(2);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.total_pending_pickups
                                                ? dashboard.total_pickups.total_pending_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.TotalPendingPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setTotalPickupFilter(3);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content " },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.total_completed_pickups
                                                ? dashboard.total_pickups.total_completed_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.TotalCompletedPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setTotalPickupFilter(4);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.productive_pickups
                                                ? dashboard.total_pickups.productive_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.ProductivePickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setTotalPickupFilter(5);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.nothing_to_pickups
                                                ? dashboard.total_pickups.nothing_to_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.NothingtoPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setTotalPickupFilter(6);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.delayed_pickups
                                                ? dashboard.total_pickups.delayed_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.DelayedPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade ".concat(((_r = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_pickups) === null || _r === void 0 ? void 0 : _r.delayed_dynamic_pickups) <= 0
                                        ? 'disabled'
                                        : classN === 18
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if ((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_pickups) === null || _a === void 0 ? void 0 : _a.delayed_dynamic_pickups) {
                                            setOpen(true);
                                            setClassN(18);
                                            setTotalPickupFilter(7);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.delayed_dynamic_pickups
                                                ? dashboard.total_pickups.delayed_dynamic_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.DelayedDynamicPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade ".concat(((_s = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_pickups) === null || _s === void 0 ? void 0 : _s.delayed_static_pickups) <= 0
                                        ? 'disabled'
                                        : classN === 19
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_pickups) === null || _a === void 0 ? void 0 : _a.delayed_static_pickups) > 0) {
                                            setOpen(true);
                                            setClassN(19);
                                            setTotalPickupFilter(8);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.delayed_static_pickups
                                                ? dashboard.total_pickups.delayed_static_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.DelayedStaticPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.delayed_us_pickups
                                                ? dashboard.total_pickups.delayed_us_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.DelayedUSPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade ".concat(((_t = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_pickups) === null || _t === void 0 ? void 0 : _t.at_risk_pending_pickups) <= 0
                                        ? 'disabled'
                                        : classN === 20
                                            ? 'active-card'
                                            : ''), onClick: function () {
                                        var _a;
                                        if (((_a = dashboard === null || dashboard === void 0 ? void 0 : dashboard.total_pickups) === null || _a === void 0 ? void 0 : _a.at_risk_pending_pickups) > 0) {
                                            setOpen(true);
                                            setClassN(20);
                                            setTotalPickupFilter(9);
                                            setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                        }
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.at_risk_pending_pickups
                                                ? dashboard.total_pickups.at_risk_pending_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.AtRiskPendingPickups))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setTotalPickupFilter(10);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.on_time_pickups_percentage
                                                ? dashboard.total_pickups.on_time_pickups_percentage
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.OnTimePickup))),
                                React.createElement(material_1.Card, { className: "route-card card-main yellow-shade disabled", onClick: function () {
                                        setTotalPickupFilter(11);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.total_pickups &&
                                                dashboard.total_pickups.on_time_lab_handover_percentage
                                                ? dashboard.total_pickups.on_time_lab_handover_percentage
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.OnTimeLabHandover)))))))),
            React.createElement(material_1.Drawer, { variant: "temporary", anchor: "bottom", open: open, className: classes.customDrawer },
                React.createElement("div", { onClick: handleDrawerClose, className: "drawer-close-btn" },
                    React.createElement("span", { className: "icon-close" })),
                statName === auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients ? (React.createElement(total_active_clients_1.default, { activeClientsFilter: activeClientsFilter, selectedLab: selectedLab, PageLimit: PageLimit, payloadexport: payloadexport })) : statName === auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalFE ? (React.createElement(total_FE_1.default, { totalFEFilter: totalFEFilter, selectedLab: selectedLab, PageLimit: PageLimit, payloadexport: payloadexport })) : statName === auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalPickUps ? (React.createElement(total_pickups_1.default, { totalPickupFilter: totalPickupFilter, selectedLab: selectedLab, PageLimit: PageLimit, payloadexport: payloadexport })) :
                    statName === auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickups ? (React.createElement(on_demand_request_1.default, { onDemandFilter: onDemandFilter, selectedLab: selectedLab, PageLimit: PageLimit, payloadexport: payloadexport, setReloadPage: setReloadPage, setDrawer: setOpen })) : ('')))));
};
exports.default = ControlTowerRouting;
