"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserRoleList = exports.getRoleListByRoleId = exports.deleteRole = exports.editRole = exports.createRole = exports.getRoleList = exports.fetchFeatures = void 0;
var api_service_1 = require("../../../../api/api_service");
var array_helpers_1 = require("../../../../utils/array_helpers");
var fetchFeatures = function (endPoint, payLoad, domain) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: endPoint,
            domain: domain,
            payLoad: (0, array_helpers_1.sortObjectKeys)(payLoad),
            customHeaders: {
                'x-signature-version': 'v2'
            }
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchFeatures = fetchFeatures;
var getRoleList = function (endPoint, payLoad, domain) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: endPoint,
            domain: domain,
            payLoad: (0, array_helpers_1.sortObjectKeys)(payLoad),
            customHeaders: {
                'x-signature-version': 'v2'
            }
        })
            .then(function (response) {
            if (response.status === 200 && response.data) {
                var convertedRolesList = (0, array_helpers_1.convertRoleListFromRes)(response.data.data.result);
                return resolve(convertedRolesList);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.getRoleList = getRoleList;
var createRole = function (endPoint, payLoad, domain) {
    return new Promise(function (resolve, reject) {
        var sortedAttrubutesData = (0, array_helpers_1.sortObjectKeys)(payLoad.attributes);
        api_service_1.api_service
            .post({
            endPoint: endPoint,
            domain: domain,
            payLoad: { attributes: sortedAttrubutesData },
            customHeaders: {
                'x-signature-version': 'v2'
            }
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response ? response.data
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.createRole = createRole;
var editRole = function (endPoint, payLoad, domain) {
    return new Promise(function (resolve, reject) {
        var sortedAttrubutesData = (0, array_helpers_1.sortObjectKeys)(payLoad.attributes);
        api_service_1.api_service
            .put({
            endPoint: endPoint,
            domain: domain,
            payLoad: { attributes: sortedAttrubutesData },
            customHeaders: {
                'x-signature-version': 'v2'
            }
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.editRole = editRole;
var deleteRole = function (endPoint, domain) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .delete({
            endPoint: endPoint,
            domain: domain,
            customHeaders: {
                'x-signature-version': 'v2'
            }
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.deleteRole = deleteRole;
var getRoleListByRoleId = function (endPoint, domain) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: endPoint,
            domain: domain,
            customHeaders: {
                'x-signature-version': 'v2'
            }
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.getRoleListByRoleId = getRoleListByRoleId;
var getUserRoleList = function (endPoint, payLoad, domain) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: endPoint,
            domain: domain,
            payLoad: (0, array_helpers_1.sortObjectKeys)(payLoad),
            customHeaders: {
                'x-signature-version': 'v2'
            }
        })
            .then(function (response) {
            if (response.status === 200 && response.data) {
                var convertedRolesList = (0, array_helpers_1.convertUserRoleListFromRes)(response.data.data.result);
                return resolve(convertedRolesList);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.getUserRoleList = getUserRoleList;
