"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var config_action_1 = require("../../../../actions/config_action");
var button_component_1 = require("../../../../common/buttons/button_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var Pagenotfound_1 = require("../../../../ErrorBoundary/Pagenotfound");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var routes_list_1 = require("../../../../routes/routes_list");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../reports_helper");
var ClientSlotwiseProductivityReports = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = React.useState(''), filterFromDate = _a[0], setFromDateFilter = _a[1];
    var _b = React.useState(''), filterToDate = _b[0], setToDateFilter = _b[1];
    var _c = React.useState(false), isDateRangeUpdated = _c[0], setIsDateRangeUpdated = _c[1];
    var _d = React.useState(appConstants_1.defaultPageLimit), PageLimit = _d[0], setPageLimit = _d[1];
    var _e = React.useState(1), pageNumber = _e[0], setPageNumber = _e[1];
    var _f = React.useState([]), filterSelectedLabs = _f[0], setSelectedFilterLabs = _f[1];
    var _g = React.useState([]), isReportList = _g[0], setIsReportList = _g[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _h = React.useState(false), open = _h[0], setOpen = _h[1];
    var _j = React.useState(false), filter = _j[0], setFilter = _j[1];
    var clientSlotwiseRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.reports, routes_list_1.CLIENT_SLOTWISE_REPORT_SUB_ROUTES);
    var _k = React.useState(false), isListReloaded = _k[0], setIsListReloaded = _k[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var labHandler = function (value) {
        setSelectedFilterLabs(value);
    };
    var autogenerateReport = function () {
        var currentPage = pageNumber < 1 ? 1 : pageNumber;
        var payload = {};
        if (filterFromDate && filterToDate !== null) {
            payload['date_range'] = {
                start_date: filterFromDate,
                end_date: filterToDate,
            };
        }
        if (filterSelectedLabs.length > 0) {
            var labCode = (0, array_helpers_1.selecteAllOption)(filterSelectedLabs, labList);
            payload['lab_codes'] = labCode;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, reports_helper_1.SuccessfulVisitsGenerate)(payload)
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var FilterHandler = function () {
        setFilter(!filter);
    };
    return (React.createElement("div", { className: "fe-wrapper pay  -fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Client Slotwise Productivity Reports - Successful Visits")),
            React.createElement(button_component_1.default, { type: "button", buttonName: appConstants_1.reportDownload, endIcon: "icon-import download-icon", color: "yellow-outline", onClickHandler: autogenerateReport, isError: !isReportList.length })),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" }),
            React.createElement("div", { className: "filter-options" },
                React.createElement(text_filed_with_fromto_Daterange_1.default, { presentDate: true, onChangeHandler: callBackOnDateRangeSelect }),
                React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "Lab Code", dropDownList: labList &&
                        (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                            return a.label.localeCompare(b.label);
                        }), onChangeHnadlre: labHandler, value: filterSelectedLabs || [] }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement(react_router_dom_1.Routes, null,
                clientSlotwiseRoutes &&
                    (clientSlotwiseRoutes === null || clientSlotwiseRoutes === void 0 ? void 0 : clientSlotwiseRoutes.map(function (features) {
                        return (React.createElement(react_router_dom_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                                    isListReloaded: isListReloaded,
                                    setIsListReloaded: setIsListReloaded,
                                } },
                                React.createElement(features.component, { filterFromDate: filterFromDate, filterToDate: filterToDate, selectedLab: filterSelectedLabs, PageLimit: PageLimit, isDateRangeUpdated: isDateRangeUpdated, setPageNumber: setPageNumber, setIsReportList: setIsReportList, filter: filter })) }));
                    })),
                React.createElement(react_router_dom_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) }))),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = ClientSlotwiseProductivityReports;
