"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitPartialVisitsTransfer = exports.getVisitUploadHistoryList = exports.downloadVisits = exports.swapFE = exports.callToFeListOnlabCode = exports.callToVisistsList = void 0;
var api_service_1 = require("../../../api/api_service");
var callToVisistsList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.VISITS_LIST,
            domain: process.env.BASE,
            payLoad: Object.keys(payload).length > 0 ? payload : undefined,
        })
            .then(function (response) {
            console.log('888 callToVisistsList', response);
            if (response.data.statusCode === 200) {
                console.log('888 callToVisistsList 200', response);
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.callToVisistsList = callToVisistsList;
var callToFeListOnlabCode = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.FE_LIST_ON_LABCODE),
            payLoad: payload,
            domain: process.env.BASE,
        })
            .then(function (res) {
            console.log('888 callToFeListOnlabCode', res);
            return resolve(res);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.callToFeListOnlabCode = callToFeListOnlabCode;
var swapFE = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.FE_SWAP,
            domain: process.env.BASE,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.data.statusCode === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw (erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.swapFE = swapFE;
var downloadVisits = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.DOWNLOAD_VISITS_LIST,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (res) {
            return resolve(res.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.downloadVisits = downloadVisits;
var getVisitUploadHistoryList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.visits_Upload_History,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                resolve(res.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (err) {
            return reject(err.message);
        });
    });
};
exports.getVisitUploadHistoryList = getVisitUploadHistoryList;
var submitPartialVisitsTransfer = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Partial_Visits_Transfer,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            return resolve(res.data);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.submitPartialVisitsTransfer = submitPartialVisitsTransfer;
