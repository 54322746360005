"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
require("./details_grid.scss");
var DetailsGrid = function (_a) {
    var lableName = _a.lableName, value = _a.value, className = _a.className, grid_size = _a.grid_size;
    var type = 'string';
    return (React.createElement(Grid_1.default, null,
        React.createElement("div", { className: "details-grid-wrapper ".concat(className) },
            React.createElement("small", { className: "user-label" }, lableName),
            React.createElement("p", { className: "user-value" }, typeof value === type
                ? value.charAt(0).toUpperCase() + value.slice(1)
                : value))));
};
exports.default = DetailsGrid;
