"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../../actions/config_action");
var api_service_1 = require("../../../../api/api_service");
var button_component_1 = require("../../../../common/buttons/button_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var generic_api_calls_1 = require("../../../generic_api_calls");
var reports_helper_1 = require("../reports_helper");
var lodash_1 = require("./../../../../utils/lodash");
var OutstationMasterboxReport = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = React.useState(undefined), dataList = _a[0], setDataList = _a[1];
    var _b = React.useState(new Date().toString()), filterFromDate = _b[0], setFromDateFilter = _b[1];
    var _c = React.useState(new Date().toString()), filterToDate = _c[0], setToDateFilter = _c[1];
    var _d = React.useState(false), open = _d[0], setOpen = _d[1];
    var _e = React.useState(false), isDateRangeUpdated = _e[0], setIsDateRangeUpdated = _e[1];
    var _f = React.useState(''), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = React.useState(1), pageCount = _g[0], setpageCount = _g[1];
    var _h = React.useState(0), totalPageCount = _h[0], setTotalPageCount = _h[1];
    var _j = React.useState(appConstants_1.defaultPageLimit), PageLimit = _j[0], setPageLimit = _j[1];
    var _k = React.useState(0), totalCount = _k[0], setTotalCount = _k[1];
    var _l = React.useState([]), filterSelectedCLabs = _l[0], setSelectedFilterLabs = _l[1];
    var _m = React.useState([]), tempCityList = _m[0], setTempCityList = _m[1];
    console.log(tempCityList, 'vtempCityList');
    var navigate = (0, react_router_1.useNavigate)();
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var stateList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.stateList; });
    var _o = React.useState(null), cityvalue = _o[0], setcityvalue = _o[1];
    var _p = React.useState(null), statevalue = _p[0], setstatevalue = _p[1];
    var _q = React.useState(false), filterButton = _q[0], setFilterbutton = _q[1];
    var labCodeChangeHandler = function (val) {
        setSelectedFilterLabs(val);
    };
    console.log(cityvalue, 'cityvalue');
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var refetch = (0, react_query_1.useQuery)(['OutstationMasterbox', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (filterFromDate && filterToDate !== null) {
            payload['date_range'] = {
                start_date: moment(filterFromDate).format('YYYY-MM-DD'),
                end_date: moment(filterToDate).format('YYYY-MM-DD'),
            };
        }
        if (cityvalue != null) {
            payload['from_location'] = cityvalue.short_code;
        }
        if (filterSelectedCLabs.length > 0) {
            var labCode = (0, array_helpers_1.selecteAllOption)(filterSelectedCLabs, labList);
            payload['lab_codes'] = labCode;
        }
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payload['page'] = currentPage;
        (0, reports_helper_1.OutstationMasterReports)(payload)
            .then(function (response) {
            console.log('fjhsj', response);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setTotalCount(response.count);
            var covertedData = (0, array_helpers_1.convertOutstationMasterboxReportListFromRes)(response === null || response === void 0 ? void 0 : response.result);
            setDataList(covertedData);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            react_hot_toast_1.default.error(err.message);
            setDataList([]);
        });
    }).refetch;
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (filterFromDate && filterToDate !== null) {
            payload['date_range'] = {
                start_date: moment(filterFromDate).format('YYYY-MM-DD'),
                end_date: moment(filterToDate).format('YYYY-MM-DD'),
            };
        }
        if (cityvalue != null) {
            payload['from_location'] = cityvalue.short_code;
        }
        if (filterSelectedCLabs.length > 0) {
            var labCode = (0, array_helpers_1.selecteAllOption)(filterSelectedCLabs, labList);
            payload['lab_codes'] = labCode;
        }
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payload['page'] = currentPage;
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Outstation_Masterbox_Generate,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                setOpen(true);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [filterButton]);
    var FilterHandler = function () {
        setFilterbutton(!filterButton);
    };
    return (React.createElement("div", { className: "fe-wrapper pay  -fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Outstation Masterbox Report")),
            React.createElement(button_component_1.default, { type: "button", buttonName: appConstants_1.reportDownload, color: "yellow-outline", isError: (dataList === null || dataList === void 0 ? void 0 : dataList.length) > 0 ? false : true, endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler })),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" }),
            React.createElement("div", { className: "filter-options" },
                React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true }),
                React.createElement(drop_down_component_1.default, { name: "state", placeholder: "Select State", label: "Select State", dropDownList: stateList, onChange: function (val) {
                        setstatevalue(val);
                        (0, generic_api_calls_1.fetchCitiesUnderStates)([val.id]).then(function (res) {
                            var cityFilteredByState = lodash_1.default.sortBy(res, 'short_code');
                            setTempCityList(cityFilteredByState);
                        });
                    }, value: statevalue || null, disableClearable: true }),
                React.createElement(drop_down_component_1.default, { name: "city", placeholder: "Select City", label: "Select City", required: true, dropDownList: __spreadArray([], tempCityList, true), onChange: function (val) {
                        setcityvalue(val);
                    }, value: cityvalue || null, key: tempCityList.toString(), disableClearable: true }),
                React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "Lab Code", dropDownList: labList &&
                        (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                            return a.label.localeCompare(b.label);
                        }), onChangeHnadlre: labCodeChangeHandler, value: filterSelectedCLabs || [] }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.Outstation_Master_Box_REPORT_COLUMNS, rows: dataList, errorMessage: errorMessage, pagination: false, className: 'leave-tracking-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = OutstationMasterboxReport;
