"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.api_service = void 0;
var axios_1 = require("axios");
var jwt_decode_1 = require("jwt-decode");
var react_hot_toast_1 = require("react-hot-toast");
var ToastPosition = require("react-toastify");
var universal_cookie_1 = require("universal-cookie");
var uuid_1 = require("uuid");
var actions_1 = require("../actions");
var config_action_1 = require("../actions/config_action");
var user_action_1 = require("../actions/user_action");
var app_config_1 = require("../config/app_config");
var appConstants_1 = require("../constants/appConstants");
var index_1 = require("../store/index");
var lodash_1 = require("./../utils/lodash");
var api_urls_1 = require("./api_urls");
var query_string_1 = require("query-string");
var md5 = require('md5');
var cookies = new universal_cookie_1.default();
var APIService = (function () {
    function APIService() {
        var _this = this;
        this.api_urls = api_urls_1.api_urls;
        this.axiosOptions = {
            timeout: 30000,
            withCredentials: true,
        };
        this.ContentHeaders = {
            Json: 'application/json',
            FormData: 'multipart/form-data',
            Plain: 'text/plain',
        };
        this.BaseDomain = {
            BASE: process.env.VENDOR_BASE_URL,
            APP_ID: process.env.APP_ID,
            DEVICE_TYPE: process.env.DEVICE_TYPE,
            APP_VERSION: process.env.APP_VERSION,
            SECRET_KEY: process.env.SECRET_KEY,
            CLIENT_REVIEW_DOMAIN: process.env.CLIENT_REVIEW_DOMAIN,
        };
        this.isNotificationCall = false;
        this.noOfRequests = 0;
        this.post = function (data) {
            if (!data.domain) {
                data.domain = _this.BaseDomain.BASE;
            }
            if (!data.headerType) {
                data.headerType = _this.ContentHeaders.Json;
            }
            if (data.showLoader != false) {
                data.showLoader = true;
            }
            if (!data.useNonInterceptor) {
                data.useNonInterceptor = false;
            }
            if (data.showLoader) {
                index_1.store.dispatch(actions_1.default.loader_Actions.showLoader(data.showLoader));
            }
            if (data.useNonInterceptor) {
                var payLoadData = data.payLoad instanceof FormData
                    ? data.payLoad
                    : JSON.stringify(data.payLoad);
                return _this.axiosNoInterceptor.post(data.endPoint, payLoadData, {
                    timeout: _this.axiosOptions.timeout,
                    transformRequest: _this.axiosOptions.transformRequest,
                    baseURL: data.domain,
                    headers: _this.getHeadersByType(data.headerType, data.domain, data.customHeaders),
                });
            }
            else {
                var payLoadData = data.payLoad instanceof FormData
                    ? data.payLoad
                    : JSON.stringify(data.payLoad);
                return axios_1.default.post(data.endPoint, payLoadData, {
                    timeout: _this.axiosOptions.timeout,
                    transformRequest: _this.axiosOptions.transformRequest,
                    baseURL: data.domain,
                    headers: _this.getHeadersByType('post', data.headerType, data.domain, data.customHeaders, data.payLoad),
                });
            }
        };
        this.isNetworkError = function (error) {
            return !error.response && error.code !== 'ECONNABORTED';
        };
        this.retry = function (config) {
            return (0, axios_1.default)(config);
        };
        this.put = function (data) {
            if (!data.domain) {
                data.domain = _this.BaseDomain.BASE;
            }
            if (!data.headerType) {
                data.headerType = _this.ContentHeaders.Json;
            }
            if (data.showLoader !== false) {
                data.showLoader = true;
            }
            if (data.showLoader) {
                index_1.store.dispatch(actions_1.default.loader_Actions.showLoader(data.showLoader));
            }
            var payLoadData = data.payLoad instanceof FormData
                ? data.payLoad
                : JSON.stringify(data.payLoad);
            return axios_1.default.put(data.endPoint, payLoadData, {
                timeout: _this.axiosOptions.timeout,
                transformRequest: _this.axiosOptions.transformRequest,
                baseURL: data.domain,
                headers: _this.getHeadersByType('put', data.headerType, data.domain, data.customHeaders, data.payLoad),
            });
        };
        this.delete = function (data) {
            if (!data.domain) {
                data.domain = _this.BaseDomain.BASE;
            }
            if (!data.headerType) {
                data.headerType = _this.ContentHeaders.Json;
            }
            if (data.showLoader !== false) {
                data.showLoader = true;
            }
            if (data.showLoader) {
                index_1.store.dispatch(actions_1.default.loader_Actions.showLoader(data.showLoader));
            }
            return axios_1.default.delete(data.endPoint, {
                baseURL: data.domain,
                headers: _this.getHeadersByType('delete', data.headerType, data.domain, data.customHeaders),
            });
        };
        this.get = function (data) {
            if (!data.domain) {
                data.domain = _this.BaseDomain.BASE;
            }
            if (!data.headerType) {
                data.headerType = _this.ContentHeaders.Json;
            }
            if (data.showLoader || data.showLoader === undefined) {
                data.showLoader = true;
            }
            if (data.showLoader) {
                index_1.store.dispatch(actions_1.default.loader_Actions.showLoader(data.showLoader));
            }
            try {
                return axios_1.default.get(data.endPoint, {
                    baseURL: data.domain,
                    timeout: _this.axiosOptions.timeout,
                    params: data.payLoad,
                    headers: _this.getHeadersByType('delete', data.headerType, data.domain, data.customHeaders),
                });
            }
            catch (e) {
                console.error('axios get::', e);
            }
        };
        this.uploadFile = function (data) {
            if (!data.domain) {
                data.domain = _this.BaseDomain.BASE;
            }
            if (!data.headerType) {
                data.headerType = _this.ContentHeaders.Json;
            }
            if (data.showLoader !== false) {
                data.showLoader = true;
            }
            if (!data.useNonInterceptor) {
                data.useNonInterceptor = false;
            }
            if (data.useNonInterceptor) {
                var payLoadData = data.payLoad instanceof FormData
                    ? data.payLoad
                    : JSON.stringify(data.payLoad);
                return _this.axiosNoInterceptor.post(data.endPoint, payLoadData, {
                    timeout: _this.axiosOptions.timeout,
                    transformRequest: _this.axiosOptions.transformRequest,
                    baseURL: data.domain,
                    headers: _this.getHeadersByType(data.headerType, data.domain, data.customHeaders),
                });
            }
            else {
                var payLoadData = data.payLoad instanceof FormData
                    ? data.payLoad
                    : JSON.stringify(data.payLoad);
                return axios_1.default.post(data.endPoint, payLoadData, {
                    timeout: _this.axiosOptions.timeout,
                    transformRequest: _this.axiosOptions.transformRequest,
                    baseURL: data.domain,
                    headers: _this.getHeadersByType('post', data.headerType, data.domain, data.customHeaders, data.signatureKeys),
                });
            }
        };
        this.axiosNoInterceptor = axios_1.default.create();
        axios_1.default.interceptors.request.use(function (request) {
            var _a;
            if (request.baseURL === process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL) {
                var parameters = undefined;
                if (request.params) {
                    parameters = request.params;
                }
                var urlArray = request.url.split('?');
                if (urlArray && urlArray.length > 1) {
                    var routeParams = query_string_1.default.parse(urlArray[1]);
                    parameters = routeParams;
                }
                var signatureData = _this.generateSignature({ authorization: (_a = request.headers) === null || _a === void 0 ? void 0 : _a['x-auth-token'], payload: request.data, params: parameters });
                if (signatureData) {
                    request.headers['x-signature'] = signatureData.signature;
                    request.headers['x-nonce'] = signatureData.nonce;
                }
                _this.incrementRequestCount();
                return request;
            }
            _this.incrementRequestCount();
            return request;
        }, function (error) {
            return _this.requestPromise(error);
        });
        axios_1.default.interceptors.response.use(function (response) {
            _this.decrementRequestCount();
            if (response.status === 200) {
            }
            return response;
        }, function (error) {
            var _a, _b, _c, _d, _e, _f;
            _this.decrementRequestCount();
            if (error.response) {
                console.log('initial error', error);
                if (error.response.status === 403) {
                    exports.api_service
                        .post({
                        endPoint: api_urls_1.api_urls.REFRESH_TOKEN,
                        payLoad: cookies.get('cookies_refresh_token'),
                        showLoader: true,
                        domain: process.env.AUTHENTICATION_SERVER_URL,
                    })
                        .then(function (res) {
                        if (res.data.status === 200) {
                            index_1.store.dispatch(user_action_1.default.setLoginStatus({
                                loginStatus: true,
                            }));
                            var decoded_token = (0, jwt_decode_1.default)(res.data.data.token);
                            var decoded_refresh_token = (0, jwt_decode_1.default)(res.data.data.refreshToken);
                            cookies.set('cookies_jwt_token', res.data.data.token, {
                                expires: new Date(decoded_token.exp * 1000),
                                secure: true,
                                httpOnly: false,
                                sameSite: true,
                            });
                            cookies.set('cookies_refresh_token', res.data.data.refreshToken, {
                                expires: new Date(decoded_refresh_token.exp * 1000),
                                secure: true,
                                httpOnly: false,
                                sameSite: true,
                            });
                        }
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error('Session expired. please re-login to continue!');
                    });
                    return error.response;
                }
                if (error.response.status === 406) {
                    console.log((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'error.response');
                    var erroMessageRes = (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message;
                    var staticErr = 'Session expired. please re-login to continue!';
                    var errorMessage = erroMessageRes ? erroMessageRes : staticErr;
                    react_hot_toast_1.default.error(errorMessage, {
                        duration: 10 * 1000,
                        position: ToastPosition.toast.POSITION.TOP_RIGHT,
                    });
                    index_1.store.dispatch(user_action_1.default.setLoginStatus({
                        loginStatus: false,
                        sessionExpired: true,
                    }));
                    cookies.remove('cookies_jwt_token');
                    cookies.remove('cookies_refresh_token');
                    index_1.store.dispatch((0, config_action_1.featchCurrentActiveMenu)({
                        mainModule: '',
                        subModule: '',
                        subChaildModule: '',
                    }));
                    window.location.replace('/');
                    return error.response;
                }
                if (error.response.status === 409 || error.response.status === 401) {
                    if (!cookies.get('cookies_jwt_token')) {
                        var errorMessage = error.response.data.message == 'Sorry! Authentication token is missing' ? 'Session expired. please re-login to continue!' : error.response.data.message;
                        if (error.response.data.message == 'Sorry! Authentication token is missing') {
                            react_hot_toast_1.default.error(errorMessage, {
                                duration: 10 * 1000,
                                position: ToastPosition.toast.POSITION.TOP_RIGHT,
                            });
                            index_1.store.dispatch(user_action_1.default.setLoginStatus({
                                loginStatus: false,
                                sessionExpired: true,
                            }));
                            cookies.remove('cookies_jwt_token');
                            cookies.remove('cookies_refresh_token');
                            index_1.store.dispatch((0, config_action_1.featchCurrentActiveMenu)({
                                mainModule: '',
                                subModule: '',
                                subChaildModule: '',
                            }));
                            window.location.replace('/');
                        }
                    }
                    return error.response;
                }
                if (error.response.status === 400) {
                    console.log(error.response.data.message, 'DSsvsdds');
                    if (!cookies.get('cookies_jwt_token') &&
                        appConstants_1.X_AUTH_TOKEN_REQUIRED == ((_f = (_e = error.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.message)) {
                        var errorMessage = 'Session expired. please re-login to continue!';
                        react_hot_toast_1.default.error(errorMessage, {
                            duration: 10 * 1000,
                            position: ToastPosition.toast.POSITION.TOP_RIGHT,
                        });
                        index_1.store.dispatch(user_action_1.default.setLoginStatus({
                            loginStatus: false,
                            sessionExpired: true,
                        }));
                        cookies.remove('cookies_jwt_token');
                        cookies.remove('cookies_refresh_token');
                        index_1.store.dispatch((0, config_action_1.featchCurrentActiveMenu)({
                            mainModule: '',
                            subModule: '',
                            subChaildModule: '',
                        }));
                        window.location.replace('/');
                    }
                    return error.response;
                }
                if (error.response.status === 404) {
                    return error.response;
                }
                if (error.response.status === 500) {
                    return error.response;
                }
            }
            return _this.responsePromise(error);
        });
    }
    APIService.prototype.responsePromise = function (res) {
        return new Promise(function (resolve, reject) {
            if (res.response) {
                resolve(res);
            }
            else {
                reject(res);
            }
        });
    };
    APIService.prototype.requestPromise = function (res) {
        return new Promise(function (resolve, reject) {
            if (res.response) {
                resolve(res);
            }
            else {
                reject(res);
            }
        });
    };
    APIService.prototype.incrementRequestCount = function () {
        this.noOfRequests++;
    };
    APIService.prototype.decrementRequestCount = function () {
        this.noOfRequests--;
        if (this.noOfRequests === 0) {
            index_1.store.dispatch(actions_1.default.loader_Actions.showLoader(false));
        }
    };
    APIService.prototype.generateSignature = function (data) {
        try {
            var nonce = (0, uuid_1.v4)();
            var secretKey = this.BaseDomain.SECRET_KEY;
            var apiData = '';
            if (data.authorization) {
                apiData = data.authorization;
            }
            apiData = apiData + secretKey + nonce;
            if (data.payload && Object.keys(data.payload).length > 0) {
                apiData +=
                    typeof data.payload === 'string'
                        ? data.payload
                        : JSON.stringify(data.payload);
            }
            if (data.params && data.params !== null && Object.keys(data.params).length > 0) {
                if (Object.keys(data.params).length > 0) {
                    var paramsKeys = Object.keys(data.params);
                    var finalObj_1 = {};
                    __spreadArray([], paramsKeys, true).map(function (key) {
                        var _a;
                        finalObj_1[key] = (_a = data.params[key]) === null || _a === void 0 ? void 0 : _a.toString();
                    });
                    apiData += typeof data.params === 'string' ? data.params : JSON.stringify(finalObj_1);
                }
            }
            var encodedSignature = md5(apiData);
            return {
                signature: encodedSignature,
                nonce: nonce,
            };
        }
        catch (error) {
            return error;
        }
    };
    APIService.prototype.getHeadersByType = function (requestMethod, headerType, domain, customHeaders, payload) {
        var data = {};
        switch (headerType) {
            case this.ContentHeaders.Json: {
                data['Content-Type'] = 'application/json';
                data['Accept'] = 'application/json';
                break;
            }
            case this.ContentHeaders.Plain: {
                data['Content-Type'] = 'text/plain';
                break;
            }
            case this.ContentHeaders.FormData: {
                data['Content-Type'] = 'multipart/form-data';
                break;
            }
            default:
                data['Content-Type'] = 'application/json';
                break;
        }
        data['x-source'] = app_config_1.appConfig.source;
        data['x-language'] = app_config_1.appConfig.lang;
        data['x-app-id'] = this.BaseDomain.APP_ID;
        data['x-device-type'] = this.BaseDomain.DEVICE_TYPE;
        data['x-app-version'] = process.env.APP_VERSION;
        data['x-timezone'] = app_config_1.appConfig.timezone;
        if (cookies.get('cookies_jwt_token')) {
            data['x-auth-token'] = cookies.get('cookies_jwt_token') || null;
        }
        else if (customHeaders && customHeaders['x-auth-token']) {
            data['x-auth-token'] = customHeaders['x-auth-token'];
        }
        var payloadData = payload !== undefined ? payload : {};
        var signatureData = this.generateSignature({
            authorization: data['x-auth-token'],
            payload: payloadData,
        });
        data['x-signature'] = signatureData.signature;
        data['x-nonce'] = signatureData.nonce;
        data = lodash_1.default.extend({}, data, customHeaders);
        return data;
    };
    return APIService;
}());
exports.api_service = new APIService();
