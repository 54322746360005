"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var CancelOutlined_1 = require("@mui/icons-material/CancelOutlined");
var React = require("react");
var CrossIconButton = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn", onClick: onClick },
            React.createElement(CancelOutlined_1.default, { sx: { fontSize: '30px', color: '#d32f2f' } }))));
};
exports.default = CrossIconButton;
