"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var config_action_1 = require("../../../actions/config_action");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var search_component_1 = require("../../../common/search/search_component");
var tabs_component_1 = require("../../../common/tabs/tabs_component");
var text_field_with_datepicker_1 = require("../../../common/text_filed/text_field_with_datepicker");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var tabs_data_1 = require("../../../constants/tabs_data");
var Pagenotfound_1 = require("../../../ErrorBoundary/Pagenotfound");
var menu_constants_1 = require("../../../menuList/menu_constants");
var routes_list_1 = require("../../../routes/routes_list");
var array_helpers_1 = require("../../../utils/array_helpers");
var state_city_filter_1 = require("../sample_traking/state_city_filter");
var MisReports = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = React.useState([]), filterStateCity = _a[0], setFilterStateCity = _a[1];
    var _b = React.useState(''), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = React.useState(new Date().toString()), filterFromDate = _c[0], setFromDateFilter = _c[1];
    var _d = React.useState(new Date().toString()), filterToDate = _d[0], setToDateFilter = _d[1];
    var _e = React.useState(), singledate = _e[0], Setsingledate = _e[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _f = React.useState([]), filetrLabs = _f[0], setFilteredLabs = _f[1];
    var _g = React.useState(false), isListReloaded = _g[0], setIsListReloaded = _g[1];
    var _h = React.useState(null), activeTab = _h[0], setActiveTab = _h[1];
    var _j = React.useState(false), open = _j[0], setOpen = _j[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var pathName = (0, react_router_dom_1.useLocation)().pathname.includes('/feRoute');
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.misReports);
    var filteredTabs = (0, array_helpers_1.disableTabs)(tabs_data_1.TABS_DATA.MIS_REPORTS_TABS_LIST, features);
    var _k = React.useState(appConstants_1.defaultPageLimit), PageLimit = _k[0], setPageLimit = _k[1];
    var misReportsRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.misReports, routes_list_1.MIST_SUB_ROUES);
    var _l = React.useState(true), disableDumpDownload = _l[0], setDisableDumpDownload = _l[1];
    var _m = React.useState(false), isDateRangeUpdated = _m[0], setIsDateRangeUpdated = _m[1];
    var stateCityHandler = function (event) {
        if (event.length >= 0) {
            setFilterStateCity(event);
        }
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var callbackUpdateSelectedStates = function (value) {
        if (value.length >= 0) {
            setFilteredLabs(value);
        }
    };
    var dumpDownloadHandler = function () {
        var currentPage = 1;
        var payload = {};
        if (filterFromDate && filterToDate) {
            var dateRangeArr = {
                start_date: moment(filterFromDate).format('YYYY-MM-DD'),
                end_date: moment(filterToDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (searchTerm) {
            payload['search_string'] = searchTerm;
        }
        if ((filterStateCity === null || filterStateCity === void 0 ? void 0 : filterStateCity.length) > 0) {
            var states_1 = [];
            var cities_1 = [];
            filterStateCity === null || filterStateCity === void 0 ? void 0 : filterStateCity.map(function (selectedCityObj) {
                if (!states_1.includes(selectedCityObj.state_name)) {
                    states_1.push(selectedCityObj.state_name);
                }
                cities_1.push(selectedCityObj.short_code);
            });
            payload['states'] = states_1;
            payload['cities'] = cities_1;
        }
        if (filetrLabs.length > 0) {
            var selecedLabcodes = filetrLabs.map(function (lab) { return lab.value.toString(); });
            payload['lab_codes'] = selecedLabcodes;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        api_service_1.api_service
            .post({
            endPoint: activeTab === 0
                ? api_service_1.api_service.api_urls.MIS_AUTOGENERATE
                : api_service_1.api_service.api_urls.MIS_FEROUTE_AUTOGENERATE,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message.includes('Sorry! No results found')) {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    React.useEffect(function () {
        if (pathName) {
            setActiveTab(1);
        }
        else {
            setActiveTab(0);
        }
    }, []);
    var disbaleDownloadButton = function () {
        if (activeTab === 0) {
            return !features[menu_constants_1.FEATURE_CONSTANTS.eventPerformance];
        }
        else {
            return !features[menu_constants_1.FEATURE_CONSTANTS.feRouteDownload];
        }
    };
    var disableDumpDownloadHandler = function (val) {
        setDisableDumpDownload(val);
    };
    var tabChangeHandler = function (val) {
        setSearchTerm('');
        setActiveTab(val);
        setFromDateFilter(new Date().toString());
        setToDateFilter(new Date().toString());
        setFilterStateCity([]);
        setFilteredLabs([]);
        setPageLimit(appConstants_1.defaultPageLimit);
    };
    return (React.createElement("div", { className: "orderlist-main-wrapper mis-reports-page" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", { className: "title" }, "MIS Reports")),
            React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, color: "yellow-outline", endIcon: "icon-import download-icon", isError: disableDumpDownload || disbaleDownloadButton(), onClickHandler: dumpDownloadHandler })),
        React.createElement("div", { className: "filter-menu-wrapper border-bottom " },
            React.createElement("div", { className: "search-filter" },
                React.createElement(search_component_1.default, { placeholder: "Search FE Name", searchValue: searchTerm, searchHandler: searchingHandler, activetab: activeTab })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(state_city_filter_1.default, { onChangeHandler: stateCityHandler, misreports: true, activetab: activeTab }),
                activeTab === 0 ? (React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true, key: activeTab })) : (React.createElement("div", { className: "filter-optionsinput-wrapper" },
                    React.createElement(text_field_with_datepicker_1.default, { label: "Select Date", value: singledate, disableManulaEntry: true, onChange: function (dateValue) {
                            Setsingledate(moment(dateValue).format('YYYY-MM-DD'));
                        }, isFutureDisable: false }))),
                React.createElement(multi_selector_1.default, { label: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList), onChangeHnadlre: callbackUpdateSelectedStates, value: filetrLabs }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }))),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement(tabs_component_1.default, { tabList: filteredTabs, tabChangeHandler: tabChangeHandler }),
            React.createElement(react_router_dom_1.Routes, null,
                misReportsRoutes &&
                    misReportsRoutes.map(function (features) {
                        return (React.createElement(react_router_dom_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                                    isListReloaded: isListReloaded,
                                    setIsListReloaded: setIsListReloaded,
                                } },
                                React.createElement(features.component, { searchTerm: searchTerm, selectedCities: filterStateCity, filterFromDate: filterFromDate, filterToDate: filterToDate, filetrLabs: filetrLabs, singledate: singledate, PageLimit: PageLimit, disableDumpDownloadHandler: disableDumpDownloadHandler, isDateRangeUpdated: isDateRangeUpdated })) }));
                    }),
                React.createElement(react_router_dom_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) }))),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = MisReports;
