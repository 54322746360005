"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchRegionMappingUpdateList = exports.fetchUserMappingUpdateList = exports.createUserMappingList = exports.createRegionMappingList = exports.fetchUserMappingList = exports.fetchRegionList = void 0;
var api_service_1 = require("../../api/api_service");
var fetchRegionList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Region_List,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchRegionList = fetchRegionList;
var fetchUserMappingList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.User_Mapping_List,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchUserMappingList = fetchUserMappingList;
var createRegionMappingList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Region_Cretion,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.createRegionMappingList = createRegionMappingList;
var createUserMappingList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.User_Region_Cretion,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.createUserMappingList = createUserMappingList;
var fetchUserMappingUpdateList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.User_Region_Update,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchUserMappingUpdateList = fetchUserMappingUpdateList;
var fetchRegionMappingUpdateList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Region_Management_Update,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchRegionMappingUpdateList = fetchRegionMappingUpdateList;
