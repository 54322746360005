"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../actions/config_action");
var api_service_1 = require("../../api/api_service");
var button_component_1 = require("../../common/buttons/button_component");
var drop_down_component_1 = require("../../common/drop_down/drop_down_component");
var modal_component_1 = require("../../common/modal/modal_component");
var pop_up_1 = require("../../common/modal/pop-up");
var radio_buttons_1 = require("../../common/radio_buttons/radio_buttons");
var tabs_component_1 = require("../../common/tabs/tabs_component");
var appConstants_1 = require("../../constants/appConstants");
var route_path_1 = require("../../constants/route_path");
var tabs_data_1 = require("../../constants/tabs_data");
var Pagenotfound_1 = require("../../ErrorBoundary/Pagenotfound");
var menu_constants_1 = require("../../menuList/menu_constants");
var routes_list_1 = require("../../routes/routes_list");
var array_helpers_1 = require("../../utils/array_helpers");
require("./fe-page.scss");
var filters_component_1 = require("./fe_list/filters_component");
var fe_services_1 = require("./fe_services");
var FEManagement = function () {
    var FeManagementfeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.feManagement);
    console.log('FeManagementfeatures:', FeManagementfeatures);
    var _a = React.useState(''), searchVlaue = _a[0], setSearchValue = _a[1];
    var _b = React.useState(''), errorMsg = _b[0], setErrorMessage = _b[1];
    var _c = React.useState(null), FEIdToBeReplaced = _c[0], setFEIdToBeReplaced = _c[1];
    var _d = React.useState([]), selectedCities = _d[0], setSelectedCites = _d[1];
    var _e = React.useState(), cityList = _e[0], setCityList = _e[1];
    var _f = React.useState(), cityAndState = _f[0], setCityAndAState = _f[1];
    var _g = React.useState(), exitedlist = _g[0], setExitedList = _g[1];
    var _h = React.useState(false), isListReloaded = _h[0], setIsListReloaded = _h[1];
    var _j = React.useState(false), popUpOpen = _j[0], setPopUpOpen = _j[1];
    var _k = React.useState(appConstants_1.defaultPageLimit), PageLimit = _k[0], setPageLimit = _k[1];
    var _l = React.useState(0), activeTab = _l[0], setActiveTab = _l[1];
    var _m = React.useState(false), isFilterButton = _m[0], setIsFilterButton = _m[1];
    var navigate = (0, react_router_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _o = React.useState('newFe'), selectedRadioButton = _o[0], setSelectedRadioButton = _o[1];
    var _p = React.useState(false), open = _p[0], setOpen = _p[1];
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var handleOpen = function () {
        setOpen(true);
        fetchExitFE(api_service_1.api_service.api_urls.FE_LIST, {
            onboard_status: [appConstants_1.FE_USER_TYPES.inactive, appConstants_1.FE_USER_TYPES.exited],
        }, process.env.FE_LIST_BASE_URL);
    };
    var handleClose = function () {
        setOpen(false);
        setSelectedRadioButton('newFe');
        setErrorMessage('');
        setFEIdToBeReplaced(null);
    };
    var tabs = tabs_data_1.TABS_DATA.FE_TABS_LIST;
    var feManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.feManagement);
    var errMsg = 'Please select one FE';
    var labels = [
        {
            label: 'New FE',
            value: 'newFe',
            disabled: !feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.createFE],
        },
        {
            label: 'Replace FE',
            value: 'replacefe',
            disabled: !feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.replaceFE],
        },
    ];
    var filteredTabs = (0, array_helpers_1.disableTabs)(tabs, feManagementFeatures);
    var nav = (0, react_router_1.useNavigate)();
    React.useEffect(function () {
        if (!FEIdToBeReplaced) {
            setErrorMessage(errMsg);
        }
        else {
            setErrorMessage('');
        }
    }, [FEIdToBeReplaced]);
    var AddNewFEHandler = function (e) {
        e.preventDefault();
        if (selectedRadioButton === 'newFe') {
            nav("".concat(route_path_1.default.PATHS.feRegistration));
        }
        else {
            if (FEIdToBeReplaced) {
                nav("".concat(route_path_1.default.PATHS.feReplaceRegistration, "/").concat(FEIdToBeReplaced.id, "/").concat(FEIdToBeReplaced.fe_code));
            }
            else {
                setErrorMessage(errMsg);
            }
        }
    };
    var feManagementRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.feManagement, routes_list_1.FE_MANGEMENT_LIST_SUB_ROUTES);
    var searchData = function (event) {
        setSearchValue(event.target.value.trim());
    };
    var cityHandlere = function (value) {
        var cities = [];
        var cityAndStateData = [];
        value.map(function (val) {
            cities.push(val.label.split(',')[0]);
            console.log();
            cityAndStateData.push({
                city: val.label.split(',')[0],
                state: val.state_name,
            });
            setCityAndAState(cityAndStateData);
        });
        setSelectedCites(cities);
        setCityList(value);
    };
    var makeCityEmptyHandler = function () {
        setCityList([]);
        setSelectedCites([]);
    };
    var activeTabChangeHandler = function (index) {
        setActiveTab(index);
        setSearchValue('');
        setCityList([]);
        setSelectedCites([]);
    };
    var fetchExitFE = function (endPoint, payLoad, domain) {
        (0, fe_services_1.fetchFEList)(endPoint, payLoad, domain)
            .then(function (response) {
            setExitedList(response.result.map(function (details) { return ({
                label: String("".concat(details.first_name, "  ").concat(details.last_name, ", ").concat(details.mobile, ", ").concat(details.state, ", ").concat(details.city)),
                id: String(details.id),
                fe_code: details.fe_id,
            }); }));
        })
            .catch(function (err) {
            setErrorMessage(err);
            setExitedList([]);
        });
    };
    var autoGenerateReportHandler = function () {
        var payload = {};
        payload['onboard_status'] = [
            'Active',
            'Draft',
            'Exited',
            'In-Active',
            'Pending for approval',
            'Approved',
            'Rejected',
        ];
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.FEMANAGEMENT_AUTOGENERATE,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setPopUpOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "FE Management")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "download", isError: !feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.FE_LIST_DOWNLOAD], endIcon: "icon-import download-icon", color: "yellow-outline", onClickHandler: autoGenerateReportHandler }),
                React.createElement(modal_component_1.default, { buttonName: "ADD FE", buttonColor: "yellow", onClickHandler: handleOpen, open: open, isBtnDisabled: !feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.createFE] },
                    React.createElement("div", { className: "modal-header header-wrapper" },
                        React.createElement("h2", { className: "heading" }, "Choose FE Onboarding Type"),
                        React.createElement("p", { className: "sub-heading" }, "New FE/Replacement of FE")),
                    React.createElement("div", { className: "modal-body small-modal" },
                        React.createElement("form", { className: "input-fileds-wrapper", onSubmit: AddNewFEHandler },
                            React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                                React.createElement(Grid_1.default, { item: true, xs: 12 },
                                    React.createElement(radio_buttons_1.default, { lableNames: labels, onchange: function (event) {
                                            setErrorMessage('');
                                            setFEIdToBeReplaced(null);
                                            setSelectedRadioButton(event.target.value);
                                        }, defaultValue: 'newFe' })),
                                selectedRadioButton === 'replacefe' && (React.createElement(Grid_1.default, { item: true, xs: 8 },
                                    React.createElement(drop_down_component_1.default, { placeholder: "Search by FE", dropDownList: exitedlist, onChange: function (val) {
                                            setFEIdToBeReplaced(val);
                                            dispatch((0, config_action_1.fetch_exit_fe_id)(val));
                                        }, value: FEIdToBeReplaced }),
                                    errorMsg && React.createElement("p", { className: "error" }, errorMsg)))),
                            React.createElement("div", { className: "modal-footer" },
                                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose }),
                                React.createElement(button_component_1.default, { buttonName: "Continue", type: "submit", color: "yellow" }))))))),
        React.createElement(filters_component_1.default, { searchValue: searchVlaue, searchHadler: searchData, cityHandlere: cityHandlere, citiesValues: cityList && cityList, makeCityEmpty: makeCityEmptyHandler, CurrentPagelimit: PageLimit, onPageLimitChange: handlePageLimitChange, displayNoOfRowFiler: true, activetab: activeTab, setIsFilterButton: setIsFilterButton, isFilterButton: isFilterButton }),
        React.createElement("div", { className: "scrollable-content fe-managment-screen" },
            React.createElement(tabs_component_1.default, { tabList: filteredTabs, tabChangeHandler: activeTabChangeHandler }),
            React.createElement(react_router_1.Routes, null,
                feManagementRoutes &&
                    feManagementRoutes.map(function (features) {
                        return (React.createElement(react_router_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                                    isListReloaded: isListReloaded,
                                    setIsListReloaded: setIsListReloaded,
                                } },
                                React.createElement(features.component, { searchValue: searchVlaue, selectedCities: selectedCities, cityAndState: cityAndState, CurrentPagelimit: PageLimit, isFilterButton: isFilterButton })) }));
                    }),
                React.createElement(react_router_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) }))),
        React.createElement(pop_up_1.default, { open: popUpOpen },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setPopUpOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = FEManagement;
