"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var cc_timeSlots_1 = require("../../../../common/cc_timeslots/cc_timeSlots");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var modal_component_1 = require("../../../../common/modal/modal_component");
var profile_grid_1 = require("../../../../common/profile_grid/profile_grid");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
require("../../../field_exicutive/slot_blocking/slot_booking.scss");
var CC_api_calls_1 = require("../../CC/CC_api_calls");
require("../control_center.tsx");
var control_center_api_calls_1 = require("../control_center_api_calls");
require("./onDemandRequest.scss");
var slotblocking_apicalls_1 = require("../../../field_exicutive/slot_blocking/slotblocking.apicalls");
var moment = require("moment");
var slotblocking_helper_1 = require("../../../field_exicutive/slot_blocking/slotblocking.helper");
var Delete_1 = require("@mui/icons-material/Delete");
var InfoOutlined_1 = require("@mui/icons-material/InfoOutlined");
var material_1 = require("@mui/material");
var fe_services_1 = require("../../../field_exicutive/fe_services");
var api_service_1 = require("../../../../api/api_service");
var pop_up_1 = require("../../../../common/modal/pop-up");
var OnDemandRequest = function () {
    var sso_user_details = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).sso_user_details;
    var is_sso_user = (0, react_redux_1.useSelector)(function (state) { return state.user; }).is_sso_user;
    var routing_types_list = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.routing_assignment_type; });
    var ultrastatic_route_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.ultra_static_route_type; });
    var updated_route_types = ultrastatic_route_types.map(function (route) { return (__assign(__assign({}, route), { label: route.label.replace(/_/g, ' ') })); });
    var pathname = (0, react_router_1.useLocation)().pathname;
    var _a = React.useState([]), warehouseCodeList = _a[0], setWarehouseCodeList = _a[1];
    var routeTypes = [{ id: 1, label: 'Static & Dymanic', value: 'Static & Dymanic' }, { id: 2, label: 'Ultrastatic', value: 'ULTRA_STATIC' }];
    var _b = React.useState(false), isUltraStatic = _b[0], setIsUltraStatic = _b[1];
    var _c = React.useState([]), ultrastaticFEList = _c[0], setUltrastaticFEList = _c[1];
    var _d = React.useState(false), open = _d[0], setOpen = _d[1];
    var _e = React.useState(false), confirmationModal = _e[0], setConfirmationModal = _e[1];
    var _f = React.useState(0), selectedSlotId = _f[0], setSelectedSlotId = _f[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var nav = (0, react_router_1.useNavigate)();
    var _g = React.useState(), CCName = _g[0], setCCName = _g[1];
    var _h = React.useState([]), existingTimeSlots = _h[0], setExistingTimeSlots = _h[1];
    var _j = React.useState(), onDemandRequestValues = _j[0], setRequestValues = _j[1];
    var _k = React.useState([]), rowData = _k[0], setRowdata = _k[1];
    var staticSlots = JSON.parse(JSON.stringify(slotblocking_helper_1.timeSlots));
    var _l = React.useState(staticSlots), slotsList = _l[0], setSlotsList = _l[1];
    var _m = React.useState([]), timeslots = _m[0], setTimeslots = _m[1];
    var _o = React.useState([]), minutes = _o[0], setMinutes = _o[1];
    var _p = React.useState([]), selectedRoute = _p[0], setSelectedRoute = _p[1];
    var _q = React.useState([]), existondemandslot = _q[0], setexistondemandslot = _q[1];
    var _r = React.useState(''), monthandYear = _r[0], setMonthandyear = _r[1];
    var _s = React.useState(''), ondemandAllowed = _s[0], setOndemandAllowed = _s[1];
    var _t = React.useState(''), usedCredits = _t[0], setUsedCredits = _t[1];
    var _u = React.useState(), ultrastaticPayload = _u[0], setUltrastaticPayload = _u[1];
    var _v = React.useState(false), infoModal = _v[0], setInfoModal = _v[1];
    var _w = React.useState(false), handoverPopup = _w[0], setHandoverPopup = _w[1];
    var _x = React.useState(false), ultrastaticSubmitModal = _x[0], setUltrastaticSubmitModal = _x[1];
    var _y = React.useState(), ultrastaticRowdata = _y[0], setUltrastaticRowdata = _y[1];
    var _z = React.useState(), ccInfoSlots = _z[0], setCCInfoSlots = _z[1];
    var _0 = React.useState(), feInfoSlots = _0[0], setFEInfoSlots = _0[1];
    var _1 = React.useState(), selectedSlot = _1[0], setSelectedSlot = _1[1];
    var defaultVisit = [{
            id: Math.random(),
            event: '',
            ccCode: '',
            ccName: is_sso_user ? sso_user_details.warehouse_code : '',
            timeslot: '',
            hours: '',
            minutes: '',
            placeHolder: {
                code: 'CC Code',
                name: 'CC Name'
            }
        }];
    var ondemandInitialValues = {
        state: is_sso_user ? sso_user_details.center_state : '',
        city: is_sso_user ? sso_user_details.center_city : '',
        labcode: is_sso_user
            ? {
                label: sso_user_details.lab_code,
                value: sso_user_details.lab_code,
            }
            : '',
        selectedCC: is_sso_user
            ? {
                label: sso_user_details.warehouse_code,
                value: sso_user_details.warehouse_code,
            }
            : '',
        selectedSlots: '',
        routeType: '',
        feMobile: '',
        feName: '',
        feState: '',
        feCity: '',
        slots: []
    };
    var routeType = routing_types_list.filter(function (routeType) { return routeType.label === "ULTRA_STATIC"; });
    var lablistInformation = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var currentdate = new Date().getDay();
    var clearSlots = function () {
        setSlotsList(JSON.parse(JSON.stringify(slotblocking_helper_1.timeSlots)));
    };
    var existingData = function () {
        var arr = [];
        slotsList.map(function (slot) {
            existingTimeSlots &&
                (existingTimeSlots === null || existingTimeSlots === void 0 ? void 0 : existingTimeSlots.map(function (each) {
                    if (each.day === (0, array_helpers_1.getCurrentday)(currentdate)) {
                        if (each['schedule_time_slot'].split(':')[0] ===
                            slot['fromTime'].split(':')[0]) {
                            slot['color'] = 'slot-btn status-exist';
                        }
                    }
                }));
            arr.push(slot);
        });
        setSlotsList(arr);
    };
    React.useEffect(function () {
        existingData();
    }, [existingTimeSlots]);
    var existingOndemandslots = function () {
        var arr = [];
        slotsList.map(function (slot) {
            existondemandslot &&
                (existondemandslot === null || existondemandslot === void 0 ? void 0 : existondemandslot.map(function (each) {
                    if (each['time_window_slot'].split(':')[0] === slot['fromTime'].split(':')[0]) {
                        slot['color'] = 'slot-btn yellow-shadecolor';
                    }
                }));
            arr.push(slot);
        });
        setSlotsList(arr);
    };
    React.useEffect(function () {
        existingOndemandslots();
    }, [existondemandslot]);
    var navigateTOListPage = function () {
        if (pathname.includes("".concat(route_path_1.default.PATHS.sso_ondemandRequest))) {
            nav("/".concat(route_path_1.default.PATHS.sso_ondemandRequest, "/").concat(route_path_1.default.PATHS.onDemandRequest));
        }
        else {
            nav("/".concat(route_path_1.default.PATHS.controlTower));
        }
    };
    var navigateOndemand_creation = function () {
        setConfirmationModal(false);
        setOpen(false);
    };
    var handleOpen = function (values) {
        var isAllValuesFilled = false;
        var arr = Object.values(values);
        arr.forEach(function (item) {
            item === '' ? (isAllValuesFilled = true) : (isAllValuesFilled = false);
        });
        if (isAllValuesFilled) {
        }
        else {
            setOpen(true);
        }
    };
    var handleClose = function () {
        setOpen(false);
    };
    var proceedModal = function () {
        submitOndemanRequest(confirmationModal);
    };
    var proceedModal1 = function () {
        setConfirmationModal(!confirmationModal);
        if (confirmationModal) {
            setOpen(false);
            nav("/".concat(route_path_1.default.PATHS.controlTower));
        }
    };
    var callbackOnselectedLab = function (val, values) {
        var payloadCC = {
            lab_code: val.value,
            ondemand_request: true,
            route_type: selectedRoute,
            coordinates_available: true
        };
        (0, CC_api_calls_1.fetchCCTimeSlotList)(payloadCC)
            .then(function (res) {
            var data = (0, array_helpers_1.convertUltraStaticCCList)(res.result);
            setWarehouseCodeList(data);
        })
            .catch(function (e) { return console.log(e); });
        var payloadFE = {
            onboard_status: [appConstants_1.FE_USER_TYPES.active],
            route_type: routeType[0].value,
            lab_code: val.value
        };
        (0, slotblocking_apicalls_1.fetchFeOnSelectedFeType)(payloadFE)
            .then(function (res) {
            setUltrastaticFEList((0, array_helpers_1.convertETRFFEList)(res));
        })
            .catch(function (e) { return console.log(e); });
    };
    var callbackOnselectedCC = function (val) {
        setSelectedSlotId(0);
        var payload = {
            warehouse_code: val,
        };
        (0, CC_api_calls_1.fetchCCEditList)(payload)
            .then(function (res) {
            var data = res.data.schedule;
            setExistingTimeSlots(data);
            setMonthandyear((0, array_helpers_1.getLatAndLng)(res.data.month, res.data.year));
            setOndemandAllowed(res.data.available_credits);
            setUsedCredits(res.data.used_credits);
        })
            .catch(function (e) { return console.log(e); });
    };
    var callbackCCondemandtimeslots = function (val) {
        setSelectedSlotId(0);
        var payload = {
            warehouse_code: val,
        };
        (0, control_center_api_calls_1.fetchCCOndemandtimeslotList)(payload)
            .then(function (res) {
            var data = res.data.result;
            setexistondemandslot(data);
        })
            .catch(function (e) { return console.log(e); });
    };
    React.useEffect(function () {
        if (is_sso_user) {
            callbackOnselectedCC(sso_user_details.warehouse_code);
        }
    }, []);
    var submitOndemanRequest = function (confirmationModal) {
        (0, control_center_api_calls_1.submitCCTimeSlot)(onDemandRequestValues)
            .then(function (res) {
            if (res.statusCode == 200) {
                setOpen(false);
                setConfirmationModal(!confirmationModal);
                react_hot_toast_1.default.success(appConstants_1.CreateOnDemandRequest.Add);
                clearSlots();
                if (pathname.includes("".concat(route_path_1.default.PATHS.sso_ondemandRequest))) {
                    nav("/".concat(route_path_1.default.PATHS.sso_ondemandRequest, "/").concat(route_path_1.default.PATHS.onDemandRequest));
                }
                else {
                    nav("/".concat(route_path_1.default.PATHS.controlTower));
                }
            }
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var ultraStaticOnDemandSubmit = function (values, resetForm, setFieldValue) {
        (0, control_center_api_calls_1.submitUltrastaticOndemand)(ultrastaticPayload)
            .then(function (res) {
            if (res.statusCode === 200) {
                resetForm();
                setFieldValue('routeType', { id: 2, label: 'Ultrastatic', value: 'ULTRA_STATIC' });
                react_hot_toast_1.default.success('On demand request has been submitted', { duration: 2000 });
            }
            else {
                if (res.errors != undefined && Object.keys(res.errors).length > 0) {
                    setHandoverPopup(true);
                }
                else {
                    react_hot_toast_1.default.error(res.message, { duration: 2000 });
                }
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message, { duration: 2000 });
        });
    };
    var formSubmitHandler = function (values) {
        if (values.routeType.value === 'ULTRA_STATIC') {
            var payload = {};
            if (values.feMobile) {
                payload['fe_id'] = values.feMobile.id;
            }
            if (values.slots.length > 0) {
                var slotsPayload_1 = [];
                values.slots.forEach(function (eachVisit) {
                    var visit = {
                        warehouse_code: eachVisit.ccCode.value,
                        time_window_slot: eachVisit.timeslot.value,
                        eta: "".concat(eachVisit === null || eachVisit === void 0 ? void 0 : eachVisit.hours, ":").concat(eachVisit === null || eachVisit === void 0 ? void 0 : eachVisit.minutes.value),
                        visit_type: eachVisit.event.id
                    };
                    slotsPayload_1.push(visit);
                });
                payload['visits'] = slotsPayload_1;
            }
            var rowData_1 = [];
            values.slots.forEach(function (eachVisit) {
                var visit = {
                    fe_name: values.feName,
                    warehouse_name: eachVisit.ccName,
                    warehouse_code: eachVisit.ccCode.value,
                    time_window_slot: eachVisit.timeslot.buttonName,
                    eta: "".concat(eachVisit === null || eachVisit === void 0 ? void 0 : eachVisit.hours, ":").concat(eachVisit === null || eachVisit === void 0 ? void 0 : eachVisit.minutes.value)
                };
                rowData_1.push(visit);
            });
            setUltrastaticPayload(payload);
            setUltrastaticRowdata(rowData_1);
            setUltrastaticSubmitModal(true);
        }
        else {
            var selectedSlot_1;
            slotsList.map(function (timeslot) {
                if (timeslot.id === selectedSlotId) {
                    selectedSlot_1 = timeslot;
                }
            });
            var payload = {
                warehouse_code: values['selectedCC']['value'],
                time_window_slot: selectedSlot_1.buttonName,
            };
            setRowdata([
                {
                    cc_name: CCName,
                    time_slot: selectedSlot_1.buttonName,
                },
            ]);
            setRequestValues(payload);
        }
    };
    var routeTypeOnchangeHandler = function (val, setFieldValue) {
        setFieldValue('labcode', '');
        setFieldValue('feMobile', '');
        setFieldValue('feName', '');
        setFieldValue('feState', '');
        setFieldValue('feCity', '');
        setFieldValue('slots', []);
        setFieldValue('selectedCC', '');
        setFieldValue('city', '');
        setFieldValue('state', '');
        setFieldValue('routeType', val);
        var routeTypes = [];
        var filterCondition = val.value === 'ULTRA_STATIC'
            ? function (type) { return type.label.includes('ULTRA_STATIC'); }
            : function (type) { return type.label === 'STATIC' || type.label === 'DYNAMIC'; };
        routing_types_list.forEach(function (type) {
            if (filterCondition(type)) {
                routeTypes.push(type.label);
            }
        });
        setSelectedRoute(routeTypes);
        if (val.value === 'ULTRA_STATIC') {
            setIsUltraStatic(true);
        }
        else {
            setIsUltraStatic(false);
        }
    };
    var timeslotHandleFocus = function () {
        var updatedSlots = staticSlots.filter(function (slotDetails) {
            var slot = slotDetails.fromTime;
            slotDetails['label'] = slotDetails.buttonName;
            slotDetails['value'] = slotDetails.buttonName;
            if (moment().format('mm') === '59') {
                if (slot.split(":")[0] >= (parseInt(moment().format('HH')) + 1).toString().padStart(2, '0')) {
                    return slotDetails;
                }
            }
            else {
                if (slot.split(":")[0] >= moment().format('HH')) {
                    return slotDetails;
                }
            }
        });
        setTimeslots(updatedSlots);
    };
    var minsHandleFocus = function (slot) {
        var currentSlot = moment().format('mm') === '59' ? timeslots[1].buttonName : timeslots[0].buttonName;
        if (slot === currentSlot) {
            var updatedMins = appConstants_1.MinutesUltrStatic.filter(function (minDetails) {
                if (minDetails.value > moment().format('mm')) {
                    return minDetails;
                }
            });
            setMinutes(updatedMins);
        }
        else {
            setMinutes(appConstants_1.MinutesUltrStatic);
        }
    };
    var hasAllValues = function (obj) {
        return Object.entries(obj).every(function (_a) {
            var key = _a[0], value = _a[1];
            return value !== undefined && value !== null && value !== '';
        });
    };
    var getInfoVisitsList = function (values, index) {
        var selectedTimeSlot = values === null || values === void 0 ? void 0 : values.slots[index].timeslot.buttonName;
        setSelectedSlot(selectedTimeSlot);
        var cc_payload = {
            fe_id: values.feMobile.id,
            time_window_slot: values === null || values === void 0 ? void 0 : values.slots[index].timeslot.buttonName
        };
        (0, control_center_api_calls_1.fetchInfoVisits)(cc_payload)
            .then(function (res) {
            if (res.statusCode === 200) {
                setInfoModal(true);
                var updatedSlots = (0, array_helpers_1.convertVisitsInfoResponse)(res.data.result);
                setCCInfoSlots(updatedSlots);
            }
        })
            .catch(function (err) {
            console.log(err);
        });
        var fe_payload = {
            warehouse_code: values === null || values === void 0 ? void 0 : values.slots[index].ccCode.value,
            time_window_slot: values === null || values === void 0 ? void 0 : values.slots[index].timeslot.buttonName
        };
        (0, control_center_api_calls_1.fetchInfoVisits)(fe_payload)
            .then(function (res) {
            if (res.statusCode === 200) {
                setInfoModal(true);
                var updatedSlots = (0, array_helpers_1.convertFEInfoResponse)(res.data.result);
                setFEInfoSlots(updatedSlots);
            }
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var validateTimeSlot = function (values, targetHour, targetMin) {
        var isNotValid = values.some(function (slot) {
            return slot &&
                slot.hours === targetHour &&
                slot.minutes.label === targetMin.label;
        });
        return isNotValid;
    };
    var onVisitChange = function (val, values, index, setFieldValue, setFieldTouched) {
        setFieldValue("slots[".concat(index, "].codePlaceHolder"), 'CC Code');
        setFieldValue("slots[".concat(index, "].namePlaceHolder"), 'CC Name');
        setFieldValue("slots[".concat(index, "].ccCode"), '');
        setFieldValue("slots[".concat(index, "].ccName"), '');
        setFieldValue("slots[".concat(index, "].timeslot"), '');
        setFieldValue("slots[".concat(index, "].hours"), '');
        setFieldValue("slots[".concat(index, "].minutes"), '');
        setFieldValue("slots[".concat(index, "].event"), val);
        setFieldTouched('event', true);
        if (val.label === 'HANDOVER HUB') {
            setFieldValue("slots[".concat(index, "].codePlaceHolder"), 'HUB Code');
            setFieldValue("slots[".concat(index, "].namePlaceHolder"), 'HUB Name');
            (0, fe_services_1.fetchFEProfileList)(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, { user_id: values.feMobile.id }, process.env.VENDOR_BASE_URL)
                .then(function (res) {
                if (!res.hub_code) {
                    setFieldValue("slots[".concat(index, "].ccCode"), '');
                    setFieldValue("slots[".concat(index, "].ccName"), '');
                    react_hot_toast_1.default.error("Selected FE didn't assigned to any hub", { duration: 3000 });
                }
                else {
                    setFieldValue("slots[".concat(index, "].ccCode"), { label: res.hub_code, value: res.hub_code });
                    setFieldValue("slots[".concat(index, "].ccName"), res.hub_name);
                }
            })
                .catch(function (e) { return console.log(e); });
        }
        if (val.label === 'HANDOVER LAB') {
            setFieldValue("slots[".concat(index, "].codePlaceHolder"), 'LAB Code');
            setFieldValue("slots[".concat(index, "].namePlaceHolder"), 'LAB Name');
            setFieldValue("slots[".concat(index, "].ccCode"), { label: values.labcode.value, value: values.labcode.value });
            setFieldValue("slots[".concat(index, "].ccName"), values.labcode.center_name);
        }
    };
    var ultrastaticVisit = function (values, setFieldValue, setFieldTouched, errors, touched, remove, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return (React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, spacing: 3, alignItems: "center" },
            React.createElement(Grid_1.default, { item: true, xs: 1.9, lg: 1.9 },
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "event", value: values === null || values === void 0 ? void 0 : values.slots[index].event, dropDownList: updated_route_types.length > 0 ? updated_route_types : [], placeholder: "Event", onChange: function (val) {
                        if (val != null) {
                            onVisitChange(val, values, index, setFieldValue, setFieldTouched);
                        }
                        else {
                            setFieldValue("slots[".concat(index, "].event"), '');
                            setFieldValue("slots[".concat(index, "].ccCode"), '');
                            setFieldValue("slots[".concat(index, "].ccName"), '');
                            setFieldValue("slots[".concat(index, "].timeslot"), '');
                            setFieldValue("slots[".concat(index, "].hours"), '');
                            setFieldValue("slots[".concat(index, "].minutes"), '');
                        }
                    }, required: true }),
                (errors === null || errors === void 0 ? void 0 : errors.slots) && ((_a = errors === null || errors === void 0 ? void 0 : errors.slots[index]) === null || _a === void 0 ? void 0 : _a.event) &&
                    touched.slots && ((_b = touched.slots[index]) === null || _b === void 0 ? void 0 : _b.event) &&
                    ((_c = values.slots[index]) === null || _c === void 0 ? void 0 : _c.event) === '' ? (React.createElement("div", { className: "error" }, errors === null || errors === void 0 ? void 0 : errors.slots[index].event)) : null),
            React.createElement(Grid_1.default, { item: true, xs: 2.2, lg: 2.2 },
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "ccCode", value: values === null || values === void 0 ? void 0 : values.slots[index].ccCode, isDisabled: (values === null || values === void 0 ? void 0 : values.slots[index].event.label) != 'PICKUP' ? true : false, dropDownList: warehouseCodeList.length > 0 ? warehouseCodeList : [], placeholder: (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'PICKUP' ? 'CC Code' : (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'HANDOVER LAB' ? 'LAB Code' : (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'HANDOVER HUB' ? 'HUB Code' : 'CC Code', label: (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'PICKUP' ? 'CC Code' : (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'HANDOVER LAB' ? 'LAB Code' : (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'HANDOVER HUB' ? 'HUB Code' : 'CC Code', required: true, onChange: function (val) {
                        setFieldValue("slots[".concat(index, "].ccCode"), { id: 1, label: val.value, value: val.value });
                        setFieldValue("slots[".concat(index, "].ccName"), val.center_name);
                        setFieldTouched('ccCode', true);
                    } }),
                (errors === null || errors === void 0 ? void 0 : errors.slots) && ((_d = errors === null || errors === void 0 ? void 0 : errors.slots[index]) === null || _d === void 0 ? void 0 : _d.ccCode) &&
                    touched.slots && ((_e = touched.slots[index]) === null || _e === void 0 ? void 0 : _e.ccCode) &&
                    ((_f = values.slots[index]) === null || _f === void 0 ? void 0 : _f.ccCode) === '' ? (React.createElement("div", { className: "error" }, errors === null || errors === void 0 ? void 0 : errors.slots[index].ccCode)) : null),
            React.createElement(Grid_1.default, { item: true, xs: 3.2, lg: 3.2 },
                React.createElement(text_filed_component_1.default, { name: "ccName", placeholder: (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'PICKUP' ? 'CC Name' : (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'HANDOVER LAB' ? 'LAB Name' : (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'HANDOVER HUB' ? 'HUB Name' : 'CC Name', label: (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'PICKUP' ? 'CC Name' : (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'HANDOVER LAB' ? 'LAB Name' : (values === null || values === void 0 ? void 0 : values.slots[index].event.label) === 'HANDOVER HUB' ? 'HUB Name' : 'CC Name', isDisabled: true, value: (_g = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _g === void 0 ? void 0 : _g.ccName, key: values.ccName })),
            React.createElement(Grid_1.default, { item: true, xs: 1.7, lg: 1.7, className: 'icons-wrapper' },
                React.createElement(Grid_1.default, { container: true },
                    React.createElement(Grid_1.default, { item: true, xs: (values === null || values === void 0 ? void 0 : values.slots[index].timeslot) ? 10 : 12, lg: (values === null || values === void 0 ? void 0 : values.slots[index].timeslot) ? 10 : 12 },
                        React.createElement(drop_down_component_1.default, { name: "timeslot", dropDownList: timeslots, value: values === null || values === void 0 ? void 0 : values.slots[index].timeslot, onFocusHandler: timeslotHandleFocus, onChange: function (val) {
                                setFieldValue("slots[".concat(index, "].timeslot"), val);
                                setFieldValue("slots[".concat(index, "].hours"), val.value.substring(0, 2));
                            }, placeholder: "Time Slots", required: true }),
                        (errors === null || errors === void 0 ? void 0 : errors.slots) && ((_h = errors === null || errors === void 0 ? void 0 : errors.slots[index]) === null || _h === void 0 ? void 0 : _h.timeslot) &&
                            touched.slots && ((_j = touched.slots[index]) === null || _j === void 0 ? void 0 : _j.timeslot) &&
                            ((_k = values.slots[index]) === null || _k === void 0 ? void 0 : _k.timeslot) === '' ? (React.createElement("div", { className: "error" }, errors === null || errors === void 0 ? void 0 : errors.slots[index].timeslot)) : null),
                    (values === null || values === void 0 ? void 0 : values.slots[index].timeslot) &&
                        React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: 'info-icon-wrapper' },
                            React.createElement(material_1.IconButton, { className: "icon-btn", onClick: function () { return getInfoVisitsList(values, index); } },
                                React.createElement(InfoOutlined_1.default, { sx: { fontSize: '20px', color: '#1976d2' } }))))),
            React.createElement(Grid_1.default, { item: true, xs: 1, lg: 1 },
                React.createElement(text_filed_component_1.default, { name: "hours", placeholder: "Hours", value: values === null || values === void 0 ? void 0 : values.slots[index].hours, label: "Hours", isDisabled: true, required: true })),
            React.createElement(Grid_1.default, { item: true, xs: 1.3, lg: 1.3 },
                React.createElement(drop_down_component_1.default, { name: "minutes", dropDownList: minutes, onFocusHandler: function () { return minsHandleFocus(values.slots[index].timeslot.buttonName); }, value: values === null || values === void 0 ? void 0 : values.slots[index].minutes, onChange: function (val) {
                        var isValid = validateTimeSlot(values.slots, values === null || values === void 0 ? void 0 : values.slots[index].hours, val);
                        if (isValid) {
                            react_hot_toast_1.default.error('The selected timeslot is already assigned. Please select a different one.', { duration: 3000 });
                        }
                        else {
                            setFieldValue("slots[".concat(index, "].minutes"), val);
                        }
                    }, placeholder: "Minutes", required: true }),
                (errors === null || errors === void 0 ? void 0 : errors.slots) && ((_l = errors === null || errors === void 0 ? void 0 : errors.slots[index]) === null || _l === void 0 ? void 0 : _l.minutes) &&
                    touched.slots && ((_m = touched.slots[index]) === null || _m === void 0 ? void 0 : _m.minutes) &&
                    ((_o = values.slots[index]) === null || _o === void 0 ? void 0 : _o.minutes) === '' ? (React.createElement("div", { className: "error" }, errors === null || errors === void 0 ? void 0 : errors.slots[index].minutes)) : null),
            React.createElement(Grid_1.default, { item: true, xs: 0.5, lg: 0.5 },
                React.createElement(material_1.Box, null,
                    React.createElement(material_1.IconButton, { className: "icon-btn", onClick: function () {
                            remove(index);
                        } },
                        React.createElement(Delete_1.default, { sx: { fontSize: '29px', color: '#1976d2' } }))))));
    };
    return (React.createElement("div", { className: "block-slot-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTOListPage }),
                React.createElement("h2", null, "On Demand Request"))),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement("div", { className: "add-slotform" },
                React.createElement("div", { className: "filter-menu-wrapper filter-inside-page" }),
                React.createElement(formik_1.Formik, { initialValues: ondemandInitialValues, validationSchema: schema_1.schemas.OnDemand_Request_Schema, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched, resetForm = _a.resetForm, setValues = _a.setValues, setErrors = _a.setErrors;
                    return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3, rowSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { required: true, name: "routeType", placeholder: "Select On Demand Route Type", onChange: function (val) {
                                        routeTypeOnchangeHandler(val, setFieldValue);
                                        setFieldTouched('labcode', false);
                                        setFieldTouched('feMobile', false);
                                    }, dropDownList: routeTypes, value: values.routeType }),
                                errors['routeType'] &&
                                    touched['routeType'] &&
                                    values['routeType'] === '' ? (React.createElement("div", { className: "error" }, errors['routeType'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { required: true, name: "labcode", placeholder: "Select Lab Code", dropDownList: lablistInformation, isDisabled: is_sso_user ? true : false, onChange: function (val) {
                                        clearSlots();
                                        setFieldValue('selectedCC', '');
                                        setFieldValue('city', '');
                                        setFieldValue('state', '');
                                        setFieldValue('selectedSlots', '');
                                        setFieldValue('feMobile', '');
                                        setFieldValue('feName', '');
                                        setFieldValue('feState', '');
                                        setFieldValue('feCity', '');
                                        setFieldValue('slots', []);
                                        setMonthandyear('');
                                        setOndemandAllowed('');
                                        setUsedCredits('');
                                        setFieldValue('labcode', val);
                                        setFieldTouched('labcode', true);
                                        callbackOnselectedLab(val, values);
                                    }, value: values.labcode }),
                                errors['labcode'] &&
                                    touched['labcode'] &&
                                    values['labcode'] === '' ? (React.createElement("div", { className: "error" }, errors['labcode'])) : null),
                            isUltraStatic && React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { name: "feMobile", dropDownList: ultrastaticFEList.length > 0 ? ultrastaticFEList : [], placeholder: "FE Mobile", label: 'FE Mobile', value: values === null || values === void 0 ? void 0 : values.feMobile, onChange: function (val) {
                                        setFieldValue("feMobile", {
                                            id: val.id,
                                            label: val.mobile,
                                            value: val.mobile
                                        });
                                        setFieldValue("feName", "".concat(val.FE_id, "/").concat(val.name));
                                        setFieldValue("feState", val.centre_state);
                                        setFieldValue("feCity", val.centre_city);
                                        setFieldTouched("feMobile", true);
                                    }, isDisabled: values.labcode == '', required: true }),
                                errors['feMobile'] &&
                                    touched['feMobile'] &&
                                    values['feMobile'] === '' ? (React.createElement("div", { className: "error" }, errors['feMobile'])) : null),
                            isUltraStatic && React.createElement(Grid_1.default, { item: true, xs: 2.8, lg: 2.8 },
                                React.createElement(text_filed_component_1.default, { name: "feName", placeholder: 'FE Code/FE Name', label: 'FE Code/FE Name', value: values === null || values === void 0 ? void 0 : values.feName, isDisabled: true })),
                            isUltraStatic && React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "feState", placeholder: 'FE Working State/City', label: 'FE Working State/City', value: values === null || values === void 0 ? void 0 : values.feState, isDisabled: true })),
                            isUltraStatic && React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "feCity", placeholder: 'FE Working City', label: 'FE Working City', value: values === null || values === void 0 ? void 0 : values.feCity, isDisabled: true })),
                            !isUltraStatic &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement(drop_down_component_1.default, { key: Math.random().toString(), required: true, name: "selectedCC", isDisabled: is_sso_user ? true : false, placeholder: "Select CC", dropDownList: warehouseCodeList && warehouseCodeList, onChange: function (val) {
                                            clearSlots();
                                            setFieldValue('selectedCC', val);
                                            setFieldValue('selectedSlots', '');
                                            setFieldValue('state', val.centre_state);
                                            setFieldValue('city', val.center_city);
                                            callbackOnselectedCC(val.value);
                                            callbackCCondemandtimeslots(val.value);
                                            setFieldTouched('selectedCC', true);
                                            setCCName(val.label);
                                        }, value: values.selectedCC }),
                                    errors['selectedCC'] &&
                                        touched['selectedCC'] &&
                                        values['selectedCC'] === '' ? (React.createElement("div", { className: "error" }, errors['selectedCC'])) : null),
                            !isUltraStatic && React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { className: "text-caps", placeholder: "State", name: "state", value: values.state, label: "State", isDisabled: true, key: values.state })),
                            !isUltraStatic && React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { className: "text-caps", placeholder: "City", name: "city", value: values.city, label: "City", isDisabled: true, key: values.city })),
                            !isUltraStatic &&
                                React.createElement(Grid_1.default, { container: true, xs: 12, lg: 12, className: 'profile-grid-wrapper' },
                                    React.createElement(Grid_1.default, { item: true, xs: 4 },
                                        React.createElement(profile_grid_1.default, { lableName: 'Month & Year', value: monthandYear ? monthandYear : '--' })),
                                    React.createElement(Grid_1.default, { item: true, xs: 4 },
                                        React.createElement(profile_grid_1.default, { lableName: 'Available On-Demand request', value: ondemandAllowed ? ondemandAllowed : '--' })),
                                    React.createElement(Grid_1.default, { item: true, xs: 4 },
                                        React.createElement(profile_grid_1.default, { lableName: 'Used On-Demand request', value: usedCredits >= 0 ? usedCredits : '--' }))),
                            !isUltraStatic &&
                                React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true },
                                    React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true },
                                        React.createElement("div", { className: "days-wrapper" },
                                            React.createElement("h2", { className: "selected-felabel custom-form-label" }, "Select On Demand Request Time Slots")))),
                            !isUltraStatic &&
                                React.createElement(Grid_1.default, { item: true, xs: 14, lg: 12 },
                                    React.createElement(cc_timeSlots_1.default, { slots: slotsList, setSelectedSlotId: setSelectedSlotId, selectedSlotId: selectedSlotId, setFieldValue: setFieldValue, existingTimeSlots: existingTimeSlots }),
                                    errors['selectedSlots'] && touched['selectedSlots'] ? (React.createElement("div", { className: "error" },
                                        errors['selectedSlots'],
                                        " ")) : null,
                                    React.createElement("div", { className: "cc-slot-legend align-center" },
                                        React.createElement("div", { className: "legend-item bg-available" }, "Available Time Slots"),
                                        React.createElement("div", { className: "legend-item bg-expired" }, "Expired Time Slots"),
                                        React.createElement("div", { className: "legend-item bg-existing" }, "Regular Time Slots"),
                                        React.createElement("div", { className: "legend-item bg-selected" }, "Selected Time Slots"),
                                        React.createElement("div", { className: "legend-item bg-ondemand" }, "Today Ondemand Request Time Slots")))),
                        isUltraStatic &&
                            React.createElement(formik_1.FieldArray, { name: "slots" }, function (_a) {
                                var _b;
                                var push = _a.push, remove = _a.remove;
                                return (React.createElement("div", null,
                                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                                        React.createElement(Grid_1.default, { item: true, xs: 12, lg: 12, className: 'add-visit-button-wrapper' },
                                            React.createElement("div", { className: "days-wrapper" },
                                                React.createElement("h2", { className: "selected-felabel custom-form-label" }, "Assign On Demand Request")),
                                            React.createElement(button_component_1.default, { buttonName: "Add Visit", onClickHandler: function () {
                                                    var pushNewVisit = function () {
                                                        push(defaultVisit[0]);
                                                    };
                                                    if (values.slots.length < 5) {
                                                        if (values.slots.length < 1) {
                                                            pushNewVisit();
                                                        }
                                                        else {
                                                            if (hasAllValues(values.slots[values.slots.length - 1])) {
                                                                pushNewVisit();
                                                            }
                                                            else {
                                                                react_hot_toast_1.default.error('Please complete the current action', { duration: 3000 });
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        react_hot_toast_1.default.error('Only 5 five visits are allowed', { duration: 3000 });
                                                    }
                                                }, color: "yellow", isError: values.event == '' }))),
                                    (values === null || values === void 0 ? void 0 : values.slots.length) > 0 &&
                                        React.createElement("div", { className: "ondemand-field-array-wrapper" }, (_b = values === null || values === void 0 ? void 0 : values.slots) === null || _b === void 0 ? void 0 : _b.map(function (p, index) {
                                            return (React.createElement("div", { key: p.id }, ultrastaticVisit(values, setFieldValue, setFieldTouched, errors, touched, remove, index)));
                                        }))));
                            }),
                        React.createElement("div", { className: "ondemand-submit-wrapper align-end" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateTOListPage }),
                            React.createElement(modal_component_1.default, { buttonName: "Create", type: "submit", buttonColor: "yellow", onClickHandler: function () {
                                    if (values.routeType.value !== 'ULTRA_STATIC') {
                                        if (Object.keys(errors).length == 0) {
                                            handleOpen(values);
                                        }
                                    }
                                }, open: open, modalSize: "mini-modal" },
                                React.createElement("div", { className: "modal-header" },
                                    React.createElement("h2", null, "Create On Demand Request"),
                                    React.createElement("div", { className: "modal-body" },
                                        React.createElement("p", { className: "sub-heading" }, "System will try to assign if there is any FE available nearby. Please Check the status of the request in Auto Routing dashboard"),
                                        React.createElement("p", { className: "sub-heading" }, "If FE is in the middle of any visit activity this request will be handled only after completing the existing visit activity"))),
                                React.createElement("div", { className: "modal-footer" },
                                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose }),
                                    React.createElement(modal_component_1.default, { buttonName: "Proceed", buttonColor: "yellow", onClickHandler: proceedModal1, open: confirmationModal, modalSize: "mini-modal" },
                                        React.createElement("div", { className: "modal-header" },
                                            React.createElement("p", { className: "sub-heading text-green" }, "You can sit back and relax now. Your On Demand Request has been scheduled successfully")),
                                        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
                                            React.createElement("div", { className: "modal-table-wrapper" },
                                                React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ONDEMAND_REQUEST_COLUMNS, rows: rowData.length > 0 ? rowData : [], pagination: false }))),
                                        React.createElement("div", { className: "modal-footer" },
                                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateOndemand_creation }),
                                            React.createElement(button_component_1.default, { buttonName: "Ok", color: "yellow", onClickHandler: proceedModal })))))),
                        React.createElement(material_1.Modal, { open: infoModal },
                            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                                React.createElement("div", { className: "modal-header header-wrapper" },
                                    React.createElement("p", { className: "sub-heading text-green" },
                                        "FE visits for the time slot ",
                                        selectedSlot)),
                                React.createElement("div", { className: "modal-body" },
                                    React.createElement("div", { className: "unclickablerow custom-pagination-table" },
                                        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.CC_INFO_COLUMNS, rows: (ccInfoSlots === null || ccInfoSlots === void 0 ? void 0 : ccInfoSlots.length) > 0 ? ccInfoSlots : [], pagination: false }))),
                                React.createElement("div", { className: "modal-header header-wrapper" },
                                    React.createElement("p", { className: "sub-heading text-green" },
                                        "CC visits for the time slot ",
                                        selectedSlot)),
                                React.createElement("div", { className: "modal-body" },
                                    React.createElement("div", { className: "unclickablerow custom-pagination-table" },
                                        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.FE_INFO_COLUMNS, rows: (feInfoSlots === null || feInfoSlots === void 0 ? void 0 : feInfoSlots.length) > 0 ? feInfoSlots : [], pagination: false }))),
                                React.createElement("div", { className: "modal-footer" },
                                    React.createElement(button_component_1.default, { buttonName: "Ok", color: "yellow", onClickHandler: function () { return setInfoModal(false); } })))),
                        React.createElement(material_1.Modal, { open: ultrastaticSubmitModal },
                            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                                React.createElement("div", { className: "modal-header header-wrapper" },
                                    React.createElement("p", { className: "sub-heading text-green" }, "You can sit back and relax now. Your On Demand Request has been scheduled successfully")),
                                React.createElement("div", { className: "unclickablerow custom-pagination-table " },
                                    React.createElement("div", { className: "modal-table-wrapper submit-table-wrapper" },
                                        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ULTRASTATIC_ONDEMAND_REQUEST_COLUMNS, rows: ultrastaticRowdata, pagination: false }))),
                                React.createElement("div", { className: "modal-footer" },
                                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return setUltrastaticSubmitModal(false); } }),
                                    React.createElement(button_component_1.default, { buttonName: "Ok", color: "yellow", onClickHandler: function () {
                                            ultraStaticOnDemandSubmit(values, resetForm, setFieldValue);
                                            setUltrastaticSubmitModal(false);
                                        } })))),
                        React.createElement(pop_up_1.default, { open: handoverPopup },
                            React.createElement("div", { className: "modal-header" },
                                React.createElement("h2", { className: "heading" }, "No Lab / Hub Handover Available!"),
                                React.createElement("h2", { className: "sub-heading" }, "Please add a Lab / Hub handover visit")),
                            React.createElement("div", { className: "modal-footer sticky-modal-footer", style: { justifyContent: 'end' } },
                                React.createElement(button_component_1.default, { buttonName: "Ok", color: "yellow-outline", isError: false, onClickHandler: function () { return setHandoverPopup(false); } })))));
                })))));
};
exports.default = OnDemandRequest;
