"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var array_helpers_1 = require("../../utils/array_helpers");
var state = 'state';
var PlusTooltip = function (_a) {
    var stateCityData = _a.stateCityData, isState = _a.isState;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    console.log(stateCityData, isState, "isState");
    var handleClose = function () {
        setOpen(false);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    var emptyValue = '--';
    var stateData = (0, array_helpers_1.removeDuplicateStates)(stateCityData || []);
    var cityData = (0, array_helpers_1.removeDuplicateCity)(stateCityData || []);
    return (React.createElement(material_1.Tooltip, { open: open, placement: "bottom", onClose: handleClose, className: "tool-tip-wrapper", title: isState == state ? (React.createElement("div", { className: "tool-tip-list" }, stateData && stateData.length > 0
            ? stateData.slice(1, stateData.length).map(function (list, index) {
                return React.createElement("div", { key: index }, list && list.state_name);
            })
            : '')) :
            isState == 'labs' ? (React.createElement("div", { className: "tool-tip-list" }, stateCityData && stateCityData.length > 0
                ? stateCityData.slice(1, stateCityData.length).map(function (list, index) {
                    return React.createElement("div", { key: index }, list && list);
                })
                : '')) :
                (React.createElement("div", null, cityData && cityData.length > 0
                    ? cityData
                        .slice(1, cityData.length)
                        .map(function (list, index) { return (React.createElement("div", { key: index }, list && list.city_name)); })
                    : '')), arrow: true },
        React.createElement("div", null, isState == state ? (React.createElement("div", null,
            ' ',
            stateData && stateData[0]
                ? stateData[0].state_name
                : emptyValue,
            ' ',
            stateData.length <= 1 ? ('') : (React.createElement("span", { onMouseOver: handleOpen, className: "more-values" },
                "+",
                stateData.length - 1)))) :
            isState == "labs" ? (React.createElement("div", null,
                ' ',
                stateCityData && stateCityData[0]
                    ? stateData[0]
                    : emptyValue,
                ' ',
                stateCityData.length <= 1 ? ('') : (React.createElement("span", { onMouseOver: handleOpen, className: "more-values" },
                    "+",
                    stateCityData.length - 1)))) : (React.createElement("div", null,
                cityData && cityData[0] ? cityData[0].city_name : emptyValue,
                ' ',
                cityData.length <= 1 ? ('') : (React.createElement("span", { onMouseOver: handleOpen, className: "more-values" },
                    "+",
                    cityData.length - 1)))))));
};
exports.default = PlusTooltip;
