"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_router_1 = require("react-router");
var button_component_1 = require("../../common/buttons/button_component");
var react_paginate_1 = require("../../common/react_pagination/react_paginate");
var search_component_1 = require("../../common/search/search_component");
var AGgrid_table_component_1 = require("../../common/tables/AGgrid_table_component");
var route_path_1 = require("../../constants/route_path");
var table_data_1 = require("../../constants/table_data");
var reginal_mapping_service_1 = require("./reginal_mapping_service");
var Region_Mapping_Helpers_1 = require("./Region_Mapping_Helpers");
var RegionList = function (_a) {
    var _b = React.useState(undefined), RegionList = _b[0], setRegionList = _b[1];
    var _c = React.useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var nav = (0, react_router_1.useNavigate)();
    var _g = React.useState(''), searchTerm = _g[0], setSearchTerm = _g[1];
    var PageLimit = 50;
    var refetch = (0, react_query_1.useQuery)(['EventPerformance-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        payload['page'] = currentPage;
        payload['size'] = PageLimit;
        if (searchTerm.trim().length >= 3) {
            payload['search_string'] = searchTerm.toLocaleLowerCase().trim();
        }
        (0, Region_Mapping_Helpers_1.fetchRegionList)(payload)
            .then(function (response) {
            var _a, _b, _c;
            console.log(response.data, 'oiuygtf');
            var pageSize = PageLimit;
            var count = Math.ceil(((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.count) / pageSize);
            setErrorMessage('');
            setTotalPageCount(count);
            var result = ((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.result) ? response.data.result : [];
            var convertedData = (0, reginal_mapping_service_1.converReginalMappingLabList)(result);
            setRegionList(convertedData);
            setTotalCount((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setRegionList([]);
        });
    }).refetch;
    var createNewUser = function () {
        nav("".concat(route_path_1.default.PATHS.region_create));
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [PageLimit, searchTerm.length]);
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    return (React.createElement("div", { className: "fe-wrapper leave-tarcking-page" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Region Management")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { color: "yellow", buttonName: "Create Region", onClickHandler: createNewUser }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: "Search Region", searchValue: searchTerm, searchHandler: searchingHandler }))),
        React.createElement("div", { className: "scrollable-content fe-managment-screen" },
            React.createElement("div", { className: "custom-pagination-table" },
                React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.REGION_LISTING_COLUMNS, rows: RegionList && RegionList, errorMessage: errorMessage, pagination: false, enableCellTextSelection: true, className: 'sample-tracking-table' }),
                React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: RegionList === null || RegionList === void 0 ? void 0 : RegionList.length, currentPageLimits: PageLimit })))));
};
exports.default = RegionList;
